<div id="onglet3" class="tab-pane fade show active">

    <section class="mt-5">
        <div class="row grid-top-btn">
            <div class="col-md-4 filter">
                <button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open"
                    [hidden]="showFilterAbonnement" (click)="toggleFilterDisplay()">
                    <i class="fa fa-sliders"></i>
                    Filtrer
                </button>
                <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
                    [count]="gridHelper.totalRowCount"></careweb-pluralize>
            </div>
            <div class="col-md-8 conf-col">
                
                <div class="careweb-toolbar">
                    <span class="btn btn-primary small" 
                                        tooltip="Ajouter une règle" placement="top" container="body"  (click)="goToAjouterRegle()">
                                        Ajouter
                                    </span>
                    <careweb-pagination-size id="dv_organismes_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                        [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
                    </careweb-pagination-size>
                    <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top"
                        container="body" name="spn_reset_grid" (click)="resetGridState()">
                        <i class="fa fa-refresh"></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="row grid-top-filter">
            <div class="col-md-12">
                <careweb-filter-label *ngFor="let filter of filterList" [filter]="filter.id" [isOrganisme]="true"
                    (closeFilter)="removeFilter(filter.id)">
                    <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                </careweb-filter-label>
            </div>
        </div>
        <div class="row highlight-row">
            <div class="col-md-12 relative">
                <ag-grid-angular style="width: 100%" class="ag-theme-balham ag-theme-organisme"
                    [gridOptions]="gridHelper.gridOptions">
                </ag-grid-angular>

                <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
            </div>
        </div>
    </section>
</div>
