import { Component, OnInit, Input } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/main/webapp/src/app/data/userDataRaw';
import { LoginService } from '../../../../login/login.service';
import { UsersService } from '../../../../users/users.service';
import { isNullOrUndefined } from 'util';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import { isEmpty, isEqual } from 'lodash-es';
import { MapEmptyValueToNull } from '../../../../../core/utilities/map-empty-value-to-null';
import {InputValidators} from "../../../../../components/validators/InputValidators";
@Component({
	selector: 'careweb-app-ps-user-edit',
	templateUrl: './ps-user-edit.component.html',
	styleUrls: [ './ps-user-edit.component.scss' ]
})
export class PsUserEditComponent implements OnInit {
	@Input() user: User;
	@Input() idPs: number;
	userForm: UntypedFormGroup;
	initialEmail: String;

	typeUserArray = [
		{ value: 1, viewValue: 'Administrateur' },
		{ value: 3, viewValue: 'Hotline' },
		{ value: 4, viewValue: 'Administrateur SEL' },
		{ value: 2, viewValue: 'Utilisateur PS' }
	];
	civiliteArray: any = [ { value: 1, viewValue: 'M' }, { value: 3, viewValue: 'Mme' } ];
	actifArray: any = [ { value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' } ];
	submitted: Boolean = false;
	initalValues: any;

	constructor(
		private modalRef: BsModalRef,
		private loginSvc: LoginService,
		private toastr: ToastrService,
		private fb: UntypedFormBuilder,
		private mapEmptyValueToNull: MapEmptyValueToNull,
		private inputValidators: InputValidators,
		private userService: UsersService, // private router: Router
	) {}
	ngOnInit() {
		this.initialiseCurrentPage();
	}

	initialiseCurrentPage() {
		this.submitted = false;
		this.validForm();
		this.getUserById(this.user.idUser);
	}

	getUserById(idUser) {
		this.userService.getUserById(idUser).subscribe(
			(data) => {
				if (data.idCivilite === 2) {
					data.idCivilite = 3;
				}
				debugger;
				if (data) {
					this.userForm.patchValue({
						typeUser: this.typeUserArray.find((type) => type.value === data.idTypeUser).viewValue,
						// groupUser: data.libelleCourtGroup,
						typeCivilite: this.civiliteArray.find((civilite) => civilite.value === data.idCivilite),
						fonction: data.fonction,
						nom: isNullOrUndefined(data.nom) ? '' : data.nom,
						prenom: isNullOrUndefined(data.prenom) ? '' : data.prenom,
						telephone: data.telephone,
						mobile: data.mobile,
						fax: data.fax,
						email: data.email,
						actif: this.actifArray.find((actif) => actif.value === data.actif),
						commentaire: data.commentaire,
						rapproActif: this.actifArray.find((rapproActif) => rapproActif.value === data.userPreferences.rapproActif),
					});
					this.initialEmail = data.email;
					this.initalValues = this.userForm.value;
				}
			},
			(err) => {}
		);
	}

	isEmailChange(): boolean {
		return this.userForm.value.email !== this.initialEmail;
	}

	validForm() {
		this.userForm = this.fb.group({
			typeUser: [ '' ],
			// groupUser: [ '' ],
			typeCivilite: [ '' ],
			fonction: [ '' ],
			nom: [ '' ],
			prenom: [ '' ],
			telephone: [ '' ],
			mobile: [ '' ],
			fax: [ '' ],
			email: [
				'',
				[
					Validators.required,
					this.inputValidators.inputWithSpaces,
					Validators.pattern(this.inputValidators.emailPattern)
				]
			],
			actif: [ '' ],
			userSupprimmer: [ '' ],
			modifMdp: [ '' ],
			modifAdministration: [ '' ],
			commentaire: [ '' ],
			rapproActif: [ '' ]
		});
	}

	get formFields() {
		return this.userForm.controls;
	}

	closeModal() {
		this.modalRef.hide();
	}

	onReinitializePassword() {
		if (this.user.email) {
			this.loginSvc.askResetPwd(this.user.email.toString()).subscribe(
				() => {
					this.toastr.success("L'email de réinitialisation du mot de passe vient d'être envoyé");
				},
				(error) => {}
			);
		}
	}

	onResendInvitationMail(): void {
		if (this.user.email) {
			this.userService.sendInvitationMail(this.user.email.toString()).subscribe(
				() => {
					this.toastr.success("L'email d'invitation vient d'être envoyé");
				},
				() => {
					this.toastr.error("Une erreur est survenue lors de l'envoie de l'email");
				}
			);
		}
	}

	getTitle() {
		let title = ``;
		let actif = '';
		if (this.userForm.value.actif) {
			actif = this.formatActif(this.userForm.value.actif.value);
		}

		title += `Modification de l'utilisateur - ${this.userForm.value.nom.toUpperCase()}  ${this.userForm.value
			.prenom}  - (${actif})`;

		return title;
	}

	formatActif(value) {
		return value === true ? 'ACTIF' : 'INACTIF';
	}

	getTypeUser(value) {
		return this.typeUserArray.find((type) => type.value === value);
	}

	unblockAccount() {
		// Vérifier si le compte est désactivé
		if (this.user.actif) {
			// Appeler l'API pour débloquer le compte
			this.userService.unblockAccount(this.user).subscribe(
				() => {
					this.user.blocked = false;
					this.reset();
				}
			);
		}
	}


	onSubmit() {
		this.submitted = true;
		if (this.userForm.valid && 
			!isEqual(this.mapEmptyValueToNull.emptyValueToNull(this.initalValues), this.mapEmptyValueToNull.emptyValueToNull(this.userForm.value))) {
		
		const controlEmail = this.userForm.controls['email'];

		// verif exist email in database
		if (this.isEmailChange() === true && !isEmpty(this.userForm.value.email)) {
			this.userService.getUsersByEmail(this.userForm.value.email).subscribe(
				(data) => {
					if (data != null) {
						controlEmail.setErrors({ validmail: true });
					} else {
						controlEmail.setErrors(null);
						this.updateUser();
					}
				},
				(err) => {}
			);
		} else {
			this.updateUser();
		}
	  }
	}


	updateusersPs(listUsers, currentUser) : Array<User>{
		const index = listUsers.findIndex((user) => user.idUser == this.user.idUser);
		if (index == -1) {
			return listUsers;
		}
		const modifiedList = [...listUsers];
		modifiedList[index] = currentUser
		return modifiedList;
	}

	updateUser() {
		let currentUser: User = new User();
		let data = this.userForm.value;
		currentUser.idCivilite = data.typeCivilite.value;
		currentUser.fonction = data.fonction ? (data.fonction as String).trim() : data.fonction;
		currentUser.nom = data.nom ? (data.nom as String).trim() : data.nom;
		currentUser.prenom = data.prenom ? (data.prenom as String).trim() : data.prenom;
		currentUser.telephone = data.telephone;
		currentUser.mobile = data.mobile;
		currentUser.fax = data.fax;
		currentUser.email = data.email ? (data.email as String).trim() : data.email;
		currentUser.actif = data.actif.value;
		currentUser.userPreferences = this.user.userPreferences;
		currentUser.userPreferences.rapproActif = data.rapproActif.value;
		currentUser.commentaire = data.commentaire ? (data.commentaire as String).trim() : data.commentaire;
		this.userService.updateUser(this.user.idUser, currentUser).subscribe((data) => {
			if (data) {
				this.toastr.success("L'utilisateur a été mis à jour avec succès");
				this.closeModal();
			}
			this.submitted = false;
		});
	}

	reset() {
		this.userForm.reset();
		this.initialiseCurrentPage();
		this.modalRef.hide();
	}
}
