import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { SiteEmissionDataRaw } from "../../data/siteEmissionDataRaw";
import { ToastrService } from "ngx-toastr";
import { ScrollTopService } from "../../core/utilities/scroll-top.service";
import { findIndex, isEmpty, isInteger, uniqBy } from 'lodash-es';

import { EbicsService } from "../ps/service/ebics.service";
import { AbonnementEbicsFilterRaw } from "../../data/filters/abonnement-ebics-filter-raw";
import { AgGridHelper } from "../../components/ag-grid/ag-grid-helper";
import { ColDef, GridOptions, IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-enterprise";
import { AuthService } from "../../core/services/auth.service";
import { isNullOrUndefined } from "util";
import { gridConvertSort } from "../../components/ag-grid/ag-grid.utils";
import { FilterItem } from "../../data/filters/filter-item";
import { AbonnementDataRaw } from "../../data/abonnement-data-raw";
import { GridDataRaw } from "../../data/gridDataRaw";
import { StorageService } from "../../shared/storage-service";
import { DatePipe } from "@angular/common";
import { AbonnementPreviewComponent } from './abonnement-preview-component';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PsService } from '../ps/ps.service';
import { DetailsCompteAssociesComponent } from './details-comptes-associes/details-comptes-associes.component';
import { OctService } from "../../core/services/oct.service";
import { OctPsService } from "../ps/service/octps.service";
import { OctDataRaw } from "../../data/octDataRaw";
import { RegleParsingXmlComponent } from './regle-parsing-xml/regle-parsing-xml.component';
import {RefreshPreviewComponent} from "./refresh-preview-component";

@Component({
	selector: 'careweb-app-abonnement-ebics',
	templateUrl: './abonnement-ebics.component.html',
	styleUrls: ['./abonnement-ebics.component.scss']
})
export class AbonnementEbicsComponent implements OnInit {
	@ViewChild(RegleParsingXmlComponent)
	regleComponent: RegleParsingXmlComponent;
	gridHelper: AgGridHelper;
	selectedRow: any;
	filterList: FilterItem[];
	abonnementDataRow: AbonnementDataRaw;
	gridDataRaw: GridDataRaw;

	rowCount: number;
	showFilter = false;
	initHidden = true;
	listEbicsOrderType: any = [
		{ value: 'FDL', viewValue: 'FDL' },
	];
	currentIdPs;
	modalMessage: string;
	showConfirmModal: boolean = false;
	siteEmissionPs: SiteEmissionDataRaw[];
	filters: AbonnementEbicsFilterRaw;
	showDeleteAbonnementModal: Boolean = false;
	showRefreshAbonnementModal: Boolean = false;
	abonnementToDelete: number;
	idAbonnementToRefresh: number;
	openedModal: BsModalRef;
	suppressionPossible: boolean = true;
	comptesAssocies: any;
	@Input() octSelected: OctDataRaw;
	octCurrent;
	currentOctId: any;
	listOctById: any;
	idPs: any;
	active: number = 1;

	constructor(
		private ebicsService: EbicsService,
		private toastr: ToastrService,
		private scrollTopService: ScrollTopService,
		private authSvc: AuthService,
		private storageService: StorageService,
		private datePipe: DatePipe,
		private router: Router,
		private modalService: BsModalService,
		private psService: PsService,
		private octSvc: OctService,
		private octPsSvc: OctPsService,

	) {
		const sessionPageSize = this.storageService.get('abonnementsPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
		this.gridHelper = new AgGridHelper('abonnementEbics', this.gridDataRaw.size);
		this.selectedRow = null;

	}

	ngOnInit() {
		this.datePipe = new DatePipe('fr-FR');

		// Init filters
		const parsedFilter = JSON.parse(sessionStorage.getItem('abonnementsFilter'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
			if (isNullOrUndefined(this.filters.bankName) || isEmpty(this.filters.bankName)) {
				this.filters.bankName = parsedFilter.bankName;
			}
		} else {
			this.filterList = [];
			this.filters = new AbonnementEbicsFilterRaw();

		}
		// Init ag-grid
		this.gridHelper.gridOptions = <GridOptions>(<unknown>{
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			enableCellTextSelection: true,
			ensureDomOrder: true,
			//onRowClicked: (params) => this.onRowClicked(params),
			onGridReady: (params) => this.onGridReady(params),
			onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onSortChanged: (params) => this.onSortChanged(params),
			onColumnMoved: (params) => this.onColumnMoved(params)
		});
		this.scrollTopService.setScrollTop();
		if (JSON.parse(localStorage.getItem('current_ps'))) {
			this.currentIdPs = JSON.parse(localStorage.getItem('current_ps')).idPs;
		}
		this.getActiveTab();

	}

	onRowClicked(params) {
		if (params.type == 'rowClicked') {
			this.router.navigate(['/abonnements-ebics', params.data.id]);
		}
	}
	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}
	onColumnMoved(params: any): void {
		this.gridHelper.saveGridColumnState();
	}
	onSortChanged(params: any): void {
		this.gridHelper.saveGridSort();
	}

	onGridReady(params) {
		this.initFilter();
		var width = window.innerWidth;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		this.setColumnOrder(width);
		this.getAbonnementsList();
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		let width = event.target.innerWidth;
		this.setColumnOrder(width);
	}
	setColumnOrder(width: any) {
		const columnDefs = this.getColumnDefs();
		if(width<=1600){
			const columnApi = this.gridHelper.gridColumnApi;
			const removeIndex = columnDefs.findIndex(column => column.field === 'remove');
			const editIndex = columnDefs.findIndex(column => column.field === 'edit');
			if (removeIndex !== -1 || editIndex !== -1) {
				columnApi.moveColumn('edit', 0);
				columnApi.moveColumn('remove', 1);
			}
		} else {
			this.gridHelper.gridApi.sizeColumnsToFit();
			this.gridHelper.gridApi.setColumnDefs(columnDefs);
		}
	}
	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}
	initFilter() {
		// Liste des filtres
		this.resetFilter();
		this.updateFilterList(this.filters);
	}
	resetFilter() {
		this.ebicsService.reset();
	}


	trackByIndex(index, item) {
		return index;
	}
	goToAjouterAbonnement() {
		this.router.navigate(['/new-abonnement-ebics']);

	}

	toggleFilterDisplay() {
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
		}
	}
	resetAllFilter(): void {
		this.filters = new AbonnementEbicsFilterRaw();
		this.launchSearch();
		this.ebicsService.reset();
	}
	launchSearch(): void {
		this.gridHelper.gridOptions.api.setColumnDefs(null);
		this.gridHelper.gridOptions.api.setColumnDefs(this.getColumnDefs());
		this.getAbonnementsList();
		sessionStorage['abonnementFilter'] = JSON.stringify(this.filters);
	}

	toDissocier(compte: any): void {
		this.idPs = compte.idPs;
		this.octCurrent = localStorage.getItem('octCurrent');
		// get selected OCT
		if (isNullOrUndefined(this.octCurrent)) {
			this.octSvc.currentOctSubject.subscribe((oct) => {
				if (!isNullOrUndefined(oct)) {
					this.currentOctId = oct.idOct;
				}
			});
		} else {
			const obj = JSON.parse(this.octCurrent);
			this.currentOctId = obj.idOct;
		}

		this.octPsSvc.getOctPsByIdPs(compte.idPs).subscribe(data => {
			this.listOctById = data;
			this.checkCurrentOctInList();
		});
		this.psService.eventDissocier.emit("dissocier");
	}
	checkCurrentOctInList(): void {
		const octExists = this.listOctById.some(oct => oct.idOct === this.currentOctId);
		if (octExists) {
			this.router.navigate(['/ps-details/', this.idPs]);
		} else {
			this.octSvc.getOctById(this.listOctById[0].idOct).subscribe(data => {
				this.onOctChange(data.idOct, data.nom, data.code);
			}
			);
		}
	}
	onOctChange(idOct, nom, code) {
		this.octSelected = new OctDataRaw().withIdOct(idOct).withNom(nom).withCode(code);
		this.router.navigate(['/ps-details/', this.idPs]);
		this.octSvc.setOctValue(this.octSelected);
		localStorage['octCurrent'] = JSON.stringify(this.octSelected);
	}
	private getColumnDefs(): ColDef[] {
		return [{
			headerName: 'Nom de l\'abonnement',
			field: 'bankName',
			minWidth: 150,
			lockVisible: true,
			sortable: true,
			hide: !this.isAuthorized()
		},
		{
			headerName: 'Host ID',
			field: 'ebicsHostId',
			minWidth: 100,
			lockVisible: true,
			sortable: true
		},
		// {
		// 	headerName: 'Partner ID',
		// 	minWidth: 100,
		// 	maxWidth: 100,
		// 	lockVisible: true,
		// 	cellStyle: this.gridHelper.centerContent(),
		// 	sortable: true
		// },
		/* {
			headerName: 'Référence du compte',
			field: 'accountRef',
			minWidth: 100,
			maxWidth: 100,
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent(),
			sortable: true
		}, */
		{
			headerName: 'User ID',
			field: 'ebicsUserId',
			minWidth: 150,
			lockVisible: true,
			sortable: true,
			hide: !this.isAuthorized()
		},
		{
			headerName: 'Ebics URL',
			field: 'ebicsUrl',
			minWidth: 180,
			lockVisible: true,
			sortable: true,
			hide: !this.isAuthorized()
		},
		{
			headerName: 'File format',
			field: 'ebicsFileFormat',
			minWidth: 150,
			lockVisible: true,
			sortable: true,
			hide: !this.isAuthorized()
		},
		{
			headerName: 'Order type',
			field: 'ebicsOrderType',
			minWidth: 150,
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent(),
			sortable: true,
			hide: !this.isAuthorized()
		},
		{
			headerName: 'Dernière synchronisation',
			field: 'lastSyncDate',
			valueFormatter: this.formatDate,
			minWidth: 150,
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent(),
			sortable: true,
			hide: !this.isAuthorized()
		},
		{
			headerName: 'Nbre de comptes',
			field: 'nbreAttachedAccounts',
			cellStyle: this.gridHelper.centerContent(),
			//onCellClicked: (params) => this.onDetailClicked(params),
			cellRenderer: (params) => {
				var link = document.createElement('a');
				link.href = '#';
				link.innerText = params.value;
				link.addEventListener('click', (e) => {
					e.preventDefault();
					this.onDetailClicked(params);
				});
				link.addEventListener('contextmenu', (e) => {
					e.preventDefault();
				});
				return link;
			},
			minWidth: 160,
			maxWidth: 200,
			lockVisible: true,
			sortable: false,
		},
		{
			headerName: 'Statut',
			field: 'lastSyncStatut',
			cellStyle: this.gridHelper.centerContent(),
			cellRenderer: (params) => this.formatSynchoReussieEBICS(params),
			minWidth: 160,
			maxWidth: 200,
			lockVisible: true,
			sortable: true,
		},
		{
			headerName: 'Statut de la certification',
			field: 'certificatesState',
			minWidth: 160,
			maxWidth: 200,
			cellStyle: this.gridHelper.centerContent(),
			cellRenderer:(params) => this.formatCertificateEBICS(params),
		},
		{
			headerName: '',
			field: 'refresh',
			minWidth: 70,
			maxWidth: 70,
			cellRenderer: RefreshPreviewComponent,
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent(),
			onCellClicked : (params) => this.showRefreshModal(params)
		},
		{
			headerName: '',
			field: 'edit',
			minWidth: 70,
			maxWidth: 70,
			cellStyle: this.gridHelper.centerContent(),
			cellRenderer: AbonnementPreviewComponent,
			cellRendererParams: {
				iconClass: 'fa fa-edit',
				action: 'edit'
			}
		},
		{
			headerName: '',
			field: 'remove',
			minWidth: 70,
			maxWidth: 70,
			cellRenderer: AbonnementPreviewComponent,
			cellRendererParams: {
				iconClass: 'fa fa-trash',
				action: 'delete'
			},
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent(),
			onCellClicked: (params) => this.showDeleteModal(params)
		}
		];

	}

	formatSynchoReussieEBICS(params) {
		let isOk = params.value;
		if (isOk) {
			if (isOk === 'ERROR') {
				return "<span class='ui-grid-cell-contents red cell-bold' >KO </span>";
			} else {
				if (isOk === 'SUCCESS') {
					return "<span class='ui-grid-cell-contents' container='body'>OK </span>";
				}
				else {
					return '-';
				}
			}
		}
	}

	formatCertificateEBICS(params){
		switch (params.value){
			case "VALID": return "<span class='ui-grid-cell-contents green cell-bold'  container='body'> Valide </span>";
			case "EXPIRING" : return "<span class='ui-grid-cell-contents orange cell-bold' > En cours d'expiration </span>";
			case "EXPIRED" : return "<span class='ui-grid-cell-contents red cell-bold' > Expireé </span>";
		}
	}
	onDetailClicked(params: any) {
		this.modalService.show(DetailsCompteAssociesComponent, {
			initialState: {
				idAbonnement: params.data.id,
			}
		});



	}
	showDeleteModal(params: any) {
		this.ebicsService.checkCompteAssocies(params.data.id).subscribe((data) => {

			if (data.length != 0) {
				//ctx.toastr.error('L\'abonnement a des associés et ne peut pas etre supprimé.');
				this.showDeleteAbonnementModal = true;
				this.suppressionPossible = false;
			}
			else {
				this.showDeleteAbonnementModal = true;
				this.suppressionPossible = true;
			}
		});
		this.abonnementToDelete = params.data.id;

	}

	showRefreshModal(params : any){
		if (params.data.certificatesState && params.data.certificatesState !== "VALID") {
			this.showRefreshAbonnementModal = true;
			this.idAbonnementToRefresh = params.data.bankId;
		}
	}
	cancelDelete() {
		this.showDeleteAbonnementModal = false;
	}

	cancelRefresh(){
		this.showRefreshAbonnementModal = false;
	}

	formatDate = (params): string => {
		if (params.value !== null && params.value !== 'Invalid Date') {
			return this.datePipe.transform(params.value, 'short');
		}
	};
	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}
	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
	}

	deleteAbonnement() {
		this.ebicsService.checkCompteAssocies(this.abonnementToDelete).subscribe((data) => {
			const ctx = this;
			let comptesAssocies = data;
			if (comptesAssocies.length != 0) {
				//ctx.toastr.error('L\'abonnement a des associés et ne peut pas etre supprimé.');
				this.showDeleteAbonnementModal = false;

			}
			else {
				this.ebicsService.deleteAbonnement(this.abonnementToDelete).subscribe((data) => {
					ctx.toastr.success('L\'abonnement a été supprimé avec succès.');
					this.showDeleteAbonnementModal = false;
					// @TODO: to optimise !!
					this.launchSearch();
				});
			}
		});

	}

	removeFilter(id) {
		const ctx = this;
		delete ctx.filters[id];
		let currentFilterList = ctx.filterList;
		const index = findIndex(currentFilterList, (filt) => filt.id === id);
		currentFilterList.splice(index, 1);
		ctx.filterList = currentFilterList;
		ctx.launchSearch();
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.getAbonnementsList();
		this.gridHelper.saveGridStatePrefix();
	}

	translateFilterLabelValue(key, value) {
		let translatedKey = key;
		let formattedValue = value;
		let formattedKey = key;
		switch (key) {
			case 'bankName':
				translatedKey = 'Nom de la banque';
				break;
			case 'ebicsHostId':
				translatedKey = "Host Id";
				break;
			case 'ebicsUserId':
				translatedKey = 'User ID';
				break;
			case 'ebicsUrl':
				translatedKey = "Ebics URL";
				break;
			case 'ebicsFileFormat':
				translatedKey = 'File format';
				break;
			case 'ebicsOrderType':
				translatedKey = 'Order type';
				break;
			default:
				break;
		}
		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}
	updateFilterList(filters) {
		const ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (filters[key] !== null && filters[key] !== '') {
				ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id != null;
		});
	}


	getAbonnementsList() {
		const ctx = this;
		this.filters.bankName = this.filters.bankName ? this.filters.bankName.trim() : null;
		this.filters.ebicsHostId = this.filters.ebicsHostId ? this.filters.ebicsHostId.trim() : null;
		this.filters.ebicsUserId = this.filters.ebicsUserId ? this.filters.ebicsUserId.trim() : null;
		this.filters.ebicsUrl = this.filters.ebicsUrl ? this.filters.ebicsUrl.trim() : null;
		this.filters.ebicsFileFormat = this.filters.ebicsFileFormat ? this.filters.ebicsFileFormat.trim() : null;
		this.filters.ebicsOrderType = this.filters.ebicsOrderType ? this.filters.ebicsOrderType.trim() : null;
		let formattedFilters = JSON.parse(JSON.stringify(this.filters));

		this.updateFilterList(this.filters);


		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				ctx.ebicsService
					.getAllAbonnements({
						page: ctx.gridHelper.gridApi.paginationGetCurrentPage(),
						size: ctx.gridHelper.gridApi.paginationGetPageSize(),
						sorts
					},
						formattedFilters
					)
					.subscribe(
						(data) => {
							ctx.rowCount = data.totalElements;
							ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
							paramsRows.success({
								"rowData": data.content,
								"rowCount": ctx.rowCount
							});
							const pageN = Number.parseInt(sessionStorage.getItem('abonnementsCurrentPage'));
							if (
								!isNullOrUndefined(pageN) &&
								pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
								pageN > 0
							) {
								ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
							}
							sessionStorage['abonnementsCurrentPage'] = null;
						},
						() => paramsRows.fail()
					);

			}
		};
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		sessionStorage['abonnementsFilter'] = JSON.stringify(this.filters);
	}
	onKeyDown($event) {
		this.launchSearch();
	}
	onTabChange(event: any) {
		const activeTabId = event.nextId;
		this.active = activeTabId;
		if (this.active === 2) {
			setTimeout(()=> {
				this.regleComponent.refreshGrid();
			},1000)
		}
		sessionStorage.setItem('abonnements-tab', activeTabId);
	}
	getActiveTab() {
		if (sessionStorage.getItem('abonnements-tab') && isInteger(parseInt(sessionStorage.getItem('abonnements-tab')))) {
			this.active = parseInt(sessionStorage.getItem('abonnements-tab'));
		} else {
			this.active = 1;
		}
	}
	ngOnDestroy(): void {
		sessionStorage['abonnementsFilter'] = JSON.stringify(this.filters);
	}


	refreshAbonnement() {
		this.ebicsService.renouvlerCerif(this.idAbonnementToRefresh).subscribe(
			data =>
			{
				this.toastr.success('le renouvellement a été passé avec succès.')
				this.launchSearch()
			}
			,
			error => {
				this.toastr.error('le renouvellement n\'a pas été bien  passé .')
			}
		);
		this.showRefreshAbonnementModal = false;
	}
}
