import { DashboardHelpvideoModalComponent } from './dashboard-helpvideo-modal/dashboard-helpvideo-modal.component';
import { formatDate } from '@angular/common';
import {Component, OnInit, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { Chart } from 'chart.js';
import {BehaviorSubject, forkJoin} from 'rxjs';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../core/services/auth.service';
import { OctService } from '../../core/services/oct.service';
import { ArlDataRaw } from '../../data/arlDataRaw';
import { ARLFilterRaw } from '../../data/filters/arl-filter';
import { FseFilterRaw } from '../../data/filters/fse-filter';
import { FseDataRaw } from '../../data/fseDataRaw';
import { FseDataSetRaw } from '../../data/fseDataSetRaw';
import { RspDataRaw } from '../../data/rspDataRaw';
import { RspDataSet } from '../../data/rspDataSet';
import { User } from '../../data/userDataRaw';
import { ARLService } from '../flux/arl/arl.service';
import { FseService } from '../flux/fse/fse.service';
import { RSPService } from '../flux/rsp/rsp.service';
import { PsService } from '../ps/ps.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TutorialService } from '../../core/services/tutorial.service';
import { PsDelegueService } from '../ps/service/ps-delegue.service';
import { PsDelegueDataRaw } from '../../data/PsDelegueDataRaw';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { OctPsService } from '../ps/service/octps.service';
import { RspRappSummaryService } from "../rapprochement/rspRappSummary.service";
import { RspRappSummaryDataRaw } from "../../data/rspRappSummaryDataRaw";
import { ConvertDateFormat } from "../../core/utilities/convert-date-format.service";
import { isEmpty } from 'lodash';
import {AdminService} from "../ contenus-plateforme/admin-content.service";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    public rembRoVolume: Number = 0;
    public rembRoMontant: string;
    public rembRcVolume: Number = 0;
    public rembRcMontant: string;
    public fseNonPayees: any = 0;
    public arlNegatifs: any = 0;
    public chartFactureOrga: any;
    public chartFactureStatus: any;
    numeroPs: String;
    fseVolume: Number = 0;
    fseMontant: string;
    fseRcImpayeVolume: Number = 0;
    fseRcImpayeMontant: string;
    fseRoImpayeVolume: Number = 0;
    fseRoImpayeMontant: string;
    octCurrent;
    userConnected;
    currentOctId: any;
    dateFin: Date = new Date();
    dateDebut: Date = new Date();
    public fseSent: any;
    public fseData: FseDataSetRaw;
    arlCriteria = new ArlDataRaw();
    arlFilter = new ARLFilterRaw();
    rspCriteria = new RspDataRaw();
    fseFilter = new FseFilterRaw();
    fseCriteria = new FseDataRaw();
    rspDataSet = new RspDataSet();
    nbrDays = 30;
    public isCollapsed = false;
    isValidDate = true;
    startDate: Date = new Date();
    endDate: Date = new Date();
    safeVideoUrl: SafeUrl;
    public modalRef: BsModalRef;
    public supervisedPsList: PsDelegueDataRaw[];
    public selectedSupervisedPs: any = -1; // to Select All PS
    public derniereTransmission
    videoVisibility = false;

    @ViewChild('retourForm')
    form: NgForm;
    retourMaxDate: Date;
    ranges: any = [30, 60, 90];
    currentDate: Date = new Date();
    isSupervisor: boolean = false;
    selectedRange: number;
    rspAvecPaiementEtLibelle = { sum: '0', count: 0 };
    rspAvecPaiementRapproches = { sum: '0', count: 0 };
    criteria = new RspRappSummaryDataRaw();
    tauxRapp: string;
    badge;
    dateDebutRapp = new Date();
    dateFinRapp = new Date();
    showRappbtn = false;
    rappVisible = false;
    showHotlineMenu = false;
    showRappBancMenu = false;
    selectedSupervised: any = -1;
    currentUser: User;
    psWithRappActiveAndVisible: String[] = [];
    public supervisedPsListwithRappActiveVisible: PsDelegueDataRaw[];

    constructor(
        private fseService: FseService,
        private rspService: RSPService,
        private arlSvc: ARLService,
        private psService: PsService,
        private octSvc: OctService,
        private authSvc: AuthService,
        private router: Router,
        private localeService: BsLocaleService,
        private activatedRoute: ActivatedRoute,
        private modalService: BsModalService,
        private tutoService: TutorialService,
        private admintutoService : AdminService,
        private sanitizer: DomSanitizer,
        private psDelegueSVC: PsDelegueService,
        private octPsService: OctPsService,
        private convertFormatDate: ConvertDateFormat,
        private rspRappSummarySvc: RspRappSummaryService,
        private psSvc: PsService,

    ) {
        // Body styling
        const body = document.body;
        body.className = 'app';
        // Define local date fr
        defineLocale('fr', frLocale);
    }

    ngOnInit() {
        this.currentUser = this.authSvc.currentUserValue;

        this.setLocale();
        this.initStorageConfiguration();
        this.initFilters();
        this.userConnected = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('careweb_user')));
        this.fillDefaultCriteria();
        this.initPsData();
        if (this.userConnected.value.idTypeUser == 3 || this.userConnected.value.idTypeUser == 1) {
            this.getHotLineSession(this.router.url);
        }
        if (this.userConnected.value.idTypeUser == 2) {
            this.getRappBancSession();
        }
        this.showHelpVideoModal();
        this.admintutoService.getVideoSettings().subscribe((data)=>{
            this.videoVisibility= data.visibility;
        });
    }

    getStatistiques() {
        if (!isNullOrUndefined(this.numeroPs)) {
            this.fillFseCriteriatAndFilter();
            this.getRspStats();
            this.getArlNegatif();
            this.getDashboard();

           /* this.getFseEnvoyesStats();
            this.getFseImpaye();
            this.getFseGraphes();*/

        }
    }
    isPsSelected() {
        let currentPsStorage = localStorage.getItem('current_ps');
        if (currentPsStorage) {
            const currentPs = JSON.parse(currentPsStorage);
            return currentPs && ((currentPs.typeRappro == 'OXLIN' && currentPs.rappComptePs && currentPs.rappComptePs.actif && !currentPs.hideRappro) || (currentPs.typeRappro == 'EBICS' && currentPs.psEbics && this.isEbicsActif(currentPs.psEbics) && !currentPs.hideRappro)) && (this.currentUser.idTypeUser == 3 || this.currentUser.idTypeUser == 1)
        }
        return false;
    }
    isEbicsActif(ebicsPs) {
        return ebicsPs.filter((e) => e.actif)?.length > 0;
    }
    getRappBancSession() {

        const currentPsStorage = localStorage.getItem('current_ps');
        if (currentPsStorage) {
            const currentPs = JSON.parse(currentPsStorage);
            this.showRappBancMenu = currentPs!= null && ( (currentPs.rappComptePs != null && currentPs.rappComptePs.actif && currentPs.typeRappro=='OXLIN'))
        }
        else{
            this.showRappBancMenu=false;
        }
    }
    getDashboard() {
        if(!!this.fseCriteria){
            this.fseService.getDashboardKpi(this.fseCriteria).subscribe(data => {
                this.fseVolume = data[0].count;
                this.fseMontant = !!(data[0].sum) ? this.formatCurrency(data[0].sum) : '0';

                this.fseRcImpayeVolume = data[1].fseRcImpayeCount;
                this.fseRcImpayeMontant = !!(data[1].fseRcImpayeSum)
                    ? this.formatCurrency(data[1].fseRcImpayeSum)
                    : '0';

                this.fseRoImpayeVolume = data[2].fseRoImpayeCount;
                this.fseRoImpayeMontant = !!(data[2].fseRoImpayeSum)
                    ? this.formatCurrency(data[2].fseRoImpayeSum)
                    : '0';

                this.getGraphStatus(data[3]);
                this.getGraphCircle(data[3]);
            });
        }
    }
    getFseEnvoyesStats() {
        if (!isNullOrUndefined(this.fseCriteria)) {
            this.fseService.getFseEnvoyes(this.fseCriteria).subscribe((data) => {
                this.fseVolume = data.count;
                this.fseMontant = !isNullOrUndefined(data.sum) ? this.formatCurrency(data.sum) : '0';
            });
        }
    }

    getRspStats() {
        this.fseFilter.statutRsp = '0';
        this.rspCriteria.dateReceptionPartitionFrom = !isNullOrUndefined(this.fseFilter.dateLotRecuFrom)
            ? formatDate(this.fseFilter.dateLotRecuFrom, "yyyy-MM-dd'T'00:00:00.SSS", 'fr-FR')
            : null;
        this.rspCriteria.dateReceptionPartitionTo = !isNullOrUndefined(this.fseFilter.dateLotRecuTo)
            ? formatDate(this.fseFilter.dateLotRecuTo, "yyyy-MM-dd'T'23:59:59.SSS", 'fr-FR')
            : null;
        this.rspCriteria.numeroAdeli = this.fseFilter.numeroPs;

        this.rspCriteria.listNumeroAdeli  = !this.isAuthorized() && this.isSupervisor ? this.fseFilter.numerosPs : [this.numeroPs];

        this.rspCriteria.statutRsp = this.fseFilter.statutRsp;
        this.rspCriteria.idOct = this.isAuthorized() ? this.currentOctId : 0;


        this.rspService.getRspStatistiques(this.rspCriteria).subscribe((data) => {
            this.rspDataSet = data;
            this.rembRcMontant = !isNullOrUndefined(this.rspDataSet.sumRc)
                ? this.formatCurrency(this.rspDataSet.sumRc)
                : '0';
            this.rembRoMontant = !isNullOrUndefined(this.rspDataSet.sumRo)
                ? this.formatCurrency(this.rspDataSet.sumRo)
                : '0';
            this.rembRcVolume = this.rspDataSet.countRcTraite;
            this.rembRoVolume = this.rspDataSet.countRoTraite;
        });
    }

    getFseImpaye() {
        if (!isNullOrUndefined(this.fseCriteria)) {
            this.fseService.getFseRcImpayee(this.fseCriteria).subscribe((data) => {
                this.fseRcImpayeVolume = data.fseRcImpayeCount;
                this.fseRcImpayeMontant = !isNullOrUndefined(data.fseRcImpayeSum)
                    ? this.formatCurrency(data.fseRcImpayeSum)
                    : '0';
            });

            this.fseService.getFseRoImpayee(this.fseCriteria).subscribe((data) => {
                this.fseRoImpayeVolume = data.fseRoImpayeCount;
                this.fseRoImpayeMontant = !isNullOrUndefined(data.fseRoImpayeSum)
                    ? this.formatCurrency(data.fseRoImpayeSum)
                    : '0';
            });
        }
    }

    getFseGraphes() {
        if (!isNullOrUndefined(this.fseCriteria)) {
            this.fseService.getFseGraphDataSet(this.fseCriteria).subscribe((data) => {
                // CHART FACTURE STATUS
                this.getGraphStatus(data);
                // CHART FACTURE PAR REGIME
                this.getGraphCircle(data);
            });
        }
    }

    getGraphCircle(fseData: FseDataSetRaw) {
        if (!isNullOrUndefined(this.chartFactureOrga) && !isNullOrUndefined(this.chartFactureOrga.chart)) {
            this.chartFactureOrga.chart = null;
            this.chartFactureOrga.data.datasets.length = 0;
        }
        this.chartFactureOrga = new Chart('chartOrga', {
            type: 'doughnut',
            data: {
                labels: ['AMO', 'AMC'],
                datasets: [
                    {
                        label: '',
                        backgroundColor: ['#27a344', '#21b8cf'],
                        data: [fseData.repartionRo, fseData.repartionRc]
                    }
                ]
            },
            options: {
                title: {
                    display: false
                },
                legend: {
                    position: 'left',
                    labels: {
                        fontSize: 17
                    }
                },
                tooltips: {
                    bodyFontSize: 20
                }
            }
        });
    }

    getGraphStatus(fseData: FseDataSetRaw) {
        if (!isNullOrUndefined(this.chartFactureStatus) && !isNullOrUndefined(this.chartFactureStatus.chart)) {
            this.chartFactureStatus.chart = null;
            this.chartFactureStatus.data.datasets.length = 0;
        }
        this.chartFactureStatus = new Chart('chartStatus', {
            type: 'bar',
            data: {
                labels: ['En cours', 'Payée', 'Rejetée'],
                datasets: [
                    {
                        label: 'FSE en cours',
                        stack: 'stack0',
                        backgroundColor: '#21b8cf',
                        data: [fseData.fseEnCours, 0, 0, 0]
                    },
                    {
                        label: 'FSE payées',
                        stack: 'stack0',
                        backgroundColor: '#27a344',
                        data: [0, fseData.fsePayee, 0, 0]
                    },
                    {
                        label: 'FSE rejetées',
                        stack: 'stack0',
                        backgroundColor: '#ff9317',
                        data: [0, 0, fseData.fseRejetee, 0]
                    }
                ]
            },
            options: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ]
                },
                title: {
                    display: false,
                    text: 'Factures transmises par état'
                },
                legend: {
                    display: false,
                    position: 'bottom',
                    labels: {
                        fontSize: 17
                    }
                },
                tooltips: {
                    bodyFontSize: 20
                }
            }
        });
    }

    getArlNegatif() {
        this.arlFilter.dateReceptionPartitionFrom = this.dateDebut;
        this.arlFilter.dateReceptionPartitionTo = this.dateFin;
        this.arlFilter.statut = false;
        this.arlFilter.numeroPs = this.numeroPs;
        this.arlFilter.listNumeroAdeli = this.fseFilter.numerosPs;

        this.arlCriteria.dateReceptionPartitionFrom = !isNullOrUndefined(this.arlFilter.dateReceptionPartitionFrom)
            ? formatDate(this.arlFilter.dateReceptionPartitionFrom, "yyyy-MM-dd'T'00:00:00.SSS", 'fr-FR')
            : null;
        this.arlCriteria.dateReceptionPartitionTo = !isNullOrUndefined(this.arlFilter.dateReceptionPartitionTo)
            ? formatDate(this.arlFilter.dateReceptionPartitionTo, "yyyy-MM-dd'T'23:59:59.SSS", 'fr-FR')
            : null;
        this.arlCriteria.numeroPs = this.arlFilter.numeroPs;

        this.arlCriteria.listNumeroAdeli  = !this.isAuthorized() && this.isSupervisor ? this.arlFilter.listNumeroAdeli : [this.numeroPs];

        this.arlCriteria.signeArl = false;

        if (this.isAuthorized() && !isNullOrUndefined(this.currentOctId)) {
            this.arlCriteria.idOct = this.currentOctId;
        }

        this.arlSvc.countArl(this.arlCriteria).subscribe((nbArl) => {
            this.arlNegatifs = nbArl;
        });

    }

    fillFseCriteriatAndFilter() {
        this.fseFilter.dateLotRecuFrom = this.dateDebut;
        this.fseFilter.dateLotRecuTo = this.dateFin;
        this.fseFilter.numeroPs = this.numeroPs;

        this.fseCriteria.idOct = this.isAuthorized() ? this.currentOctId : 0;
        this.fseCriteria.numPs = this.fseFilter.numeroPs;

        this.fseCriteria.listNumeroPs = !this.isAuthorized() && this.isSupervisor ? this.fseFilter.numerosPs : [this.numeroPs];

        this.fseCriteria.dateLotRecuFrom = !isNullOrUndefined(this.fseFilter.dateLotRecuFrom)
            ? formatDate(this.fseFilter.dateLotRecuFrom, "yyyy-MM-dd'T'00:00:00.SSS", 'fr-FR')
            : null;

        this.fseCriteria.dateLotRecuTo = !isNullOrUndefined(this.fseFilter.dateLotRecuTo)
            ? formatDate(this.fseFilter.dateLotRecuTo, "yyyy-MM-dd'T'23:59:59.SSS", 'fr-FR')
            : null;

    }

    voirArlNegatif() {
        if (!isNullOrUndefined(this.arlFilter)) {
            this.arlFilter = new ARLFilterRaw();
            this.arlFilter.dateReceptionPartitionFrom = this.dateDebut;
            this.arlFilter.dateReceptionPartitionTo = this.dateFin;
            this.arlFilter.statut = false;
            if (!this.isAuthorized()) {
                this.arlFilter.numeroPScomboBox = this.isSupervisor ? this.numeroPs : null;
            } else {
                this.arlFilter.numeroPs = this.numeroPs;
            }
            sessionStorage['dateDebut'] = this.dateDebut;
            sessionStorage['dateFin'] = this.dateFin;
            sessionStorage['arlFilter'] = JSON.stringify(this.arlFilter);
            this.router.navigate(['/arl']);
        }
    }

    voirRembouressementRO() {
        if (!isNullOrUndefined(this.fseFilter)) {
            this.fseFilter = new FseFilterRaw();
            this.fseFilter.dateRspRecuFrom = this.dateDebut;
            this.fseFilter.dateRspRecuTo = this.dateFin;
            if (!this.isAuthorized()) {
                this.fseFilter.numeroPScomboBox = this.isSupervisor ? this.selectedSupervisedPs === -1 ? null : this.selectedSupervisedPs : null;
            } else {
                this.fseFilter.numeroPs = this.numeroPs;
            }
            this.fseFilter.typeOrganisme = 'RO';
            this.fseFilter.presenceRspRo = true;
            this.fseFilter.statutRsp = '0';
            sessionStorage['dateDebut'] = this.dateDebut;
            sessionStorage['dateFin'] = this.dateFin;
            sessionStorage['fseFilters'] = JSON.stringify(this.fseFilter);
            this.router.navigate(['/fse']);
        }
    }

    voirRembouressementRC() {
        if (!isNullOrUndefined(this.fseFilter)) {
            this.fseFilter = new FseFilterRaw();
            this.fseFilter.dateRspRecuFrom = this.dateDebut;
            this.fseFilter.dateRspRecuTo = this.dateFin;
            if (!this.isAuthorized()) {
                this.fseFilter.numeroPScomboBox = this.isSupervisor ? this.selectedSupervisedPs === -1 ? null : this.selectedSupervisedPs : null;
            } else {
                this.fseFilter.numeroPs = this.numeroPs;
            }
            this.fseFilter.typeOrganisme = 'RC';
            this.fseFilter.presenceRspRc = true;
            this.fseFilter.statutRsp = '0';
            sessionStorage['dateDebut'] = this.dateDebut;
            sessionStorage['dateFin'] = this.dateFin;
            sessionStorage['fseFilters'] = JSON.stringify(this.fseFilter);
            this.router.navigate(['/fse']);
        }
    }

    voirFseEnvoyes() {
        if (!isNullOrUndefined(this.fseFilter)) {
            this.fseFilter = new FseFilterRaw();
            this.fseFilter.dateLotRecuFrom = this.dateDebut;
            this.fseFilter.dateLotRecuTo = this.dateFin;
            if (!this.isAuthorized()) {
                this.fseFilter.numeroPScomboBox = this.isSupervisor ? this.selectedSupervisedPs === -1 ? null :  this.selectedSupervisedPs : null;             
            } else {
                this.fseFilter.numeroPs = this.numeroPs;
            }
            sessionStorage['dateDebut'] = this.dateDebut;
            sessionStorage['dateFin'] = this.dateFin;
            sessionStorage['fseFilters'] = JSON.stringify(this.fseFilter);
            this.router.navigate(['/fse']);
        }
    }

    voirFactureImpayeRC() {
        if (!isNullOrUndefined(this.fseCriteria)) {
            this.fseFilter = new FseFilterRaw();
            if (!this.isAuthorized()) {
                this.fseFilter.numeroPScomboBox = this.isSupervisor ? this.selectedSupervisedPs === -1 ? null :  this.selectedSupervisedPs : null;
            } else {
                this.fseFilter.numeroPs = this.numeroPs;
            }
            this.fseFilter.dateLotRecuFrom = this.dateDebut;
            let dateFinMoins15: Date = new Date();
            // pour FSE Impaye tjr la date de fin est la date du jour -15
            dateFinMoins15.setDate(dateFinMoins15.getDate() - 15);

            this.fseFilter.tiersPayant = true;
            this.fseFilter.dateLotRecuTo = dateFinMoins15;
            this.fseFilter.presenceRspRc = false;
            this.fseFilter.partExtraite = ['RC','GS','GU'];
            sessionStorage['dateDebut'] = this.dateDebut;
            sessionStorage['dateFin'] = this.dateFin;
            sessionStorage['fseFilters'] = JSON.stringify(this.fseFilter);
            this.router.navigate(['/fse']);
        }
    }

    voirFactureImpayeRO() {
        if (!isNullOrUndefined(this.fseCriteria)) {
            this.fseFilter = new FseFilterRaw();
            if (!this.isAuthorized()) {
                this.fseFilter.numeroPScomboBox = this.isSupervisor ? this.selectedSupervisedPs === -1 ? null :  this.selectedSupervisedPs : null;
            } else {
                this.fseFilter.numeroPs = this.numeroPs;
            }
            this.fseFilter.dateLotRecuFrom = this.dateDebut;

            let endDateMinusSeven: Date = new Date();
            // pour FSE Impaye RO tjr la date de fin est la date du jour -7
            endDateMinusSeven.setDate(endDateMinusSeven.getDate() - 7);

            this.fseFilter.tiersPayant = true;
            this.fseFilter.dateLotRecuTo = endDateMinusSeven;
            this.fseFilter.presenceRspRo = false;
            this.fseFilter.partExtraite = ['RO','GS','GU'];
            sessionStorage['dateDebut'] = this.dateDebut;
            sessionStorage['dateFin'] = this.dateFin;
            sessionStorage['fseFilters'] = JSON.stringify(this.fseFilter);
            this.router.navigate(['/fse']);
        }
    }

    filter() {
        this.isValidDate = true;
        if (!isNullOrUndefined(this.startDate) && !isNullOrUndefined(this.endDate)) {
            if (this.startDate.getTime() < this.endDate.getTime()) {
                this.dateDebut = this.startDate;
                this.dateFin = this.endDate;
                this.isValidDate = true;
                this.getStatistiques();
                this.isCollapsed = false;
                sessionStorage['dateDebut'] = this.dateDebut;
                sessionStorage['dateFin'] = this.dateFin;
            } else {
                this.isValidDate = false;
            }
        } else {
            this.isValidDate = false;
        }
    }

    reinit() {
        this.isValidDate = true;
        this.startDate = new Date();
        this.endDate = new Date();
        this.dateFin = new Date();
        this.dateDebut = new Date();
        sessionStorage.removeItem('dateDebut');
        sessionStorage.removeItem('dateFin');
        this.dateDebut.setDate(this.dateDebut.getDate() - this.nbrDays);
        this.startDate.setDate(new Date().getDate() - this.nbrDays);
        this.getStatistiques();
    }

    isAuthorized() {
        return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
    }

    formatDate(date: Date) {
        return formatDate(date, 'dd/MM/yy', 'fr-FR');
    }

    setRetourMaxDate($event) {
        this.retourMaxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 100));
    }

    formatCurrency = (montant): string => {
        if (!isNullOrUndefined(montant)) {
            return montant / 100 + ' ';
        } else {
            return '0.00 ';
        }
    };

    removeFilterFromStorage() {
        sessionStorage.clear(); // Sometimes not working
        Object.keys(sessionStorage)
            .filter(function (k) {
                return /Filter/.test(k);
            })
            .forEach(function (k) {
                sessionStorage.removeItem(k);
            });
    }

    getVideoLink() {
        this.tutoService.getVideoLink().subscribe((videolink) => {
            this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videolink);
        })
    }

    showHelpVideoModal() {
        const user = this.userConnected.value
        this.admintutoService.getVideoSettings().subscribe((data)=>{
            this.videoVisibility= data.visibility;
            if (!this.isAuthorized() && user.userPreferences.proposeVideo !== false && this.videoVisibility==true) {
                this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
                this.modalRef = this.modalService.show(DashboardHelpvideoModalComponent,
                    {
                        class: 'modal-xl', ignoreBackdropClick: true, keyboard: false,
                        initialState: {
                            user: this.userConnected.value,
                            safeUrl: this.safeVideoUrl
                        }
                    });
            }
        })
    }

    datePeriodeClick(period: number): void {
        if (this.selectedRange == period) {
            this.isCollapsed = false;
        } else {
            this.selectedRange = period;
            this.startDate = new Date();
            this.dateDebut = new Date();
            this.endDate = new Date();
            this.dateFin = new Date();
            this.dateDebut.setDate(this.dateDebut.getDate() - period);
            this.startDate.setDate(new Date().getDate() - period);
            this.filter();
        }
    }

    persoFilter(): void {
        this.selectedRange = 0;
        this.filter();
    }

    onSupervisedPsSelected(): void {
        if (this.selectedSupervisedPs === -1) {
            this.fseFilter.numerosPs = this.supervisedPsList.map((ps: PsDelegueDataRaw) => ps.numeroPsDelegue)
            this.derniereTransmission = null
        }
        else {
            const numeroAndId=this.selectedSupervisedPs.split(';')
            this.selectedSupervisedPs=numeroAndId[0];
            const idPs=numeroAndId[1];
            this.fseFilter.numerosPs = [this.selectedSupervisedPs]
            this.getDerniereTransmission(idPs)
        }
        this.showRappbtn = this.showRapp();
        this.getStatistiques();
    }

    private initPsData() {
        // if connected user is PS
        if (!isNullOrUndefined(this.userConnected.value) && this.userConnected.value.idTypeUser == 2) {
            var idPs = this.userConnected.value.idPs;

            // Verify if connected user is a supervisor ps

            this.psDelegueSVC.getPsDelegueByIdPs(idPs).subscribe((data: Array<PsDelegueDataRaw>) => {
                if (data.length > 0) {
                    this.isSupervisor = true;
                    this.initListDelegues(data);
                    // Apply numero Ps supervised on the filter if exist
                    this.selectedSupervisedPs = localStorage.getItem('supervised_ps');
                    if (!isEmpty(this.selectedSupervisedPs)) {
                        if (this.selectedSupervisedPs === '-1') {
                            this.fseFilter.numerosPs = this.supervisedPsList.map((ps: PsDelegueDataRaw) => ps.numeroPsDelegue)
                            this.derniereTransmission = null
                        }
                        else {
                            const numeroAndId = this.selectedSupervisedPs.split(';')
                            this.selectedSupervisedPs = numeroAndId[0]
                            idPs = numeroAndId[1];
                            this.fseFilter.numerosPs = [this.selectedSupervisedPs]
                            this.getDerniereTransmission(idPs)
                            // numeroPsSupervisedLocalStorage = numeroPsSupervisedLocalStorage.split(';')[0];
                             this.numeroPs = this.selectedSupervisedPs;
                             //this.showRappbtn = this.showRapp();
                             this.getStatistiques();
                            // if (isNullOrUndefined(this.tauxRapp)) {
                            //     this.getTauxRapp();
                            // }
                        }

                    }
                    // this.fseFilter.numerosPs = this.supervisedPsList.map((ps: PsDelegueDataRaw) => ps.numeroPsDelegue);
                }
                else {
                    this.getDerniereTransmission(this.userConnected.value.idPs ? this.userConnected.value.idPs : localStorage.getItem("idPs"))
                }
                this.psService.getPsById(idPs).then((data) => {
                    this.numeroPs = data.numeroAdeli;
                    //this.showRappbtn = this.showRapp();

                    this.getStatistiques();
                    this.showRappbtn = this.showRapp();
                    if (isNullOrUndefined(this.tauxRapp)) {
                        this.getTauxRapp();
                    }

                });
            });



        } else { // Hotline/Admin
            this.numeroPs = localStorage.getItem('numeroPs');
            this.octCurrent = localStorage.getItem('octCurrent');
            // get selected OCT
            if (isNullOrUndefined(this.octCurrent)) {
                this.octSvc.currentOctSubject.subscribe((oct) => {
                    if (!isNullOrUndefined(oct)) {
                        this.currentOctId = oct.idOct;
                    }
                });
            } else {
                const obj = JSON.parse(this.octCurrent);
                this.currentOctId = obj.idOct;
            }
            this.getDerniereTransmission(this.userConnected.value.idPs?this.userConnected.value.idPs:localStorage.getItem("idPs"))
            this.showRappbtn = this.showRapp();
            if (isNullOrUndefined(this.tauxRapp)){
                this.getTauxRapp();
            }
            this.getStatistiques();
        }
    }

    private initListDelegues(psDelegues: PsDelegueDataRaw[]): void {
        const psSuperviseur = new PsDelegueDataRaw();

        // Ajouter Ps de l'utilisateur
        psSuperviseur.idPsDelegue = psDelegues[0].idPsAssocie;
        psSuperviseur.numeroPsDelegue = psDelegues[0].numeroPsAssocie;
        psSuperviseur.nomPsDelegue = psDelegues[0].nomPsAssocie;
        
        this.supervisedPsList = psDelegues;
        this.supervisedPsList = [...psDelegues, psSuperviseur];  
        const supervisedPsLocalStorage = localStorage.getItem('supervised_ps');
        if (!isEmpty(supervisedPsLocalStorage) && supervisedPsLocalStorage != '-1') {
            this.selectedSupervised = supervisedPsLocalStorage?.split(';')[0];
        }
        else if (isEmpty(supervisedPsLocalStorage) || supervisedPsLocalStorage) {
            localStorage.setItem('supervised_ps', '-1');
            this.selectedSupervised = -1;
        }
        this.psSvc.getPsDelegueWithRappVisible(this.currentUser.idPs,Number(this.currentUser.idUser)).subscribe((data) => {
            this.psWithRappActiveAndVisible = data.map(p=>p[0]);
            this.psWithRappActiveAndVisible = !this.currentUser.userPreferences.rapproActif ? this.psWithRappActiveAndVisible.filter(p => p !== currentPs.numeroAdeli) : this.psWithRappActiveAndVisible;
            const currentPs = JSON.parse(localStorage.getItem('current_ps'));

            this.supervisedPsListwithRappActiveVisible = this.supervisedPsList.filter(ps => this.psWithRappActiveAndVisible.includes(ps.numeroPsDelegue));
            const supervisedPsLocalStorage = localStorage.getItem('supervised_ps');
            if (!isEmpty(supervisedPsLocalStorage) && supervisedPsLocalStorage != '-1') {
                this.rappVisible = this.psWithRappActiveAndVisible.includes(supervisedPsLocalStorage.split(';')[0]);
                localStorage.setItem('supervised_rapp_visible', this.rappVisible.toString());
                this.showRappbtn=this.rappVisible;
                if (isNullOrUndefined(this.tauxRapp)) {
                    this.getTauxRapp();
                }
            }
            // // Vérifier si la route actuelle est différente de /dashboard
            // const currentUrl = this.router.url;
            // if (currentUrl !== '/dashboard') {
            //     // Forcer la navigation pour réévaluer le guard
            //     this.router.navigate([currentUrl], { skipLocationChange: true }).then(() => {
            //         this.router.navigate([currentUrl]);
            //     });
            // }
            


        });      
    }

    private initFilters(): void {
        if (!isNullOrUndefined(sessionStorage.getItem('dateFin'))) {
            this.endDate = new Date(sessionStorage.getItem('dateFin'));
            this.dateFin = this.endDate;
        } else {
            this.endDate = new Date();
            this.dateFin = new Date();
        }

        if (!isNullOrUndefined(sessionStorage.getItem('dateDebut'))) {
            this.startDate = new Date(sessionStorage.getItem('dateDebut'));
            this.dateDebut = this.startDate;
        } else {
            this.startDate.setDate(new Date().getDate() - this.nbrDays);
            this.dateDebut.setDate(this.dateFin.getDate() - this.nbrDays);
        }

        const diffDays = Math.round(Math.abs((this.endDate.getTime() - this.startDate.getTime()) / (1000 * 60 * 60 * 24)));
        this.selectedRange = this.ranges[this.ranges.indexOf(diffDays)];
    }

    private setLocale() {
        this.localeService.use('fr');
    }
    onChangeInputEnd($event) {
        if($event.target.value === 'Invalid date'){
            $event.target.value = 'Date invalide'
        }

    }
    onChangeInputStart($event) {
        if($event.target.value === 'Invalid date'){
            $event.target.value = 'Date invalide'
        }
    }

    private initStorageConfiguration(): void {
        // if octHas changed before redirection
        // remove sessionFilter
        const removeSession = this.activatedRoute.snapshot.params.removeSession;
        if (!isNullOrUndefined(removeSession) && removeSession) {
            this.removeFilterFromStorage();
        }
    }

    getDerniereTransmission(idPs) {
        if(idPs){
            this.octPsService.getDerniereTransmissionByPs(idPs).subscribe(
                data => {
                    this.derniereTransmission=data
                },
                err => { throw err }
            )
        }
    }
    fillDefaultCriteria() {
        let startDate = new Date();
        this.dateDebutRapp = new Date(startDate);
        this.dateFinRapp = new Date(startDate);
        this.dateDebutRapp.setDate(startDate.getDate() - 14);
        this.dateFinRapp.setDate(startDate.getDate() - 7);
        this.criteria.idPs = (localStorage.getItem('supervised_ps') != null && localStorage.getItem('supervised_ps') != '-1') ? localStorage.getItem('supervised_ps').split(';')[1] : (localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.value.idPs;
        this.criteria.dateComptableFrom = this.convertFormatDate.getFormatDateFr(this.dateDebutRapp);
        this.criteria.dateComptableTo = this.convertFormatDate.getFormatDateFr(this.dateFinRapp);
    }
    getHotLineSession(path: any) {
        this.showHotlineMenu = path.includes('hotline') || path.includes('intervention');
    }
    isEbicsUser() {
        let currentPsStorage = localStorage.getItem('current_ps');
        let careweb_user = localStorage.getItem('careweb_user');
        if (currentPsStorage) {
            const currentPs = JSON.parse(currentPsStorage);
            return currentPs && ( (currentPs.typeRappro == 'EBICS' && currentPs.psEbics && this.isEbicsActif(currentPs.psEbics)))
        }
        else if(careweb_user){
            const currentUser = JSON.parse(careweb_user);
            return currentUser && currentUser.typeRappro=='ebics';
        }
        return false;
    }
    showRapp() {
        if (this.isSupervisor) {
            return this.rappVisible
        }
        if (this.currentUser.idTypeUser === 1 || this.currentUser.idTypeUser === 3) {
            this.rappVisible = (this.isPsSelected() && !this.showHotlineMenu);
        } else {
            this.rappVisible = ((((this.showRappBancMenu && !this.isPsSelected()) || (this.isEbicsUser() && !this.isPsSelected())) && this.currentUser.userPreferences.rapproActif && this.isSupervisor==false) || (this.isSupervisor && (this.rappVisible || this.selectedSupervised==-1) && this.hasRapproVisible()))        }
        if (!this.rappVisible) {
            return false;
        }
        
        return true;
    }
    hasRapproVisible() {
        let currentPsStorage = localStorage.getItem('current_ps');
        if (currentPsStorage) {
            const currentPs = JSON.parse(currentPsStorage);
            return currentPs.rappVisible;
        }
        return false;
    }
    getTauxRapp() {
        if (this.rappVisible) {
            forkJoin([
                this.rspRappSummarySvc.getRspAvecPaiementEtLibelle(this.criteria),
                this.rspRappSummarySvc.getRspAvecPaiementRapproches(this.criteria)
            ]).subscribe((data) => {
                if (data) {
                    // RSP avec virement rapprochables
                    if (data[0]) {
                        this.rspAvecPaiementEtLibelle = data[0];
                        this.rspAvecPaiementEtLibelle.sum = !isNaN(parseFloat(data[0].sum) / 100)
                            ? (parseFloat(data[0].sum) / 100).toFixed(2)
                            : '0';
                    }
                    // RSP avec virement rapproché
                    if (data[1]) {
                        this.rspAvecPaiementRapproches = data[1];
                        this.rspAvecPaiementRapproches.sum = !isNaN(parseFloat(data[1].sum) / 100)
                            ? (parseFloat(data[1].sum) / 100).toFixed(2)
                            : '0';
                    }
                    // Taux rapprochement
                    this.calculateTauxRapprochement();
                }
            });
        }
    }
    calculateTauxRapprochement() {
        if (this.rspAvecPaiementEtLibelle != null && this.rspAvecPaiementRapproches != null) {
            this.tauxRapp = !isNaN(this.rspAvecPaiementRapproches.count * 100 / this.rspAvecPaiementEtLibelle.count)
                ? (this.rspAvecPaiementRapproches.count * 100 / this.rspAvecPaiementEtLibelle.count).toFixed(2)
                : '0';
        }
        let taux = parseFloat(this.tauxRapp);
        this.badge = taux > 80 ? 'badge badge-success ml-2' : taux > 30 ? 'badge badge-warning ml-2' : 'badge badge-danger ml-2';
    }
    btnRappClicked(){
        this.router.navigate(['/rapp-dashboard']);
    }
}
