<careweb-help></careweb-help>
<div class="full-careweb-container">
	<div class="grid-container" id="grid">

		<!-- COL KPI --->
		<div>

			<!--  FILTRES  -->
			<div class="row mt-3">
				<div class="col-lg-6 mb-3">
					<h2 class="title-border">Suivre mes rapprochements
						<careweb-open-help code="help-014"></careweb-open-help>
					</h2>
				</div>
				<div class="col-lg-3"></div>
				<div class="col-lg-3 ">
					<form #retourForm="ngForm" class="form-content p-relative" id="period-form">
						<div class="row div-dropdown">
							<button class="btn btn-action align-left w-100 no-wrap" data-toggle="collapse"
								(click)="isCollapsed = !isCollapsed">
								<i class="fa fa-calendar"></i>
								Période du {{dateDebut | date:'shortDate'}} au {{dateFin | date:'shortDate'}}
								<i class="fa fa-sort-down p-relative"></i>
							</button>
						</div>
						<div class="box-content row box-dropdown p-absolute" data-toggle="collapse"
							[ngClass]="{ 'collapse': !isCollapsed }">
							<div class="col-md-12">
								<div class="row align-left" *ngFor="let range of ranges">
									<div class="col-md-12">
										<span (click)="datePeriodeClick(range)"
											[ngClass]="{'period-option-active': selectedRange == range}"
											class="period-option cursor-pointer">Les {{range}} derniers jours</span>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<!--- INDICATEURS HAUT --->
			<div class="row">

				<div class="col-md-4">
					<a class="link-box">
						<div class="box-content box-content-default pointer-cursor"
							(click)="voirRspAvecVirRapprochable($event)"
							(contextmenu)="voirRspAvecVirRapprochable($event)">
							<div class="title">
								<span>RSP</span>
								<div class="subtitle text-primary">avec virement rapprochables</div>
								<div class="row" style="margin-top:40px">
									<div class="col-md-6" style="border-right:solid 2px">
										<div class="row">
											<div class="col-md-12">Volume</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<div class="number" style="font-size:2rem">{{(rspAvecPaiementEtLibelle
													!= null) ? rspAvecPaiementEtLibelle.count : '0'}}</div>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="row">
											<div class="col-md-12">Montant</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<div class="number" style="font-size:2rem">
													{{rspAvecPaiementEtLibelle.sum | number:'':'fr-FR'}}€</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row align-right">
								<div class="col-md-12 mt-2">
									<a class="more">Voir</a>
								</div>
							</div>
						</div>
					</a>
				</div>


				<div class="col-md-4">
					<a class="link-box">
						<div class="box-content box-content-default pointer-cursor"
							(click)="voirRspAvecVirRapprochable($event,true)"
							(contextmenu)="voirRspAvecVirRapprochable($event,true)">
							<div class="title">
								<span>RSP</span>
								<div class="subtitle text-primary">avec virement rapproché</div>
								<div class="row" style="margin-top:40px">
									<div class="col-md-6" style="border-right:solid 2px">
										<div class="row">
											<div class="col-md-12">Volume</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<div class="number" style="font-size:2rem">{{(rspAvecPaiementRapproches
													!= null) ? rspAvecPaiementRapproches.count : '0'}}</div>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="row">
											<div class="col-md-12">Montant</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<div class="number" style="font-size:2rem">{{
													rspAvecPaiementRapproches.sum | number:'':'fr-FR'}}€</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row align-right">
								<div class="col-md-12 mt-2">
									<a class="more">Voir</a>
								</div>
							</div>
						</div>
					</a>
				</div>
				<div class="col-md-4">
					<a class="link-box">
						<div class="box-content box-content-default">
							<div class="title">
								<span>Taux</span>
								<div class="subtitle text-success">rapprochement</div>
								<div class="number">{{this.tauxRapp | number:'1.2-2':'fr-FR'}}%</div>
								<div *ngIf="isAuthorized" class="fs-6">Taux global: {{this.tauxGlobal |
									number:'1.2-2':'fr-FR'}}%</div>
							</div>
							<!-- <a class="more" href="#">Voir</a> -->
						</div>
					</a>
				</div>
			</div>

			<!--- FIN INDICATEURS HAUT  --->

			<!--- VRT PAR DATE COMPTABLE--->
			<div class="row">
				<div class="col-md-12">
					<div class="row">
						<div class="col-md-12">
							<a class="link-box">
								<div class="box-content box-content-default">
									<div class="title">
										<span>Rapprochements par date comptable</span>
										<canvas id="chartVirements" width="200" height="70"
											style="margin-top:20px; cursor: pointer;">
											{{chartVirements}}
										</canvas>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>


			<!--- ORGANISMES--->
			<div class="row">
				<div class="col-md-6">
					<!--- RSP / VRT -->
					<div class="row">
						<div class="col-md-12">
							<a class="link-box">
								<div class="box-content box-content-default ">
									<div class="title">
									<div class="chart-header " >
										<div class="pagination-buttons btn-group" role="group" [style.visibility]="'hidden'">
											<button  class="btn btn-light" (click)="previousPage()" style="width: 30px;height: 25px;"
												[disabled]="paginator.pageIndex === 0">
												<span style="font-size: 20px;color:black;height: 50%;">&lt;</span>
											</button>

											<button  class="btn btn-light" (click)="nextPage()" style="width: 30px;height: 25px;"
												[disabled]="paginator.pageIndex === paginator.getNumberOfPages() - 1">
												<span style="font-size: 20px;color: black;">&gt;</span>
											</button>
										</div>

										<span class="title">Rapprochements par organisme</span>

										<div class="pagination-buttons btn-group" role="group">
											<button  class="btn btn-light" (click)="previousPage()" style="width: 30px;height: 25px;"
												[disabled]="paginator.pageIndex === 0">
												<span style="font-size: 15px;color:black;">&lt;</span>
												<!-- <i class="fa fa-arrow-left" aria-hidden="true"></i> -->
											</button>

											<button  class="btn btn-light" (click)="nextPage()" style="width: 30px;height: 25px;"
												[disabled]="paginator.pageIndex === paginator.getNumberOfPages() - 1">
												<span style="font-size: 15px;color: black;">&gt;</span>
												<!-- <i class="fa fa-arrow-right" aria-hidden="true"></i> -->
											</button>
										</div>

										<!-- Mat-paginator used as a reference, but hidden -->
										<mat-paginator #paginator [length]="totalItems" [pageSize]="pageSize"
											(page)="onPageChange($event)" [hidePageSize]="true"
											[showFirstLastButtons]="false"></mat-paginator>
											
									</div>
									<canvas id="chartTop10Orga" style="margin-top: 15px;"></canvas>
								</div>
									
								</div>

							</a>
						</div>
					</div>
				</div>

				<div class="col-md-6">
					<!--- RSP / VRT -->
					<div class="row">
						<div class="col-md-12">
							<a class="link-box">
								<div class="box-content box-content-default">
									<div class="title">
										<span>TOP 10 Organismes (par rapprochement auto)</span>
										<canvas id="chartTop10OrgaByRappAuto" width="200" height="100"
											style="margin-top:20px">
											{{chartTop10OrgaByRappAuto}}
										</canvas>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>

		</div>

	</div>
</div>