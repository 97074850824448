import { AccordionGroupComponent } from './../../../components/accordion/accordion-group.component';
import { DateFromToFilterComponent } from './../../../components/date-from-to/date-from-to-filter.component';
import { OrganismesService } from './../../organismes/organismes/organismes.service';
import {  ColDef, GridOptions, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { DatePipe } from '@angular/common';
import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { findIndex, isEmpty, uniqBy } from 'lodash-es';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { ColumnSelectorComponent } from '../../../components/ag-grid/grid-column-selector.component';
import { ColumnSpec } from '../../../components/ag-grid/grid-column-spec';
import { AuthService } from '../../../core/services/auth.service';
import { ArlDataRaw } from '../../../data/arlDataRaw';
import { ARLFilterRaw } from '../../../data/filters/arl-filter';
import { FilterItem } from '../../../data/filters/filter-item';
import { PsDelegueDataRaw } from '../../../data/PsDelegueDataRaw';
import { PsDelegueService } from '../../ps/service/ps-delegue.service';
import { ArlLotPreviewComponent } from './arl-lot-details/arl-lot-preview.component';
import { ARLService } from './arl.service';
import { ArlsFilterSvc } from './arls-filter.service';
import { ConvertDateFormat } from '../../../core/utilities/convert-date-format.service';
import { Router } from '@angular/router';
import { OctService } from '../../../core/services/oct.service';
import { PsService } from '../../ps/ps.service';
import { RouteHistoryService } from '../../../shared/route-history.service';
import { ArlDetailsModalComponent } from './arl-details-modal/arl-details-modal.component';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { StorageService } from '../../../shared/storage-service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ExportUtilService } from '../../../core/utilities/export-util.service';
import { ExportModalComponent, ExportMode } from '../../export-modal/export-modal.component';
import { saveAs } from 'file-saver';
import {ColumnOrganismTooltipComponent} from "../../../components/column-tooltip/column-organism-tooltip-component";
import {TransformService} from "../../../core/services/transform.service";
import { CellClickedEvent } from 'ag-grid-community';
import {ExportState, State} from "../../../shared/model/storage.model";
import { LinkCellRendererComponent } from './arl-link-renderer';

@Component({
	selector: 'careweb-app-arl',
	templateUrl: './arl.component.html',
	styleUrls: ['./arl.component.scss']
})
export class ARLComponent implements OnInit, OnDestroy {
	params: any;
	initHidden = true;
	showFilter = false;
	arlGridHelper: AgGridHelper;
	filters: ARLFilterRaw;
	filterList: FilterItem[];
	arlsDataRaw: ArlDataRaw;
	datePipe: DatePipe;
	currentOct;
	@ViewChild(ColumnSelectorComponent, { static: true })
	columnSelector: ColumnSelectorComponent;
	psDelegueListFilter = new Array<PsDelegueDataRaw>();
	totalResultsHidden = false;
	gridDataRaw: GridDataRaw;

	@ViewChild('dateEnvoie')
	dateEnvoie: DateFromToFilterComponent;
	isDateRangeValid = true;

	@ViewChild('dateAccordion')
	dateAccordion: AccordionGroupComponent;
	serverName: string;
	public rowCount: number = 0;
	private openedModal: BsModalRef;
	export: boolean = false;
	exportState: ExportState;
	static MIN_WIDTH = 1350;

	constructor(
		private arlSvc: ARLService,
		private authSvc: AuthService,
		private arlFilterSvc: ArlsFilterSvc,
		private psService: PsService,
		private psDelegueSVC: PsDelegueService,
		private convertFormatDate: ConvertDateFormat,
		private octSvc: OctService,
		private router: Router,
		private routeHistory: RouteHistoryService,
		private modalService: BsModalService,
		private organismesService: OrganismesService,
		private storageService: StorageService,
		private transformService: TransformService
	) {
		//Get grid size from session if already stored
		const sessionPageSize = this.storageService.get('arlsPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

		this.arlGridHelper = new AgGridHelper('arls', this.gridDataRaw.size, 10);
		const body = document.body;
		body.className = 'app';
		this.arlsDataRaw = new ArlDataRaw();
		this.convertFormatDate = new ConvertDateFormat();
	}

	ngOnInit() {
		// verify previous url if not details remove session currentPage
		if (this.routeHistory?.getPreviousUrl()?.indexOf('ps-details') === -1) {
			sessionStorage['arlsCurrentPage'] = null;
		}

		//Get current OCT
		this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));
		if (isNullOrUndefined(this.currentOct)) {
			this.octSvc.currentOctSubject.subscribe((value) => {
				this.currentOct = value;
			});
		}

		var parsedFilter = JSON.parse(sessionStorage.getItem('arlFilter'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
			// Convert String dates to Dates format
			this.filters.dateReceptionPartitionFrom
				? (this.filters.dateReceptionPartitionFrom = new Date(parsedFilter.dateReceptionPartitionFrom))
				: '';
			this.filters.dateReceptionPartitionTo
				? (this.filters.dateReceptionPartitionTo = new Date(parsedFilter.dateReceptionPartitionTo))
				: '';
			this.filters.dateReceptionPartitionExacte
				? (this.filters.dateReceptionPartitionExacte = new Date(parsedFilter.dateReceptionPartitionExacte))
				: '';
		} else {
			this.filters = new ARLFilterRaw();
			this.filterList = [new FilterItem()];
		}

		// Apply numero Ps on the filter if exist
		const numeroPsLocalStorage = localStorage.getItem('numeroPs');
		if (!isEmpty(numeroPsLocalStorage)) {
			if(this.filters.numeroPs && this.filters.numeroPs != numeroPsLocalStorage) {
				this.filters =  new ARLFilterRaw();
			}
			this.filters.numeroPs = numeroPsLocalStorage;
			sessionStorage['arlFilter'] = JSON.stringify(this.filters);
		}
		// Apply numero Ps supervised on the filter if exist
		var numeroPsSupervisedLocalStorage = localStorage.getItem('supervised_ps');
		if (!isEmpty(numeroPsSupervisedLocalStorage)) {
			if (numeroPsSupervisedLocalStorage === '-1') {
				this.filters.numeroPScomboBox = null;
			}
			else {
				numeroPsSupervisedLocalStorage = numeroPsSupervisedLocalStorage.split(';')[0];
				if (this.filters.numeroPScomboBox && this.filters.numeroPScomboBox != numeroPsSupervisedLocalStorage) {
					this.filters = new ARLFilterRaw();
				}
				this.filters.numeroPScomboBox = numeroPsSupervisedLocalStorage;
				sessionStorage['arlFilter'] = JSON.stringify(this.filters);
			}
		}
		// Initiate date pipe format
		this.datePipe = new DatePipe('fr-FR');

		this.arlGridHelper.gridOptions = <GridOptions>(<unknown>{
			defaultColDef: {
				sortable: true,
				resizable: true,
				suppressMenu: true
			},
			columnDefs: this.getColumnDefs(),
			context: {
				componentParent: this
			},
			components: {
				formatArlComponent: this.formatArlComponent,
				columnTooltipCellRenderer : ColumnOrganismTooltipComponent
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			localeText: this.arlGridHelper.getLocaleText(),
			suppressContextMenu: true,
			enableCellTextSelection: true,
			getRowClass: (params) => this.stylePreviouslyClickedRow(params),
			onGridReady: (params) => this.onGridReady(params),
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onColumnMoved: (params) => this.onColumnChanged(params),
			onColumnVisible: (params) => this.onColumnChanged(params)
		});

		// load combobox of numero PS if user is PS
		if (!this.isAuthorized()) {
			this.loadComboBoxFilterNumeroPS_Supervise();
		}
	}

	stylePreviouslyClickedRow(params: any) {

		let rowNode = params.node;
		if (!isNullOrUndefined(rowNode.data)) {
			let previouslyClickedRow = sessionStorage.getItem('previouslyClickedArlId');
			if (rowNode.data.idArl == previouslyClickedRow) {
				return 'ag-row-selected';
			}
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		let width = event.target.innerWidth;
		if (width >= 1350){
			this.arlGridHelper?.gridApi.sizeColumnsToFit();
		}
	}
	onGridSizeChanged(params) {
		this.arlGridHelper.gridApi.sizeColumnsToFit();
	}

	onGridReady(params) {
		var width = window.innerWidth;
		const ctx = this;
		ctx.initFilter();
		this.arlGridHelper.gridApi = params.api;
		this.arlGridHelper.gridColumnApi = params.columnApi;
		this.initSorting();
		this.restoreGridState();
		this.initColumnsDisplay();
		const parsedFilter = JSON.parse(sessionStorage.getItem('arlFilter'));
		if (width >= ARLComponent.MIN_WIDTH) {
			this.arlGridHelper.gridApi.sizeColumnsToFit();
		}
		if (parsedFilter) {
			Object.keys(parsedFilter).forEach(
				(key) => (parsedFilter[key] === undefined || parsedFilter[key] === null ? delete parsedFilter[key] : {})
			);
		}
		if (!isEmpty(parsedFilter)) {
			this.launchSearch();
		} else {
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.arlGridHelper.gridSortModel(), []);
					let criteria;
					// Connect By Admin Or Hotline
					if (!ctx.isAuthorized()) {
						const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
						const criteriaArl = new ArlDataRaw();
						criteria = {
							page: ctx.arlGridHelper.gridApi.paginationGetCurrentPage(),
							size: ctx.arlGridHelper.gridApi.paginationGetPageSize(),
							idPs: userConnected.idPs,
							sorts
						};
						ctx.arlSvc.searchArlsByIdPsWithDelegues(criteria, criteriaArl).subscribe(
							(data) => {
								ctx.rowCount = data.totalElements;
								ctx.arlGridHelper.manageNoRowsOverlay(ctx.rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
								const pageN = Number.parseInt(sessionStorage.getItem('arlsCurrentPage'));
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.arlGridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.arlGridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['arlsCurrentPage'] = null;
								ctx.totalResultsHidden = true;
							},
							() => paramsRows.fail()
						);
					}
				}
			};
			this.arlGridHelper.gridApi.setServerSideDatasource(ctx.isAuthorized() ? null : dataSource);
		}
	}

	existGroupNumeroPS() {
		return this.isAuthorized() || this.psDelegueListFilter.length !== 0;
	}

	loadComboBoxFilterNumeroPS_Supervise() {
		const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
		if (!isEmpty(userConnected)) {
			if (!isNullOrUndefined(userConnected.idPs)) {
				this.psDelegueSVC.getPsDelegueByIdPs(userConnected.idPs).subscribe((data) => {
					if (!isNullOrUndefined(data) && !isEmpty(data)) {
						this.psDelegueListFilter = data;
						const psCurrent = new PsDelegueDataRaw();
						psCurrent.idPsDelegue = this.psDelegueListFilter[0].idPsAssocie;
						psCurrent.numeroPsDelegue = this.psDelegueListFilter[0].numeroPsAssocie;
						psCurrent.nomPsDelegue = this.psDelegueListFilter[0].nomPsAssocie;
						this.psDelegueListFilter.push(psCurrent);
					}
					isNullOrUndefined(this.arlGridHelper.gridColumnApi) ? null : this.arlGridHelper.gridColumnApi.setColumnVisible('numeroPs', this.existGroupNumeroPS());
				});
			}
		}
	}

	restoreGridState() {
		this.arlGridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.arlGridHelper.paginationPageSize;
	}

	toggleFilterDisplay() {
		let width =window.innerWidth;
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
			if(!this.showFilter && width>1500){
				setTimeout(() => {
					this.arlGridHelper.gridApi.sizeColumnsToFit();
				}, 200);
			}
		}
	}

	resetGridState() {
		this.arlGridHelper.resetGridState();
		this.gridDataRaw.size = this.arlGridHelper.defaultPaginationPageSize;
		this.initColumnsDisplay();
		this.initSorting();
		//this.resetAllFilter();
	}

	ngOnDestroy() {
		sessionStorage['arlFilter'] = JSON.stringify(this.filters);
		sessionStorage['arlsCurrentPage'] = this.arlGridHelper.gridApi.paginationGetCurrentPage();
		this.arlGridHelper.saveGridStatePrefix();
	}

	refresh(): boolean {
		return false;
	}

	resetFilter() {
		this.arlFilterSvc.reset();
	}

	initFilter() {
		// Liste des filtres
		this.resetFilter();
		this.updateFilterList(this.filters);
	}

	resetAllFilter(): void {
		this.dateEnvoie.isDateRangeValid = true;
		this.isDateRangeValid = true;

		if (!this.isAuthorized()) {
			this.initFilter();
			this.filters = new ARLFilterRaw();
			this.launchSearch();
			this.arlFilterSvc.reset();
		} else {
			this.filters = new ARLFilterRaw();
			this.rowCount = 0;
			this.arlFilterSvc.reset();
			this.updateFilterList(this.filters);
			this.clearGridFromData();
		}
		sessionStorage.removeItem('arlFilter');
	}

	launchSearch(): void {
		const ctx = this;
		ctx.totalResultsHidden = true;
		if (this.isFormValid()) {
			this.arlsDataRaw.numeroPs = this.isAuthorized()
				? this.filters.numeroPs ? this.filters.numeroPs : null
				: this.filters.numeroPScomboBox ? this.filters.numeroPScomboBox : null;
			this.filters.numLot = this.formatNumLot(this.filters.numLot); //@TODO: to put in utils service
			this.arlsDataRaw.numLot = this.filters.numLot || null;
			this.arlsDataRaw.dateReceptionPartitionFrom = this.convertFormatDate.getFormatDateFr(
				this.filters.dateReceptionPartitionFrom
			);
			this.arlsDataRaw.dateReceptionPartitionTo = this.convertFormatDate.getFormatDateFr(
				this.filters.dateReceptionPartitionTo
			);
			this.arlsDataRaw.dateReceptionPartitionExacte = this.convertFormatDate.getFormatDateFr(
				this.filters.dateReceptionPartitionExacte
			);
			this.arlsDataRaw.rapprochement = isNullOrUndefined(this.filters.rapprochement)
				? null
				: this.filters.rapprochement;
			this.arlsDataRaw.lotSecurise = isNullOrUndefined(this.filters.securise) ? null : this.filters.securise;
			this.arlsDataRaw.isArpReceived = isNullOrUndefined(this.filters.arp) ? null : this.filters.arp;
			this.arlsDataRaw.signeArl = isNullOrUndefined(this.filters.statut) ? null : this.filters.statut;
			this.updateFilterList(this.filters);
			Object.keys(this.arlsDataRaw).forEach((key) => {
				if (isNullOrUndefined(this.arlsDataRaw[key])) {
					delete this.arlsDataRaw[key];
				}
			});
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.arlGridHelper.gridSortModel(), []);
					if (ctx.isAuthorized()) {
						ctx.arlSvc
							.getArlsFilteredList(
								{
									page: ctx.arlGridHelper.gridApi.paginationGetCurrentPage(),
									size: ctx.arlGridHelper.gridApi.paginationGetPageSize(),
									idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
									sorts
								},
								ctx.arlsDataRaw
							)
							.subscribe(
								(data) => {
									ctx.rowCount = data.totalElements;
									ctx.arlGridHelper.manageNoRowsOverlay(ctx.rowCount);
									paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
									const pageN = Number.parseInt(sessionStorage.getItem('arlsCurrentPage'));
									if (
										!isNullOrUndefined(pageN) &&
										pageN != ctx.arlGridHelper.gridApi.paginationGetCurrentPage() &&
										pageN > 0
									) {
										ctx.arlGridHelper.gridApi.paginationGoToPage(pageN - 1);
									}
									sessionStorage['arlsCurrentPage'] = null;
								},
								() => paramsRows.fail()
							);
					} else {
						// Connect By PS
						const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
						const criteria = {
							page: ctx.arlGridHelper.gridApi.paginationGetCurrentPage(),
							size: ctx.arlGridHelper.gridApi.paginationGetPageSize(),
							idPs: userConnected.idPs,
							sorts
						};
						ctx.arlSvc.searchArlsByIdPsWithDelegues(criteria, ctx.arlsDataRaw).subscribe(
							(data) => {
								ctx.rowCount = data.totalElements;
								ctx.arlGridHelper.manageNoRowsOverlay(ctx.rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
								const pageN = Number.parseInt(sessionStorage.getItem('arlsCurrentPage'));
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.arlGridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.arlGridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['arlsCurrentPage'] = null;
							},
							() => paramsRows.fail()
						);
					}
				}
			};

			this.arlGridHelper.gridApi.setServerSideDatasource(dataSource);
			sessionStorage['arlFilter'] = JSON.stringify(this.filters);
		}
	}

	onKeyDown(event) {
		this.launchSearch();
	}

	formatNumLot(numLot) {
		if (!isNullOrUndefined(numLot)) {
			numLot = numLot.trim().toUpperCase();
			if (numLot.length == 3) {
				return numLot;
			} else if (numLot.length == 2) {
				return '0' + numLot;
			} else if (numLot.length == 1) {
				return '00' + numLot;
			}
		} else {
			return null;
		}
	}

	updateFilterList(filters) {
		const ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (!isNullOrUndefined(filters[key]) && filters[key] !== '') {
				ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id !== null;
		});
	}

	translateFilterLabelValue(key, value) {
		let translatedKey = key;
		let formattedValue = value;
		let formattedKey = key;
		if (key === 'numeroPs' || key === 'numeroPScomboBox') {
			translatedKey = 'Numéro de PS';
		} else if (key === 'numLot') {
			translatedKey = 'N° du lot';
		} else if (key === 'statut') {
			translatedKey = 'Statut';
			formattedValue = value ? 'Positif' : 'Négatif';
		} else if (key === 'rapprochement') {
			translatedKey = 'Rapproché';
			formattedValue = value ? 'Oui' : 'Non';
		} else if (key === 'arp') {
			translatedKey = 'AR_P (ARL provisoire)';
			formattedValue = value ? 'Oui' : 'Non';
		} else if (key === 'securise') {
			translatedKey = 'Sécurisé';
			formattedValue = value ? 'Oui' : 'Non';
		} else if (key === 'dateReceptionPartitionFrom') {
			translatedKey = 'Lots envoyés après le';
			formattedValue = this.formatDateForTags({ value: value });
		} else if (key === 'dateReceptionPartitionTo') {
			translatedKey = 'Lots envoyés avant le';
			formattedValue = this.formatDateForTags({ value: value });
		} else if (key === 'dateReceptionPartitionExacte') {
			translatedKey = 'Lots envoyés le';
			formattedValue = this.formatDateForTags({ value: value });
		}
		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	selectColumnEvent(col: ColumnSpec) {
		this.arlGridHelper.gridColumnApi.setColumnVisible(col.id, true);
		this.arlGridHelper.gridApi.sizeColumnsToFit();
	}

	deselectColumnEvent(col: ColumnSpec) {
		this.arlGridHelper.gridColumnApi.setColumnVisible(col.id, false);
		this.arlGridHelper.gridApi.sizeColumnsToFit();
	}

	initColumnsDisplay() {
		const allColumns = this.arlGridHelper.gridColumnApi.getColumns();
		const selectedColumns = this.arlGridHelper.gridColumnApi.getAllDisplayedColumns();
		this.columnSelector.setItems(this.getColumnSpecs(allColumns));
		this.columnSelector.setSelectedItems(this.getColumnSpecs(selectedColumns));
	}

	private getColumnSpecs(columns: any[]): ColumnSpec[] {
		let allowedColumns = [
			'numeroPs',
			'nomPs',
			'raisonSocialeLot',
			'dateReception',
			'libelleOrganisme',
			'codeOfficielOrganisme',
			'nbFactures',
			'montantFacture'
		];

		if (!this.isAuthorized()) {
			// Le compte PS ne doit pas voir les 3 premières colonnes
			allowedColumns = allowedColumns.slice(3);
		}

		return columns
			.filter(
				(cd) => cd.colDef.headerName && cd.colDef.headerName.length > 0 && allowedColumns.indexOf(cd.colId) > -1
			)
			.map((cd) => {
				return {
					id: cd.colDef.field,
					name: cd.colDef.headerName,
					label: cd.colDef.headerName
				};
			});
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	removeFilter(id) {
		const ctx = this;
		delete ctx.filters[id];
		let currentFilterList = ctx.filterList;
		const index = findIndex(currentFilterList, (filt) => filt.id === id);
		currentFilterList.splice(index, 1);
		ctx.filterList = currentFilterList;
		if ((Array.isArray(ctx.filterList) && ctx.filterList.length) || !ctx.isAuthorized()) {
			this.fillFilterObjectFromTagsOnly();
			ctx.launchSearch();
		} else {
			ctx.clearGridFromData();
			this.rowCount = 0;
		}
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new ARLFilterRaw();
		this.filterList.forEach(filter => {
			this.filters[filter.id] = filter.value2;
		});
	}

	private getColumnDefs(): ColDef[] {
		const ctx = this;
		return [
			{
				headerName: 'N° du lot',
				field: 'numLot',
				lockVisible: true,
				cellStyle: this.arlGridHelper.centerContent(),
				cellClass: (params) => this.styleLink(params),
				cellRenderer: ArlLotPreviewComponent,
				minWidth: 60,
				maxWidth: 80,
				cellRendererParams: {
					redirect: 'lotDetails'
				}
			},
			{
				headerName: 'N° de PS',
				field: 'numeroPs',
				valueFormatter: this.trimColumn,
				lockVisible: true,
				minWidth: 100,
				maxWidth: 150,
				cellClass: (params) => this.styleLink(params),
				hide: !ctx.existGroupNumeroPS(),
				cellStyle: this.arlGridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Nom du titulaire',
				field: 'nomPs',
				valueFormatter: this.trimColumn,
				lockVisible: true,
				minWidth: 100,
				hide: !ctx.isAuthorized(),
				onCellClicked: (params) => this.showArlDetail(params)
			},
			{
				headerName: 'Etablissement',
				field: 'raisonSocialeLot',
				lockVisible: true,
				valueFormatter: this.trimColumn,
				minWidth: 250,
				hide: !ctx.isAuthorized(),
				onCellClicked: (params) => this.showArlDetail(params)
			},
			{
				headerName: "Date d'envoi",
				field: 'dateReceptionPartition',
				lockVisible: true,
				valueFormatter: this.formatDate,
				minWidth: 120,
				maxWidth: 150,
				cellStyle: this.arlGridHelper.centerContent(),
				onCellClicked: (params) => this.showArlDetail(params)
			},
			{
				headerName: 'ARL reçu le',
				field: 'dateReception',
				lockVisible: true,
				valueFormatter: this.formatDate,
				minWidth: 120,
				maxWidth: 150,
				cellStyle: this.arlGridHelper.centerContent(),
				onCellClicked: (params) => this.showArlDetail(params)
			},
			{
				headerName: 'Organisme dest.',
				field: 'libelleLong',
				cellRenderer: LinkCellRendererComponent,
				cellRendererParams: {
					suppressDoubleClickExpand: true,
					innerRenderer: 'columnTooltipCellRenderer'
				},
				valueFormatter: this.trimColumn,
				cellClass: (params) => this.styleLink(params),
				minWidth: 300,
				lockVisible: true,
				cellStyle: this.arlGridHelper.centerContent(),
				onCellClicked: (params) => this.onCellOrganismeClicked(params),
			},
			{
				headerName: 'Code officiel',
				field: 'codeOfficielOrganisme',
				valueFormatter: this.trimColumn,
				cellStyle: this.arlGridHelper.centerContent(),
				lockVisible: true,
				minWidth: 90,
				maxWidth: 100,
				onCellClicked: (params) => this.showArlDetail(params)
			},
			{
				headerName: 'Nb factures',
				field: 'nbFactures',
				cellStyle: this.arlGridHelper.centerContent(),
				lockVisible: true,
				minWidth: 80,
				maxWidth: 100,
				onCellClicked: (params) => this.showArlDetail(params)
			},
			{
				headerName: 'Montant total du lot',
				field: 'montantFacture',
				lockVisible: true,
				valueFormatter: this.formatCurrency,
				cellStyle: this.arlGridHelper.centerContent(),
				minWidth: 80,
				onCellClicked: (params) => this.showArlDetail(params)
			},
			{
				headerName: 'Rapprochement',
				field: 'rapprochement',
				lockVisible: true,
				minWidth: 120,
				maxWidth: 120,
				valueFormatter: this.formatRapprochement,
				cellStyle: this.arlGridHelper.centerContent(),
				onCellClicked: (params) => this.showArlDetail(params)
			},
			{
				headerName: 'Statut',
				field: 'signeArl',
				lockVisible: true,
				minWidth: 80,
				maxWidth: 80,
				cellRenderer: (params) => this.formatArlComponent(params),
				cellStyle: this.arlGridHelper.centerContent(),
				onCellClicked: (params) => this.showArlDetail(params)
			}
		];
	}


	styleLink(params) {
		if (params.colDef.field !== 'numLot' || (params.colDef.field === 'numLot' && params.data.rapprochement !== false)) {
			let previouslyClickedRow = sessionStorage.getItem('previouslyClickedArlId');

			if (!isNullOrUndefined(params.data) && params.data.idArl === Number(previouslyClickedRow)) {
				return 'link-in-selected-row';
			} else {
				return 'link-in-not-selected-rows';
			}
		}
	}

	onCellOrganismeClicked(params: CellClickedEvent): void {
		if (!this.arlGridHelper.isCellTextSelected()) {
			var ctx = this;
			let organismeId = params.data.idOrganisme;
			sessionStorage.previouslyClickedArlId = params.node.data.idArl;
			ctx.organismesService
				.getOrganismeTypeById(organismeId)
				.subscribe((organismeType) => {
					const url = this.router.createUrlTree(['/careweb-client/organisme-details', organismeId, organismeType]).toString();
					window.open(url, '_blank');
				});
		}

	}

	onCellClicked(params) {
		var ctx = this;
		if (this.isAuthorized() && !this.arlGridHelper.isCellTextSelected()) {
			sessionStorage.previouslyClickedArlId = params.node.data.idArl;
			ctx.psService
				.getPsByNumAdeli({
					numAdeli: params.value
				})
				.subscribe((data) => {
					this.router.navigate(['/ps-details', data[0].idPs]);
				});
		}
	}

	private initSorting() {
		const sort = [
			{
				colId: 'dateReception',
				sort: 'desc',
				sortIndex: 0
			}
		];
		this.arlGridHelper.applyColumnState(sort);
	}

	isFormValid(): boolean {
		let dateInputValid = true;
		if (this.filters.dateReceptionPartitionFrom && this.filters.dateReceptionPartitionTo) {
			if ((this.filters.dateReceptionPartitionFrom.getTime() > this.filters.dateReceptionPartitionTo.getTime()) || !this.isDateRangeValid) {
				dateInputValid = false;
				this.dateAccordion.expansionPannel.open();
				this.dateEnvoie.showTooltipDebut();
				this.dateEnvoie.showTooltipFin();
			} else {
				this.dateEnvoie.hideTooltlipDebut();
				this.dateEnvoie.hideTooltlipFin();
			}
		}
		return dateInputValid;
	}

	/**
	 * Fonction qui ajoute des zero avant le nombre pour compléter trois chiffres
	 * ex: 1 => devient 001
	   */
	paddy(num, padlen) {
		const pad = new Array(1 + padlen).join('0');
		return (pad + num).slice(-pad.length);
	}

	formatDate = (params): string => {
		return this.datePipe.transform(params.value, 'short');
	};

	formatDateForTags = (params): string => {
		if (params.value !== null && params.value != 'Invalid Date') {
			return this.datePipe.transform(params.value, 'dd/MM/yyyy');
		}
	};

	formatArlComponent(params) {
		if (!isNullOrUndefined(params.value)) {
			if (params.value == true) {
				return "<span class='ui-grid-cell-contents bullet-label green status bullet' container='body' containerclass='tooltip-grid' placement='top' ng-reflect-klass='ui-grid-cell-contents bullet-l' ng-reflect-ng-class='green,status,bullet'> Positif </span>";
			} else {
				return "<span class='ui-grid-cell-contents bullet-label red status bullet' container='body' containerclass='tooltip-grid' placement='top' ng-reflect-klass='ui-grid-cell-contents bullet-l' ng-reflect-ng-class='red,status,bullet'> Négatif </span>";
			}
		}
	}

	formatSigneArl = (params): string => {
		if (params.data) {
			return !isNullOrUndefined(params.value) && params.value ? 'Positif' : 'Négatif';
		}
	};

	formatRapprochement = (params): string => {
		if (params.data) {
			return params.value ? 'Oui' : 'Non';
		}
	};

	formatCurrency = (params): string => {
		if (params.data) {
			if (params.value) {
				return params.value / 100 + ' €';
			} else {
				return '0.00 €';
			}
		}
	};

	trimColumn = (params): string => {
		if (params.data) {
			if (!isNullOrUndefined(params.value)) {
				return params.value.trim();
			}
		}
	};

	// disable appliquer les filtres button
	disableSearch() {
		if (
			this.isAuthorized() &&
			Object.values(this.filters).every((o) => o === null || o === '' || o === undefined)
		) {
			return true;
		} else {
			return false;
		}
	}

	//clear grid
	clearGridFromData() {
		this.totalResultsHidden = false;
		this.arlGridHelper.gridApi.setServerSideDatasource(null);
		this.rowCount = 0;
	}

	showArlDetail(params) {
		if (!this.arlGridHelper.isCellTextSelected()) {
			const initialState = { arl: params.data };
			this.modalService.show(ArlDetailsModalComponent, { initialState, class: 'modal-xl' });
		}
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.arlGridHelper.changePaginationSize(paginationSize);
		if (this.isAuthorized() && this.rowCount != 0){
			this.launchSearch();
		} else if(!this.isAuthorized()){
			this.launchSearch();
		}
		this.arlGridHelper.saveGridStatePrefix();
	}
	onColumnChanged(params: any): void {
		//this.arlGridHelper.gridColumnApi.setColumnsVisible(["numeroPs"], this.existGroupNumeroPS());
		this.arlGridHelper.saveGridColumnState();
	}
	onChoiceChange($event) {
		if ($event.filterChoice === "EXACTE") {
			this.filters.dateReceptionPartitionFrom = null;
			this.filters.dateReceptionPartitionTo = null;
		} else {
			this.filters.dateReceptionPartitionExacte = null;
		}
	}

	checkDateRangeValidity(event) {
		this.isDateRangeValid = event;
		if (this.dateEnvoie) {
			this.dateEnvoie.hideTooltlipDebut();
			this.dateEnvoie.hideTooltlipFin();
		}
	}

	exportFileXlsxModal() {
		if (this.rowCount > 0 && this.rowCount <= 1000) {
			this.exportToExcel();
		} else if (this.rowCount > 1000) {
			this.openExportModal();
		}
	}

	openExportModal() {
		this.openedModal = this.modalService.show(ExportModalComponent, {
			initialState: {
				nbrElement: this.rowCount
			},
			class: 'modal-lg'
		});

		this.openedModal.content.exportEvent.subscribe((e: any) => {
			if (e.action === ExportMode.EXPORT) {
				this.exportToExcel();
			}
			if (e.action === ExportMode.EMAIL) {
				this.exportByEmail();
			}
		});
	}

	exportExcelClient() {
		const ctx = this;
		let excel = new ExportUtilService();
		const sorts = gridConvertSort(ctx.arlGridHelper.gridSortModel(), []);
		let criteria;
		const columnKeys = this.arlGridHelper.gridColumnApi.getColumnState().map(column => {
			return column.colId;
		});
		let headers: Array < String > = [];
		let map = this.getColumnsAndFieldDataArl(columnKeys, this.arlsDataRaw);
		for (let key of map.keys()) {
			headers.push(key)
		}
		excel.setNameSheet('Arl');
		excel.setCellTitle('D1');
		excel.setTitle('Liste Des Arl');
		excel.setFontTitleSize(30, true);
		excel.setHeadersToExport(headers);
		excel.setFontHeaderSize(14, true);
		if (!ctx.isAuthorized()) {
			const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
			criteria = {
				idPs: userConnected.idPs,
				size: this.arlGridHelper.gridApi.paginationGetPageSize(),
				page : this.arlGridHelper.gridApi.paginationGetCurrentPage(),
				sorts
			};
			ctx.arlSvc.exportArlsByIdPsWithDelegues(criteria, ctx.arlsDataRaw).subscribe((dataSource) => {
						dataSource.forEach((data) => {
						let map = this.getColumnsAndFieldDataArl(columnKeys, data);
						let jsonObject = [];
						map.forEach((value, key) => {
							jsonObject[key] = value
						});
						excel.setRowData(jsonObject);
						excel.SetWidthBeetwenColumns(20);
					});
					excel.addBorderStyle();
					excel.ExportData('Liste-Des-Arl')
				})
		}

	}

	exportExcelClient2() {
		const ctx = this;
		let exportableColumns = this.arlGridHelper.gridColumnApi.getAllDisplayedColumns().map((c:any) => c.colId).filter(col => col != 'idFacture');
		if (!this.isAuthorized()) {
			// Le compte PS ne doit pas voir la colonne du numéro PS
			const nonExportableColumns = ['numeroAdeli'];
			exportableColumns = exportableColumns.filter((item) => nonExportableColumns.indexOf(item) === -1);
		}

		const params = {
			skipHeader: false,
			columnGroups: true,
			skipFooters: false,
			skipGroups: true,
			skipPinnedTop: false,
			skipPinnedBottom: false,
			allColumns: true,
			onlySelected: false,
			fileName: 'Liste Des Arl',
			sheetName: 'Arl',
			columnKeys: exportableColumns,
			processCellCallback: function (params) {
				if (
					params.column.colDef.field === 'montantFacture'
				) {
					params.value = ctx.formatCurrency({
						data: true,
						value: params.value
					});
				} else if (params.column.colDef.field === 'dateReception' || params.column.colDef.field === 'dateReceptionPartition') {
					params.value = ctx.formatDate({
						value: params.value
					});
				} else if(params.column.colDef.field === 'rapprochement') {
					params.value = ctx.formatRappro(params)
				} else if(params.column.colDef.field === 'signeArl') {
					params.value = ctx.formatSigne(params)
				}
				return params.value;
			}
		};
		this.arlGridHelper.paginationPageSize = this.gridDataRaw.size;
		this.arlGridHelper.gridApi.exportDataAsExcel(params);
	}

	formatSigne = (value): string => {
		return !isNullOrUndefined(value) && value ? 'Positif' : 'Négatif';
	};

	formatRappro = (value): string => {
		return value ? 'Oui' : 'Non';
	};


	formatDateForExport(params: any): string {
		return this.datePipe.transform(params, 'short');
	}

	formatCurrencyForExport(params): string {
		if (params) {
			return params / 100 + ' €';
		} else {
			return '0.00 €';
		}
	}

	getColumnsAndFieldDataArl(columnKeys: string[], dataFields ? : any) {
		let data = new Map();
		columnKeys.forEach(column => {
			switch (column) {
				case "numLot":
					data.set("N° du lot", dataFields.numLot);
					break;
				case "dateReceptionPartition":
					data.set("Date d'envoi", this.formatDateForExport(dataFields.dateReceptionPartition));
					break;
				case "dateReception":
					data.set("ARL reçu le", this.formatDateForExport(dataFields.dateReception));
					break;
				case "libelleOrganisme":
					data.set("Organisme dest.", dataFields.libelleOrganisme);
					break;
				case "codeOfficielOrganisme":
					data.set("Code officiel", dataFields.codeOfficielOrganisme);
					break;
				case "nbFactures":
					data.set("Nb factures", dataFields.nbFactures);
					break;
				case "montantFacture":
					data.set("Montant total du lot", this.formatCurrencyForExport(dataFields.montantFacture));
					break;
				case "rapprochement":
					data.set("Rapprochement", dataFields.rapprochement ? "Oui" : "Non");
					break;
				case "signeArl":
					data.set("Statut", dataFields.signeArl ? "Positif" :"Négatif");
					break;
			}
		})
		return data;
	}

	exportToExcel() {
		this.export = true;
		this.exportState = {
			message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
			state: State.INPROGRESS
		}
		const columns = this.arlGridHelper.gridColumnApi.getAllDisplayedColumns()
			.filter(column => column.getColDef().headerName != "")
			.map(column => column.getColDef().field);
		const sorts = gridConvertSort(this.arlGridHelper.gridSortModel(), []);
		this.arlSvc.export(
			{
				page: 0,
				size: this.rowCount,
				idOct: this.currentOct ? this.currentOct.idOct : '',
				sorts
			},
			this.arlsDataRaw,
			columns).subscribe((response) => {
				saveAs(response.file, response.fileName);
				this.exportState = {
					message : "Votre fichier a été téléchargé avec succès.",
					state: State.SUCCESS
				}
			},() => {
				this.exportState = {
					message : "Le téléchargement de votre fichier a rencontré un problème.",
					state: State.FAILED
				}
			});
    }

	exportByEmail() {
		const columns = this.arlGridHelper.gridColumnApi.getAllDisplayedColumns()
			.filter(column => column.getColDef().headerName != "")
			.map(column => column.getColDef().field);
		const sorts = gridConvertSort(this.arlGridHelper.gridSortModel(), []);
		this.arlSvc.exportByEmail(
                            {
                                page: 0,
								size: this.rowCount,
								idOct: this.currentOct ? this.currentOct.idOct : '',
								sorts
                            },
                            this.arlsDataRaw,
                            columns
                        ).subscribe(()=> {
                            // when email received
                        });
    }
	closeAlert() {
		this.export = false;
	}
}
