import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
	IServerSideDatasource,
	IServerSideGetRowsParams,
} from 'ag-grid-enterprise';
import { saveAs } from 'file-saver';
import { findIndex, isEmpty, uniqBy } from 'lodash-es';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../components/ag-grid/ag-grid.utils';
import { ColumnSelectorComponent } from '../../components/ag-grid/grid-column-selector.component';
import { ColumnSpec } from '../../components/ag-grid/grid-column-spec';
import { AuthService } from '../../core/services/auth.service';
import { OctService } from '../../core/services/oct.service';
import { TransformService } from "../../core/services/transform.service";
import { ConvertDateFormat } from '../../core/utilities/convert-date-format.service';
import { ScrollTopService } from '../../core/utilities/scroll-top.service';
import { FilterItem } from '../../data/filters/filter-item';
import { PsFilterRaw } from '../../data/filters/psFilterRaw';
import { GridDataRaw } from '../../data/gridDataRaw';
import { OctDataRaw } from "../../data/octDataRaw";
import { PsDataRaw } from '../../data/psDataRaw';
import { ExportState, State } from "../../shared/model/storage.model";
import { RouteHistoryService } from '../../shared/route-history.service';
import { StorageService } from '../../shared/storage-service';
import { ExportModalComponent, ExportMode } from "../export-modal/export-modal.component";
import { LotsFilterSvc } from '../flux/lots/lots-filter.service';
import { AccordionGroupComponent } from './../../components/accordion/accordion-group.component';
import { DateFromToFilterComponent } from './../../components/date-from-to/date-from-to-filter.component';
import { PsPreviewComponent } from './ps-subcomponents/ps-preview.components';
import { PsService } from './ps.service';
@Component({
	selector: 'careweb-app-ps',
	templateUrl: './ps.component.html',
	styleUrls: ['./ps.component.scss']
})
export class PSComponent implements OnInit, OnDestroy, AfterViewInit {
	//public modules: Module[] = AllCommunityModules;
	params: any;
	initHidden = true;
	showFilter = false;
	gridHelper: AgGridHelper;
	filters = new PsFilterRaw();
	psDataRaw: PsDataRaw;
	datePipe: DatePipe;
	@ViewChild(ColumnSelectorComponent, { static: true })
	columnSelector: ColumnSelectorComponent;
	currentOct;
	filterList: FilterItem[];
	subscription: Subscription;
	subscriptionPsNotHaveOCT: Subscription;
	gridDataRaw: GridDataRaw;
	rowCount: number;
	@Input() octSelected: OctDataRaw;
	psOnOct :boolean ;
	listOctBynumAdeli:[];
	numeroAdeli :String;
	hideAlert=false

	@ViewChild('dateDernierTransmission') dateDernierTransmission: DateFromToFilterComponent;
	isDateRangeValid = true;

	@ViewChild('dateAccordion') dateAccordion: AccordionGroupComponent;
	private openedModal: BsModalRef;
	serverName: string;
	export: boolean = false;
	exportState: ExportState;
	static MIN_WIDTH = 1173;
	constructor(
		private psSvc: PsService,
		private router: Router,
		private octSvc: OctService,
		private scrollTopService: ScrollTopService,
		private filterServiceSvc: LotsFilterSvc,
		private authSvc: AuthService,
		private convertFormatDateFr: ConvertDateFormat,
		private routeHistory: RouteHistoryService,
		private storageService: StorageService,
		private modalService: BsModalService,
		private transformService: TransformService
	) {
		//Get grid size from session if already stored
		const sessionPageSize = this.storageService.get('pssPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

		this.gridHelper = new AgGridHelper('pss', this.gridDataRaw.size, 10);
		this.psDataRaw = new PsDataRaw();
		this.convertFormatDateFr = new ConvertDateFormat();
		const body = document.body;
		body.className = 'app';
	}

	ngOnInit() {
		// Apply numero Ps on the filter if exist

		var parsedFilter = JSON.parse(sessionStorage.getItem('psFilter'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
			// Convert String dates to Dates format
			this.filters.dateStartDerniereTransmission
				? (this.filters.dateStartDerniereTransmission = new Date(parsedFilter.dateStartDerniereTransmission))
				: '';
			this.filters.dateStartDerniereTransmission
				? (this.filters.dateStartDerniereTransmission = new Date(parsedFilter.dateStartDerniereTransmission))
				: '';
			this.filters.dateExacteDerniereTransmission
				? (this.filters.dateExacteDerniereTransmission = new Date(parsedFilter.dateExacteDerniereTransmission))
				: '';
		} else {
			this.filters = new PsFilterRaw();
			this.filterList = [new FilterItem()];
		}
		this.scrollTopService.setScrollTop();
		// verify previous url if not details remove session currentPage
		if (this.routeHistory?.getPreviousUrl()?.indexOf('ps-details') === -1) {
			sessionStorage['pssCurrentPage'] = null;
		}
		this.psOnOct= false;
		this.listOctBynumAdeli=[];
		// Initiate date pipe format
		this.datePipe = new DatePipe('fr-FR');
		this.parseFilter();

		this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));

		// Get current oct
		this.subscription = this.octSvc.currentOctSubject.subscribe((selectedOct) => {
			if (selectedOct) {
				if (this.currentOct == null || (this.currentOct && this.currentOct.code !== selectedOct.code)) {
					this.currentOct = selectedOct;
					this.parseFilter();
					if (!isNullOrUndefined(this.gridHelper.gridApi)) {
						if (this.isThalesOct()) {
							this.gridHelper.gridApi.setColumnDefs([]);
						}
						this.gridHelper.gridApi.setColumnDefs(this.getColumnDefs());
						this.launchSearch();
					} else {
						this.initGrid();
					}
				}
			}
		});
		 
		sessionStorage.setItem('fiche-ps-tab','comptes');
		this.initGrid();
	}
	ngAfterViewInit(): void {
		let numeroAdeli =localStorage.getItem('numeroAdeliHotline');
		if(!isNullOrUndefined(numeroAdeli)){
			this.filters.numeroAdeli = numeroAdeli;
			localStorage.removeItem('numeroAdeliHotline');
			this.launchSearch();
		}
		this.subscriptionPsNotHaveOCT = this.psSvc.eventPsNotHaveOCT.subscribe((data) => {
           this.filters.numeroAdeli = data;
			this.launchSearch();
        });
	  }
	
	initGrid() {
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			enableCellTextSelection: true,
			onRowClicked: (params) => this.onRowClicked(params),
			onGridReady: (params) => this.onGridReady(params),
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onColumnMoved: (params) => this.onColumnChanged(params),
			onColumnVisible: (params) => this.onColumnChanged(params)
		};
	}

	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	onGridReady(params) {
		this.initFilter();
		const ctx = this;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		if (window.innerWidth > PSComponent.MIN_WIDTH) {
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
		this.initColumnsDisplay();
		let parsedFilter = JSON.parse(sessionStorage.getItem('psFilter'));
		const numeroPs = localStorage.getItem("numeroPs")
		if (!parsedFilter && numeroPs) {
			parsedFilter = new PsFilterRaw({ numeroAdeli: numeroPs })
		}
		if (!isEmpty(parsedFilter)) {
			this.launchSearch();
		} else if (ctx.currentOct?.idOct){

			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					if (!ctx.isAdminSel()) {
						ctx.psSvc
						?.getPSList({
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							idOct: ctx.currentOct ? ctx.currentOct.idOct : null,
							sorts
						})
						.subscribe(
							(data) => {
								ctx.rowCount = data.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
								const pageN = Number.parseInt(sessionStorage.getItem('pssCurrentPage')) || 0;
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['pssCurrentPage'] = null;
							},
							() => paramsRows.fail()
						);
					} else {
						ctx.launchSearch();
					}
				}
			};
			this.gridHelper.gridApi.setServerSideDatasource(dataSource);
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		let width = event.target.innerWidth;
		this.setColumnOrder(width);
	}
	setColumnOrder(width :any) {
		if(width<=1600){
			const columnApi = this.gridHelper.gridColumnApi;

			const currentOrder = columnApi.getColumnState();
			const psOptionsIndex = currentOrder.findIndex((col) => col.colId === 'psOptions');
			const psFluxIndex = currentOrder.findIndex((col) => col.colId === 'psFlux');

			if (psOptionsIndex !== -1 || psFluxIndex !== -1) {
				columnApi.moveColumn('psOptions', 0);
				columnApi.moveColumn('psFlux', 1);
			}
		}else {
			const originalColumnDefs = this.getColumnDefs();
			this.gridHelper.gridApi.sizeColumnsToFit();
			// Set the original column order
			this.gridHelper.gridApi.setColumnDefs(originalColumnDefs);
		}
	}
	ngOnDestroy() {
		sessionStorage['psFilter'] = JSON.stringify(this.filters);
		sessionStorage['pssCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
		this.gridHelper.saveGridStatePrefix();
		this.subscription.unsubscribe();
	}

	onRowClicked(params) {
		if (!this.gridHelper.isCellTextSelected()) {
			localStorage.setItem('numeroPs', params.data.numeroAdeli);
			localStorage.setItem('idPs', params.data.idPs);
			localStorage.setItem('current_ps', JSON.stringify(params.data));
			this.router.navigate(['/ps-details', params.data.idPs]);
		}
	}

	restoreGrid(): void {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
		this.initColumnsDisplay();
	}

	exportExcel() {
		const columns = this.gridHelper.gridColumnApi.getAllDisplayedColumns()
			.filter(column => column.getColDef().headerName != "")
			.map(column => column.getColDef().field);
		if (this.rowCount > 0 && this.rowCount <= 1000) {
			this.exportToExcel(columns);
		} else if (this.rowCount > 1000) {
			this.openedModal = this.modalService.show(ExportModalComponent,  { initialState: {
					nbrElement: this.rowCount
				}, class: 'modal-lg'});
			this.openedModal.content.exportEvent.subscribe((e: any) => {
				if (e.action === ExportMode.EXPORT) {
					// this.exportFileXlsx();
					this.exportToExcel(columns);
				}
				if (e.action === ExportMode.EMAIL) {
					// this.exportFileXlsx();
					this.exportByEmail(columns);
				}
			});
		}
	}
	exportToExcel(columns) {
		this.export = true;
		this.exportState = {
			message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
			state: State.INPROGRESS
		}
		let formattedFilters = JSON.parse(JSON.stringify(this.filters));
			formattedFilters.dateEndDerniereTransmission = this.convertFormatDateFr.getFormatDateFr(
				this.filters.dateEndDerniereTransmission
			);
			formattedFilters.dateStartDerniereTransmission = this.convertFormatDateFr.getFormatDateFr(
				this.filters.dateStartDerniereTransmission
			);
			formattedFilters.dateExacteDerniereTransmission = this.convertFormatDateFr.getFormatDateFr(
				this.filters.dateExacteDerniereTransmission
			);
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(),[]);
		this.psSvc.exportPss(
			{
				page: this.gridHelper.gridApi.paginationGetCurrentPage(),
				size: this.gridHelper.gridApi.paginationGetPageSize(),
				idOct: this.currentOct.idOct,
				sorts
			},
			formattedFilters,
			columns
		).subscribe((response) => {
			saveAs(response.file, response.fileName);
			this.exportState = {
				message : "Votre fichier a été téléchargé avec succès.",
				state: State.SUCCESS
			}
		},() => {
			this.exportState = {
				message : "Le téléchargement de votre fichier a rencontré un problème.",
				state: State.FAILED
			}
		})
	}

	exportByEmail(columns) {
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(),[]);
		this.psSvc.exportPssByEmail(
			{
				page: this.gridHelper.gridApi.paginationGetCurrentPage(),
				size: this.gridHelper.gridApi.paginationGetPageSize(),
				idOct: this.currentOct.idOct,
				sorts
			},
			this.filters,
			columns
		).subscribe((response) => {

		})
	}
	closeAlert() {
		this.export = false;
	}

	rowGroupCallback(params) {
		return params.node.key;
	}

	refresh(): boolean {
		return false;
	}

	resetAllFilter(): void {
		const ctx = this;
		if(!isNullOrUndefined(this.dateDernierTransmission)){
			ctx.dateDernierTransmission.isDateRangeValid = true;
		}
		this.isDateRangeValid = true;

		this.initFilter();
		this.filterServiceSvc.reset();
		this.filters = new PsFilterRaw();
		this.filterServiceSvc.reset();
		if (!this.isAdminSel) {
			this.launchSearch();
		} else {
			this.setFilterSelWhenIsAdminSel();
			this.launchSearch();
		}
	}

	initFilter() {
		this.resetFilter();
		this.updateFilterList(this.filters);
	}

	updateFilterList(filters) {
		const ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (!isNullOrUndefined(filters[key]) && filters[key] !== '') {
				ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id != null;
		});
	}

	translateFilterLabelValue(key, value) {
		let translatedKey = key;
		let formattedValue = value;
		let formattedKey = key;
		switch (key) {
			case 'isActif':
				translatedKey = 'Actif';
				formattedValue = value ? 'Oui' : 'Non';
				break;
			case 'numeroAdeli':
				translatedKey = 'Numéro de PS';
				break;
			case 'rpps':
				translatedKey = 'Numéro RPPS';
				break;
			case 'nom':
				translatedKey = 'Nom';
				break;
			case 'raisonSocialeEtablissement':
				translatedKey = 'Etablissement';
				break;
			case 'telephoneEtablissment':
				translatedKey = 'Téléphone';
				break;
			case 'codepostalEtablissment':
				translatedKey = 'Code Postal';
				break;
			case 'villeEtablissment':
				translatedKey = 'Ville';
				break;
			case 'faxEtablissment':
				translatedKey = 'Fax';
				break;
			case 'balEmettriceSiteEmission':
				translatedKey = 'BAL émettrice';
				break;
			case 'genSiteEmission':
				translatedKey = 'Numéro GEN';
				break;
			case 'numeroMaitreSiteEmission':
				translatedKey = 'Numéro Maître';
				break;
			case 'dateStartDerniereTransmission':
				translatedKey = 'Dernière télétransmission après le';
				formattedValue = this.formatDateForTags({ value: value });
				break;
			case 'dateEndDerniereTransmission':
				translatedKey = 'Dernière télétransmission avant le';
				formattedValue = this.formatDateForTags({ value: value });
				break;
			case 'dateExacteDerniereTransmission':
				translatedKey = 'Dernière télétransmission le';
				formattedValue = this.formatDateForTags({ value: value });
				break;
			case 'numeroEsclaveSiteEmission':
				translatedKey = 'Numéro esclave';
				break;
			case 'sel':
				translatedKey = 'Statut SEL';
				formattedValue = value ? 'Oui' : 'Non';
				if (value == 1) {
					formattedValue = 'Oui';
				} else if (value == 0) {
					formattedValue = 'Non';
				} else if (value == 2) {
					formattedValue = 'Indéfini';
				}
				break;
			case 'rappBancaire':
				translatedKey = 'Rapprochement bancaire';
				formattedValue = value ? 'Activé' : 'Désactivé';
				break;
			case 'email':
				translatedKey = 'Email utilisateur';
				break;
			default:
				break;
		}
		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	onKeyDown(event) {
		this.launchSearch();
	}

	resetFilter() {
		this.filterServiceSvc.reset();
	}

	isAdminAuthorized() {
		return this.authSvc.userAuthorized('ADMIN');
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('ADMIN') || this.authSvc.userAuthorized('HOTLINER');
	}

	isAdminSel() {
		return this.authSvc.userAuthorized('ADMIN_SEL');
	}

	formatDate = (params): string => {
		return this.datePipe.transform(params.value, 'short');
	};

	formatDateForTags = (params): string => {
		if (params.value !== null && params.value != 'Invalid Date') {
			return this.datePipe.transform(params.value, 'dd/MM/yyyy HH:mm:ss');
		}
	};

	launchSearch(): void {
		const ctx = this;
		if (this.isFormValid()) {
			this.filters.numeroAdeli = this.filters.numeroAdeli
				? this.filters.numeroAdeli.replace(/\s/g, '').replace(/\./gi, '')
				: null;
			this.filters.raisonSocialeEtablissement = this.filters.raisonSocialeEtablissement
				? this.filters.raisonSocialeEtablissement.trim()
				: null;
			this.filters.nom = this.filters.nom ? this.filters.nom.trim() : null;
			this.filters.villeEtablissment = this.filters.villeEtablissment ? this.filters.villeEtablissment.trim() : null;
			this.filters.balEmettriceSiteEmission = this.filters.balEmettriceSiteEmission
				? this.filters.balEmettriceSiteEmission.trim()
				: null;
			this.filters.email = this.filters.email ? this.filters.email.trim() : null;
			this.filters.isActif = isNullOrUndefined(this.filters.isActif) ? null : this.filters.isActif;

			this.filters.sel = isNullOrUndefined(this.filters.sel) ? null : this.filters.sel;
			//this.filters.rappBancaire = isNullOrUndefined(this.filters.rappBancaire) ? null : this.filters.rappBancaire;

			let formattedFilters = JSON.parse(JSON.stringify(this.filters));
			formattedFilters.dateEndDerniereTransmission = this.convertFormatDateFr.getFormatDateFr(
				this.filters.dateEndDerniereTransmission
			);
			formattedFilters.dateStartDerniereTransmission = this.convertFormatDateFr.getFormatDateFr(
				this.filters.dateStartDerniereTransmission
			);
			formattedFilters.dateExacteDerniereTransmission = this.convertFormatDateFr.getFormatDateFr(
				this.filters.dateExacteDerniereTransmission
			);
			this.updateFilterList(formattedFilters);
			Object.keys(formattedFilters).forEach((key) => {
				if (isNullOrUndefined(formattedFilters[key])) {
					delete formattedFilters[key];
				}
			});

			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					ctx.psSvc
						.searchByIdOct(
							{
								page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
								size: ctx.gridHelper.gridApi.paginationGetPageSize(),
								idOct: ctx.currentOct.idOct,
								sorts
							},
							formattedFilters
						)
						.subscribe(
							(data) => {
								ctx.rowCount = data.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
								const pageN = Number.parseInt(sessionStorage.getItem('pssCurrentPage'));
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.gridHelper?.gridApi?.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['pssCurrentPage'] = null;

								ctx.octSvc.getAllOctByNumAdeli(formattedFilters?.numeroAdeli,formattedFilters?.email).subscribe(data=>{
									ctx.listOctBynumAdeli=data;
									if(!isNullOrUndefined(ctx.listOctBynumAdeli) && ctx.listOctBynumAdeli?.length >1 ){
										ctx.numeroAdeli=ctx.listOctBynumAdeli.slice(0, 1)[0];
										if(ctx.listOctBynumAdeli.slice(1).length===1 
										&& ctx.listOctBynumAdeli.slice(1)[0][0]=== ctx.currentOct.idOct){
											ctx.hideAlert=true;											
										}
										else{
											ctx.hideAlert=false;
										}
										ctx.psOnOct=true;
									}else {
										ctx.psOnOct=false;
										ctx.listOctBynumAdeli=[];
									}
								});
							},
							() => paramsRows.fail()
						);
				}
			};
			this.gridHelper.gridApi?.setServerSideDatasource(dataSource);
			sessionStorage['psFilter'] = JSON.stringify(this.filters);
			// localStorage.setItem('numeroPs', this.filters.numeroAdeli?.toString());
		}
	}
	onOctChange(idOct, nom, code) {
		this.octSelected = new OctDataRaw().withIdOct(idOct).withNom(nom).withCode(code);
		this.octSvc.setOctValue(this.octSelected);
		sessionStorage['psFilter'] = JSON.stringify(this.filters);
		localStorage['octCurrent'] = JSON.stringify(this.octSelected);
	}
	toggleFilterDisplay() {
		let width =window.innerWidth;
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
			if(!this.showFilter && width>1700){
				setTimeout(() => {
					this.gridHelper.gridApi.sizeColumnsToFit();
				}, 200);
			}
		}
	}

	initColumnsDisplay() {
		if (this.gridHelper && this.gridHelper.gridColumnApi) {
			const allColumns = this.gridHelper.gridColumnApi.getColumns();
			const selectedColumns = this.gridHelper.gridColumnApi.getAllDisplayedColumns();
			this.columnSelector.setItems(this.getColumnSpecs(allColumns));
			this.columnSelector.setSelectedItems(this.getColumnSpecs(selectedColumns));
		}
	}

	isFormValid(): boolean {
		let dateInputValid = true;
		if (this.filters.dateStartDerniereTransmission && this.filters.dateEndDerniereTransmission) {
			if (
				this.filters.dateStartDerniereTransmission.getTime() >
				this.filters.dateEndDerniereTransmission.getTime() ||
				!this.isDateRangeValid
			) {
				dateInputValid = false;
			} 
		}
		return dateInputValid;
	}

	private getColumnSpecs(columns: any[]): ColumnSpec[] {
		var allowedColumns;
		if (this.isThalesOct()) {
			allowedColumns = [
				'rpps',
				'dateExacteDerniereTransmission',
				'telephoneEtablissment',
				'villeEtablissment',
				'faxEtablissment',
				'balEmettriceSiteEmission',
				'numeroMaitreSiteEmission',
				'numeroEsclaveSiteEmission',
				'genSiteEmission'
			];
		} else {
			allowedColumns = [
				'rpps',
				'dateExacteDerniereTransmission',
				'telephoneEtablissment',
				'villeEtablissment',
				'faxEtablissment',
				'balEmettriceSiteEmission'
			];
		}

		return columns
			.filter(
				(cd) => cd.colDef.headerName && cd.colDef.headerName.length > 0 && allowedColumns.indexOf(cd.colId) > -1
			)
			.map((cd) => {
				return {
					id: cd.colDef.field,
					name: cd.colDef.headerName,
					label: cd.colDef.headerName
				};
			});
	}

	selectColumnEvent(col: ColumnSpec) {
		this.gridHelper.gridColumnApi.setColumnVisible(col.id, true);
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	deselectColumnEvent(col: ColumnSpec) {
		this.gridHelper.gridColumnApi.setColumnVisible(col.id, false);
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	private getColumnDefs() {
		if (this.isThalesOct()) {
			return [
				{
					headerName: '',
					headerClass: 'ag-header-group-parent',
					children: [
						{
							headerName: 'N° de PS',
							field: 'numeroAdeli',
							colId: 'numeroAdeli',
							minWidth: 100,
							maxWidth: 150,
							lockVisible: true,
							cellStyle: this.gridHelper.centerContent(),
							onCellClicked: (params) => this.onCellClicked(params),
							sortable: true
						},
						{
							headerName: 'Dernière télétrans.',
							field: 'dateExacteDerniereTransmission',
							colId: 'dateExacteDerniereTransmission',
							valueFormatter: this.formatDateForTags,
							minWidth: 125,
							maxWidth: 170,
							lockVisible: true,
							cellStyle: this.gridHelper.centerContent(),
							onCellClicked: (params) => this.onCellClicked(params),
							sortable: true
						},
						{
							headerName: 'Numéro RPPS',
							field: 'rpps',
							colId: 'rpps',
							minWidth: 100,
							lockVisible: true,
							cellStyle: this.gridHelper.centerContent(),
							onCellClicked: (params) => this.onCellClicked(params),
							sortable: true
						},
						{
							headerName: 'Etablissement',
							field: 'raisonSocialeEtablissement',
							colId: 'raisonSocialeEtablissement',
							minWidth: 150,
							lockVisible: true,
							sortable: true
						},
						{
							headerName: 'Nom',
							field: 'nom',
							colId: 'nom',
							minWidth: 100,
							lockVisible: true,
							sortable: true
						},
						{
							headerName: 'Téléphone',
							field: 'telephoneEtablissment',
							colId: 'telephoneEtablissment',
							minWidth: 60,
							maxWidth: 100,
							lockVisible: true,
							cellStyle: this.gridHelper.centerContent(),
							onCellClicked: (params) => this.onCellClicked(params),
							sortable: true
						},
						{
							headerName: 'Code postal',
							field: 'codepostalEtablissment',
							colId: 'codepostalEtablissment',
							minWidth: 80,
							lockVisible: true,
							cellStyle: this.gridHelper.centerContent(),
							onCellClicked: (params) => this.onCellClicked(params),
							sortable: true
						},
						{
							headerName: 'Ville',
							field: 'villeEtablissment',
							colId: 'villeEtablissment',
							minWidth: 100,
							lockVisible: true,
							sortable: true
						},
						{
							headerName: 'Fax',
							field: 'faxEtablissment',
							colId: 'faxEtablissment',
							minWidth: 50,
							lockVisible: true,
							cellStyle: this.gridHelper.centerContent(),
							onCellClicked: (params) => this.onCellClicked(params),
							sortable: true
						},
						{
							headerName: 'BAL émettrice',
							field: 'balEmettriceSiteEmission',
							colId: 'balEmettriceSiteEmission',
							minWidth: 160,
							lockVisible: true,
							sortable: true
						}
					]
				},
				{
					headerName: "Site d'émission du PS",
					headerClass: 'ag-header-group-parent',
					children: [
						{
							headerName: 'Numéro GEN',
							field: 'genSiteEmission',
							colId: 'genSiteEmission',
							minWidth: 150,
							cellStyle: this.gridHelper.centerContent(),
							hide: !this.isThalesOct(),
							sortable: true
						},
						{
							headerName: 'Numéro Maître',
							field: 'numeroMaitreSiteEmission',
							colId: 'numeroMaitreSiteEmission',
							minWidth: 150,
							cellStyle: this.gridHelper.centerContent(),
							hide: !this.isThalesOct(),
							sortable: true
						},
						{
							headerName: 'Numéro esclave',
							field: 'numeroEsclaveSiteEmission',
							colId: 'numeroEsclaveSiteEmission',
							minWidth: 165,
							cellStyle: this.gridHelper.centerContent(),
							hide: !this.isThalesOct(),
							sortable: true
						}
					],
					hide: !this.isThalesOct()
				},
				{
					headerName: 'SEL',
					field: 'sel',
					colId: 'sel',
					minWidth: 50,
					maxWidth: 50,
					lockVisible: true,
					cellRenderer: PsPreviewComponent,
					cellStyle: this.gridHelper.centerContent()
				},
				{
					headerName: 'Rapp. bancaire',
					field: 'typeRappro',
					colId: 'typeRappro',
					minWidth: 80,
					maxWidth: 80,
					lockVisible: true,
					cellRenderer: this.formatRapprochement,
					cellStyle: this.gridHelper.centerContent(),
					sortable: true
				},
				{
					headerName: '',
					field: 'psFlux',
					colId: 'psFlux',
					minWidth: 50,
					maxWidth: 50,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),

					cellRenderer: PsPreviewComponent,
					cellRendererParams: {
						iconClass: 'fa fa-link',
						redirect: 'lot'
					},
					onCellClicked: (params) => this.onCellClickedOnFlux(params)
				},
				{
					headerName: '',
					field: 'psOptions',
					colId: 'psOptions',
					minWidth: 50,
					maxWidth: 50,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					cellRenderer: PsPreviewComponent,
					cellRendererParams: {
						iconClass: 'fa fa-eye',
						redirect: 'ps'
					},
					onCellClicked: (params) => this.onCellClicked(params)
				}
			];
		} else {
			return [
				{
					headerName: 'N° de PS',
					field: 'numeroAdeli',
					colId: 'numeroAdeli',
					minWidth: 100,
					maxWidth: 150,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					onCellClicked: (params) => this.onCellClicked(params),
					sortable: true
				},
				{
					headerName: 'Dernière télétrans.',
					field: 'dateExacteDerniereTransmission',
					colId: 'dateExacteDerniereTransmission',
					valueFormatter: this.formatDateForTags,
					minWidth: 125,
					maxWidth: 150,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					onCellClicked: (params) => this.onCellClicked(params),
					sortable: true
				},
				{
					headerName: 'Numéro RPPS',
					field: 'rpps',
					colId: 'rpps',
					minWidth: 100,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					onCellClicked: (params) => this.onCellClicked(params),
					sortable: true
				},
				{
					headerName: 'Etablissement',
					field: 'raisonSocialeEtablissement',
					colId: 'raisonSocialeEtablissement',
					minWidth: 100,
					lockVisible: true,
					sortable: true
				},
				{
					headerName: 'Nom',
					field: 'nom',
					colId: 'nom',
					minWidth: 100,
					lockVisible: true,
					sortable: true
				},
				{
					headerName: 'Téléphone',
					field: 'telephoneEtablissment',
					colId: 'telephoneEtablissment',
					minWidth: 80,
					maxWidth: 150,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					onCellClicked: (params) => this.onCellClicked(params),
					sortable: true
				},
				{
					headerName: 'Code postal',
					field: 'codepostalEtablissment',
					colId: 'codepostalEtablissment',
					minWidth: 80,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					onCellClicked: (params) => this.onCellClicked(params),
					sortable: true
				},
				{
					headerName: 'Ville',
					field: 'villeEtablissment',
					colId: 'villeEtablissment',
					minWidth: 100,
					lockVisible: true,
					sortable: true
				},
				{
					headerName: 'Fax',
					field: 'faxEtablissment',
					colId: 'faxEtablissment',
					minWidth: 50,
					lockVisible: true,
					cellStyle: this.gridHelper.centerContent(),
					onCellClicked: (params) => this.onCellClicked(params),
					sortable: true
				},
				{
					headerName: 'BAL émettrice',
					field: 'balEmettriceSiteEmission',
					colId: 'balEmettriceSiteEmission',
					minWidth: 220,
					lockVisible: true,
					sortable: true
				},
				{
					headerName: 'Rapp. bancaire',
					field: 'typeRappro',
					colId: 'typeRappro',
					minWidth: 120,
					maxWidth: 120,
					lockVisible: true,
					cellRenderer: this.formatRapprochement,
					cellStyle: this.gridHelper.centerContent(),
					sortable: true
				},
				{
					headerName: 'SEL',
					field: 'sel',
					colId: 'sel',
					headerClass: 'ag-header-merge',
					minWidth: 50,
					maxWidth: 50,
					lockVisible: true,
					cellRenderer: PsPreviewComponent,
					cellStyle: this.gridHelper.centerContent()
				},
				{
					headerName: '',
					field: 'psFlux',
					colId: 'psFlux',
					minWidth: 50,
					maxWidth: 50,
					lockVisible: true,
					cellRenderer: PsPreviewComponent,
					cellRendererParams: {
						iconClass: 'fa fa-link',
						redirect: 'lot'
					},
					cellStyle: this.gridHelper.centerContent(),
					onCellClicked: (params) => this.onCellClickedOnFlux(params)
				},
				{
					headerName: '',
					field: 'psOptions',
					colId: 'psOptions',
					minWidth: 50,
					maxWidth: 50,
					lockVisible: true,
					cellRenderer: PsPreviewComponent,
					cellRendererParams: {
						iconClass: 'fa fa-eye',
						redirect: 'ps'
					},
					cellStyle: this.gridHelper.centerContent(),
					onCellClicked: (params) => this.onCellClicked(params)
				}
			];
		}
	}

	onCellClicked(params) {
		if (!this.gridHelper.isCellTextSelected()) {
			localStorage.setItem('numeroPs', params.data.numeroAdeli);
			localStorage.setItem('idPs', params.data.idPs);
			localStorage.setItem('current_ps', JSON.stringify(params.data));
			this.router.navigate(['/ps-details', params.data.idPs]);
		}
	}

	onCellClickedOnFlux(params) {
		localStorage.setItem('numeroPs', params.data.numeroAdeli);
		localStorage.setItem('idPs', params.data.idPs);
		localStorage.setItem('current_ps', JSON.stringify(params.data));
		this.router.navigate(['/lots']);
	}

	getCurrentOct() {
		// Get current oct
		this.subscription = this.octSvc.currentOctSubject.subscribe((value) => {
			this.currentOct = value;
		});
	}

	isThalesOct() {
		return this.currentOct && this.currentOct.code === 'THA' ? true : false;
	}

	removeFilter(id) {
		const ctx = this;
		if (ctx.filterList) {
			delete ctx.filters[id];
			let currentFilterList = ctx.filterList;
			const index = findIndex(currentFilterList, (filt) => filt.id === id);
			if(index!=-1){
				currentFilterList.splice(index, 1);
			}
			ctx.filterList = currentFilterList;
			this.fillFilterObjectFromTagsOnly();
			ctx.launchSearch();
		}
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new PsFilterRaw();
		this.filterList.forEach(filter => {
			this.filters[filter.id] = filter.value2;
			if (!isEmpty(this.filters.dateEndDerniereTransmission)) {
				this.filters.dateEndDerniereTransmission = new Date(this.filters.dateEndDerniereTransmission);
			}
			if (!isEmpty(this.filters.dateStartDerniereTransmission)) {
				this.filters.dateStartDerniereTransmission = new Date(this.filters.dateStartDerniereTransmission);
			}
			if (!isEmpty(this.filters.dateExacteDerniereTransmission)) {
				this.filters.dateExacteDerniereTransmission = new Date(this.filters.dateExacteDerniereTransmission);
			}
		});
	}

	formatRapprochement = (params): string => {
		if (params.data) {
			return isNullOrUndefined(params.value) || params.value ==="UNKNOWN"
				? '<span><i class="fa fa-close red"></i></span>'
				: '<span><i class="fa fa-check green"></i></span>';
		}
	};

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.launchSearch();
		this.gridHelper.saveGridPaginationSize();
	}

	private parseFilter() {
		const parsedFilter = JSON.parse(sessionStorage.getItem('psFilter'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			if (!isEmpty(parsedFilter.dateEndDerniereTransmission)) {
				parsedFilter.dateEndDerniereTransmission = new Date(parsedFilter.dateEndDerniereTransmission);
			}
			if (!isEmpty(parsedFilter.dateStartDerniereTransmission)) {
				parsedFilter.dateStartDerniereTransmission = new Date(parsedFilter.dateStartDerniereTransmission);
			}
			if (!isEmpty(parsedFilter.dateExacteDerniereTransmission)) {
				parsedFilter.dateExacteDerniereTransmission = new Date(parsedFilter.dateExacteDerniereTransmission);
			}
			Object.assign(this.filters, parsedFilter);
			if (!this.isThalesOct()) {
				delete this.filters['genSiteEmission'];
				sessionStorage['psFilter'] = JSON.stringify(this.filters);
				this.removeFilter('genSiteEmission');
				this.removeFilter('numeroMaitreSiteEmission');
				this.removeFilter('numeroEsclaveSiteEmission');
			}
		} else {
			this.filterList = [new FilterItem()];
		}
		this.setFilterSelWhenIsAdminSel();
	}

	onColumnChanged(params: any): void {
		this.gridHelper.saveGridColumnState();
	}

	onChoiceChange($event) {
		if ($event.filterChoice === 'EXACTE') {
			this.filters.dateStartDerniereTransmission = null;
			this.filters.dateEndDerniereTransmission = null;
		} else {
			this.filters.dateExacteDerniereTransmission = null;
		}
	}

	checkDateRangeValidity(event) {
		this.isDateRangeValid = event;
		if (this.dateDernierTransmission) {
			this.dateDernierTransmission.hideTooltlipDebut();
			this.dateDernierTransmission.hideTooltlipFin();
		}
	}

	setFilterSelWhenIsAdminSel() {
		if (this.isAdminSel()) {
			this.filters.sel = 1;
		}
	}
	dismissAlert(): void {
		this.psOnOct = false;
	}

}
