import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { User } from '../../data/userDataRaw';
import { AuthService } from '../../core/services/auth.service';
import { UsersService } from '../users/users.service';
import { TypePsService } from '../ps/service/typeps.service';
import { PsDelegueService } from '../ps/service/ps-delegue.service';
import { PsService } from '../ps/ps.service';
import { PsDelegueDataRaw } from '../../data/PsDelegueDataRaw';
import { DatePipe } from '@angular/common';
import { OctService } from '../../core/services/oct.service';
import { isNullOrUndefined } from 'util';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'careweb-app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: [ './user-profile.component.scss' ]
})
export class UserProfileComponent implements OnInit {
	civiliteArray: any = [ { value: 1, viewValue: 'M' }, { value: 3, viewValue: 'Mme' } ];
	actifArray: any = [ { value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' } ];
	SuperviseurArray: any = [ { value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' } ];
	typeUserArray = [
		{ value: '1', viewValue: 'Administrateur' },
		{ value: '3', viewValue: 'Hotline' },
		{ value: '2', viewValue: 'Professionnel de santé' }
	];
	octObject = { value: '', viewValue: '', clicked: false };
	listOCT;
	userForm: UntypedFormGroup;
	currentUser: User;
	initialEmail;
	submitted = false;
	usersPs: Array<User>;
	mainUsers: Array<User>;
	listPsAssocie: Array<PsDelegueDataRaw>;
	listPsDelegue: Array<PsDelegueDataRaw>;
	datePipe: DatePipe;
	configRappro=false
	rapproComptePs
	rapproEbics
	rapproBtn=false
	rapproInitValue
	rapproInit;

	constructor(
		private authSvc: AuthService,
		private userService: UsersService,
		private typePsService: TypePsService,
		private psDelegueSVC: PsDelegueService,
		private psService: PsService,
		private toastr: ToastrService,
		private octService: OctService,
		private fb: UntypedFormBuilder
	) {
		// Body styling
		const body = document.body;
		body.className = 'app';
		this.currentUser = new User();
		this.usersPs = new Array<User>();
		this.mainUsers = new Array<User>();
		this.listPsAssocie = new Array<PsDelegueDataRaw>();
		this.listPsDelegue = new Array<PsDelegueDataRaw>();
		this.datePipe = new DatePipe('fr-FR');
	}

	ngOnInit() {
		//this.listOCT = [];
		this.initForm();
		// get currentUser connected
		this.currentUser = this.authSvc.currentUserValue;
		if (!isNullOrUndefined(this.currentUser)) {
			this.getUserById(this.currentUser.idUser);

			// if connected user is PS
			if (this.currentUser.idTypeUser === 2) {
				this.getPsByIdPs(this.currentUser.idPs);
			}
			// get oct list
			this.fillUserForm(this.currentUser);
			//this.getOctListByUser(this.currentUser.idUser);
		}
	}

	initForm() {
		this.userForm = this.fb.group({
			typeCivility: new UntypedFormControl('', [ Validators.required ]),
			nom: [ '', [ Validators.required ] ],
			prenom: new UntypedFormControl(''),
			email: [ '', [ Validators.required ] ],
			fonction: new UntypedFormControl(''),
			telephone: new UntypedFormControl(''),
			fax: new UntypedFormControl(''),
			mobile: new UntypedFormControl(''),
			selectActif: new UntypedFormControl(''),
			selectSuperviseur: new UntypedFormControl(''),
			selectTypeUser: new UntypedFormControl(this.getTypeUser(3)),
			selectTypePs: new UntypedFormControl(''),
			numeroAdeli: '',
			raisonSociale: '',
			adresse1: '',
			adresse2: '',
			codepostal: '',
			ville: '',
			telephonePs: '',
			faxPs: '',
			emailPs: '',
			derniereTransmission: '',
			hideRappro:true,
			rappVisible:true
		});
	}

	getTypeUser(value) {
		return this.typeUserArray.find((type) => type.value === value);
	}

	getUserById(idUser) {
		return this.userService.getUserById(idUser).subscribe((res) => {
			this.currentUser = res;
		});
	}

	getOctListByUser(idUser) {
		const ctx = this;
		return ctx.userService.getListOctByIdUser(idUser).subscribe((data) => {
			if (data) {
				this.listOCT = [];
				data.forEach((oct) => {
					this.octObject.value = oct.code;
					this.octObject.viewValue = oct.nom;
					this.listOCT.push(Object.assign({}, this.octObject));
				});
			}
		});
		
	}

	getPsByIdPs(idPs) {
		this.psService.getPsById(idPs).then((data) => {
			if (data && data.typeRappro && data.typeRappro!=='UNKNOWN') {
				this.configRappro = true
				this.rapproInitValue=data.hideRappro?true:false
				this.userForm.controls.hideRappro.setValue(this.rapproInitValue)
			}			
			let derniereTransmission = this.formatDate(data.octPsDtos[0].derniereTransmission);
			this.userForm.controls.numeroAdeli.setValue(data.numeroAdeli);
			this.psService.eventPsSelected.emit(data.numeroAdeli);
			this.userForm.controls.derniereTransmission.setValue(derniereTransmission);
			this.userForm.controls.raisonSociale.setValue(
				!isNullOrUndefined(this.userForm.controls.raisonSociale.value) &&
				this.userForm.controls.raisonSociale.value != ''
					? this.userForm.controls.raisonSociale.value
					: data.raisonSocialeEtablissement ? data.raisonSocialeEtablissement : data.numeroAdeli
			);
			this.userForm.controls.adresse1.setValue(
				!isNullOrUndefined(this.userForm.controls.adresse1.value) && this.userForm.controls.adresse1.value != ''
					? this.userForm.controls.adresse1.value
					: data.adresse1Etablissement
			);
			this.userForm.controls.adresse2.setValue(
				!isNullOrUndefined(this.userForm.controls.adresse2.value) && this.userForm.controls.adresse2.value != ''
					? this.userForm.controls.adresse2.value
					: data.adresse2Etablissement
			);
			this.userForm.controls.ville.setValue(
				!isNullOrUndefined(this.userForm.controls.ville.value) && this.userForm.controls.ville.value != ''
					? this.userForm.controls.ville.value
					: data.villeEtablissment
			);
			this.userForm.controls.codepostal.setValue(
				!isNullOrUndefined(this.userForm.controls.codepostal.value) &&
				this.userForm.controls.codepostal.value != ''
					? this.userForm.controls.codepostal.value
					: data.codepostalEtablissment
			);
			this.userForm.controls.emailPs.setValue(
				!isNullOrUndefined(this.userForm.controls.email.value) && this.userForm.controls.email.value != ''
					? this.userForm.controls.email.value
					: data.emailEtablissment
			);
			this.userForm.controls.telephonePs.setValue(
				!isNullOrUndefined(this.userForm.controls.telephone.value) &&
				this.userForm.controls.telephone.value != ''
					? this.userForm.controls.telephone.value
					: data.telephoneEtablissment
			);
			this.userForm.controls.faxPs.setValue(
				!isNullOrUndefined(this.userForm.controls.fax.value) && this.userForm.controls.fax.value != ''
					? this.userForm.controls.fax.value
					: data.faxEtablissment
			);
			this.getTypePs(data.idTypePs);
			this.usersPs = data.psUsers;
			this.userService.getMainUsersByIdPs(idPs, this.usersPs.map((user: User) => user.idUser)).subscribe((data: User[]) => {
				this.mainUsers = data;
			});
			this.psDelegueSVC.getPsDelegueByIdPs(idPs).subscribe((psDelegue) => {
				this.listPsDelegue = psDelegue;
			});
			this.psDelegueSVC.getPsAssocieByIdPs(idPs).subscribe((psAssocie) => {
				this.listPsAssocie = psAssocie;
			});
			if (data) {
				//console.log("data", data)
				this.listOCT = [];
				this.rapproInit = data.rappVisible;
				this.userForm.controls.rappVisible.setValue(this.rapproInit);
 				data.octPsDtos.forEach((oct) => {
					this.octService.getOctById(oct.idOct).subscribe((oct) => {
						this.octObject.value = oct.code;
						this.octObject.viewValue = oct.nom;
						this.listOCT.push(Object.assign({}, this.octObject));
					});
				}); 
			}
		});
	}

	getTypePs(idTypePs) {
		return this.typePsService.getTypePsById(idTypePs).subscribe((type) => {
			this.currentUser.typePs = type.libelleLong;
			this.userForm.controls.selectTypePs.setValue(this.currentUser.typePs);
		});
	}

	onSubmit() {
		this.submitted = true;
		if (this.userForm.invalid) {
			return;
		}
	}

	fillUserForm(data) {
		data.telephone = this.formatWithoutSeperater(data.telephone);
		data.fax = this.formatWithoutSeperater(data.fax);
		data.mobile = this.formatWithoutSeperater(data.mobile);
		this.initialEmail = data.email;
		this.userForm.patchValue({
			typeCivility: data.idCivilite === 3 || data.idCivilite === 2 ? 'Mme' : data.idCivilite === 1 ? 'M' : '',
			nom: data.nom,
			prenom: data.prenom,
			fonction: data.fonction,
			telephone: data.telephone,
			fax: data.fax,
			mobile: data.mobile,
			email: data.email
		});
	}

	isAdminOrHot() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	isAdminSel() {
		return this.authSvc.userAuthorized('ADMIN_SEL');
	}

	isPs() {
		return this.authSvc.userAuthorized('PS');
	}

	formatWithoutSeperater(value) {
		if (value) {
			return value.replace(/\s/g, '').replace(/\./gi, '');
		}
	}

	formatCivility = (value): string => {
		return value === 1 ? 'M' : 'Mme';
	};

	formatDate = (params): string => {
		if (!isNullOrUndefined(params) && params !== 'Invalid Date') {
			return this.datePipe.transform(params, 'short');
		}
	};

	get formFields() {
		return this.userForm.controls;
	}

	saveConfigRappro() {
		this.rapproBtn = false
		this.saveHideRappro(this.currentUser.idPs);
		//this.saveRappVisible();
	}

	saveHideRappro(idPs: number) {
		const currentValues = this.userForm.value;
		let hideRapproChanged= this.rapproInitValue !== currentValues.hideRappro;
		let rapproVisibleChanged=  this.rapproInit !== currentValues.rappVisible;
		if (hideRapproChanged || rapproVisibleChanged) {
			
			this.rapproInitValue = this.userForm.controls.hideRappro.value;
			 
			this.rapproInit = currentValues.rappVisible;

			this.psService.setHideRappro(this.currentUser.idPs, this.rapproInitValue, this.rapproInit).subscribe(
				data => {
					if(hideRapproChanged){
						this.toastr.success(this.userForm.controls.hideRappro.value ?
							"La visibilité du rapprochement pour l'assistance a été désactivée avec succès." :
							"La visibilité du rapprochement pour l'assistance a été activée avec succès.")
					}
					if(rapproVisibleChanged){
						this.toastr.success(currentValues.rappVisible ?
							"La visibilité du rapprochement pour le superviseur a été activée avec succès." :
							"La visibilité du rapprochement pour le superviseur a été désactivée avec succès.")
					}
					
				},
				err => {
					this.toastr.error(err?.message)
					throw err;
				}
			)
		}
	}
	saveRappVisible(idPs: number) {
		const currentValues = this.userForm.value;
		if (this.rapproInit !== currentValues.rappVisible) {
			this.rapproInit = currentValues.rappVisible;
			this.psService.updateRappVisible(this.currentUser.idPs, this.rapproInit).subscribe(
				data => {
					this.toastr.success(currentValues.rappVisible ?
						"La visibilité du rapprochement pour le superviseur a été activée avec succès." :
						"La visibilité du rapprochement pour le superviseur a été désactivée avec succès.")
				},
				err => {
					this.toastr.error(err?.message)
					throw err;
				}
			);
		}
	}

	checkRapproBtn() {
		const currentValues = this.userForm.value;
		this.rapproBtn = this.rapproInitValue !== currentValues.hideRappro ||
			this.rapproInit !== currentValues.rappVisible;
	}
}
