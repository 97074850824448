import { DictRappRule } from './../../data/dictRappRule';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONF } from '../../core/constants';
import { Observable } from 'rxjs';
import {CustomSortDataRow} from "../../data/customSortDatRaw";
import {DictRappkeyword} from "../../data/dictRappMotCle";

@Injectable({
    providedIn: 'root'
})
export class DictRuleRappService {
    private readonly baseUrl = `${CONF.envUrl + CONF.appContext + CONF.apiBaseUrl}/v1/rules`;
    private readonly baseUrl2 = `${CONF.envUrl + CONF.appContext + CONF.apiBaseUrl}/v1/mot-cle`;

    constructor(private http: HttpClient) {}

    saveRule(rule: DictRappRule): Observable<DictRappRule> {
        return this.http.post<DictRappRule>(this.baseUrl, rule);
    }

    isRuleExists(keyLabel: string): Observable<boolean> {
        return this.http.get<boolean>(this.baseUrl + '/' + keyLabel);
    }

    getListRulesPageable(criteria: any): Observable<any> {
		return this.http.get<any>(
			this.baseUrl +
				'/pageable-list?page=' +
				criteria.page +
				'&size=' +
				criteria.size +
				this.getSortParameters(criteria.sortsRules)
		);
	}
	getListRulesMotClePageable(criteria: any, rulesCpamDataRaw: DictRappkeyword): Observable<any> {
		return this.http.post<DictRappkeyword>(
			this.baseUrl2 + '/pageable-list?page=' + criteria.page + '&size=' + criteria.size +
				this.getSortParameters(criteria.sortsRules),
			rulesCpamDataRaw

		);
	}

	isRuleCpamExists(labelVirement: string,motCleLabelV1: string ): Observable<Number> {
		return this.http.get<Number>(this.baseUrl2 + '/' +labelVirement + '/'+ motCleLabelV1);
	}

	saveRuleCpam(rule: DictRappkeyword): Observable<DictRappkeyword> {
		return this.http.post<DictRappkeyword>(this.baseUrl2, rule);
	}

	updateRuleCpam(rule: DictRappkeyword ): Observable<DictRappkeyword> {
		return this.http.put<DictRappkeyword>(`${this.baseUrl2}/${rule.idDictRapp}`, rule);
	}

	enableRuleCpam(rule: DictRappkeyword): Observable<DictRappkeyword> {
		return this.http.put<DictRappkeyword>(`${this.baseUrl2}/enable`, rule);
	}

	countDisabledRule(): Observable<number> {
		return this.http.get<number>(this.baseUrl2 + '/count');
	}

	deleteRuleCpam(idRule: number ): Observable<DictRappkeyword> {
		return this.http.delete<DictRappkeyword> (`${this.baseUrl2}/${idRule}`);
	}

	getRulesFilteredList(criteria: any, rulesDataRaw: DictRappRule): Observable<any> {
		const sorts: CustomSortDataRow[] = criteria.sortsRules;
		return this.http.post<any>(
			this.baseUrl + '/searchFilter' + '?page=' + criteria.page + '&size=' + criteria.size,
			{
				filters: rulesDataRaw,
				sorts: sorts
			}
		);
	}

    getSortParameters(sorts) {
		if (sorts.length > 1) {
		 	let properties = [];
		 	let directions = [];
			sorts.forEach(sortObj => {
				properties.push(sortObj.property);
				directions.push(sortObj.direction);
			});
			return '&sort=' + properties.join(",") + '&direction=' + directions.join(",");
		} else if (sorts.length === 1) {
			const sort = sorts[0].property;
			return '&sort=' + sort + '&direction=' + sorts[0].direction;
		} else {
			return '&sort=default&direction=default';
		}
	}

	updateRule(rule: DictRappRule ): Observable<DictRappRule> {
		return this.http.put<DictRappRule> (`${this.baseUrl}/${rule.idRule}`, rule);
	}

	deleteRule(idRule: number ): Observable<DictRappRule> {
		return this.http.delete<DictRappRule> (`${this.baseUrl}/${idRule}`);
	}
}
