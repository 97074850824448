import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class RappAccessEbicsGuard implements CanActivate {
	constructor( private router: Router) {}

	canActivate() {
		//const canActive = this.authSvc.currentUserValue.userPreferences.rapproActif;
		let currentPsStorage = localStorage.getItem('current_ps');

		if (this.isEmpty(localStorage.getItem('supervised_ps'))) {
			if (!currentPsStorage) {
				this.router.navigate(['/dashboard']);
				return false;
			}
			const currentPs = JSON.parse(currentPsStorage);
			const canActivate = currentPs.typeRappro === 'EBICS' && currentPs.psEbics.some((e: any) => e.actif);
			if (!canActivate) {
				this.router.navigate(['/dashboard']);
				return false;
			}		  } else {
			const supervisedRappVisible = localStorage.getItem('supervised_rapp_visible');
			if (supervisedRappVisible === null || supervisedRappVisible === undefined || supervisedRappVisible=='false' || localStorage.getItem('supervised_ps') === '-1') {
			  this.router.navigate(['/dashboard']);
			  return false;
			}
			const rappVisible = JSON.parse(supervisedRappVisible);
			if (!rappVisible) {
			  this.router.navigate(['/dashboard']);
			  return false;
			}
			return true;
		  }
		
		
		
		
	}
	private isEmpty(value: string | null): boolean {
		return value === null || value === undefined || value.trim() === '';
	  }
}
