import { FilterMultipleComponent } from './../../components/filtre-multiple/filter-multiple.component';
import { RappDetailVrtCellRendererComponent } from './../../pages/rapprochement/rapp-detail-vrt-cell-enderer.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
//import { NgChartsModule } from 'ng2-charts';
// Grid imports
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ShowdownModule } from 'ngx-showdown';
import { AccordionGroupComponent } from '../../components/accordion/accordion-group.component';
import { AccordionHeaderButtonsComponent } from '../../components/accordion/accordion-header-buttons.component';
import { DynamicGridModule } from '../../components/ag-grid/dynamic-grid.module';
import { ColumnSelectorComponent } from '../../components/ag-grid/grid-column-selector.component';
import { ColumnTooltipComponent } from '../../components/column-tooltip/column-tooltip-component';
import { ComponentsModule } from '../../components/components.module';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';
import { DateFilterpersonalizeComponent } from '../../components/date-filter/date-filter-personalize.component';
import { DateFromToFilterComponent } from '../../components/date-from-to/date-from-to-filter.component';
import { FilterChoiceExactePlageComponent } from '../../components/filter-choice-exacte-plage/filter-choice-exacte-plage.component';
import { FilterLabelComponent } from '../../components/filter/filter-label.component';
import { PaginationSizeComponent } from '../../components/grid-pagination/pagination-size.component';
import { PaginationComponent } from '../../components/grid-pagination/pagination.component';
//Help
import { HelpComponent } from '../../components/help/help.component';
import { OpenHelpComponent } from '../../components/help/open-help.component';
import { IconRendererComponent } from '../../components/icon-renderer/icon-renderer.component';
import { PluralizeComponent } from '../../components/pluralize/pluralize.component';
import { CurrencyPipe } from '../../core/pipes/currency.pipe';
import { CustomCurrencyPipe } from '../../core/pipes/custom-currency.pipe';
import { CustomDatePipe } from '../../core/pipes/custom-date.pipe';
import { FormatThousandsPipe } from '../../core/pipes/format-thousands.pipe';
import { OrganismeTypePipe } from '../../core/pipes/organisme-type.pipe';
import { PadStartPipe } from '../../core/pipes/pad-start.pipe';
import { PhoneNumberFormatterPipe } from '../../core/pipes/phone-number-formatter.pipe';
import { PsPipe } from '../../core/pipes/ps.pipe';
import { YesNoPipe } from '../../core/pipes/yesno.pipe';
import { ContextHelpSvc } from '../../core/services/context-help.service';
import { DirectivesModule } from '../../directives/directives.module';
import { ContactSupportComponent } from '../../pages/contact/contact-support.component';
import { DashboardAdminComponent } from '../../pages/dashboard-admin/dashboard-admin.component';
import { DashboardHelpvideoModalComponent } from '../../pages/dashboard/dashboard-helpvideo-modal/dashboard-helpvideo-modal.component';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { AjoutEditeurComponent } from '../../pages/editeurs/ajout-editeur/ajout-editeur.component';
import { EditeurPreviewComponent } from '../../pages/editeurs/editeur-preview.component';
import { EditorsComponent } from '../../pages/editeurs/editeurs.component';
import { FicheEditeurComponent } from '../../pages/editeurs/fiche-editeur/fiche-editeur.component';
import { FicheEditeurRattacherComponent } from '../../pages/editeurs/fiche-editeur/rattacher-logiciel/fiche-editeur-rattacher.component';
import { ArlDetailsModalComponent } from '../../pages/flux/arl/arl-details-modal/arl-details-modal.component';
import { ArlLotPreviewComponent } from '../../pages/flux/arl/arl-lot-details/arl-lot-preview.component';
import { ARLComponent } from '../../pages/flux/arl/arl.component';
import { ArlsFilterSvc } from '../../pages/flux/arl/arls-filter.service';
import { ComptabiliteComponent } from '../../pages/flux/comptabilite/comptabilite.component';
import { FicheFSEComponent } from '../../pages/flux/fse/fiche-fse/fiche-fse.component';
import { FseFilterSvc } from '../../pages/flux/fse/fse-filter.service';
import { FseLotPreviewComponent } from '../../pages/flux/fse/fse-lot-details/fse-lot-preview.component';
import { FsePreviewComponent } from '../../pages/flux/fse/fse-subcomposant/fse-preview.component';
import { FSEComponent } from '../../pages/flux/fse/fse.component';
import { CheckLoiComponent } from '../../pages/flux/loi/check-loi.component';
import { LoiComponent } from '../../pages/flux/loi/loi.component';
import { FicheLotComponent } from '../../pages/flux/lots/fiche-lot/fiche-lot.component';
import { FseDetailsModalComponent } from '../../pages/flux/lots/fse-details/fse-details-modal.component';
import { LotsFilterSvc } from '../../pages/flux/lots/lots-filter.service';
import { LotPreviewComponent } from '../../pages/flux/lots/lots-subcomponents/lot-preview.component';
import { LotsComponent } from '../../pages/flux/lots/lots.component';
import { RspDetailsModalComponent } from '../../pages/flux/lots/rsp-details/rsp-details-modal.component';
import { RetourDetailsModalComponent } from '../../pages/flux/retours-editables/retour-editables-subcomponents/retour-details-modal.component';
import { RetourPreviewComponent } from '../../pages/flux/retours-editables/retour-editables-subcomponents/retour-preview.component';
import { RetoursEditablesComponent } from '../../pages/flux/retours-editables/retours-editables.component';
import { FicheRSPComponent } from '../../pages/flux/rsp/fiche-rsp/fiche-rsp.component';
import { RspPreviewComponent } from '../../pages/flux/rsp/rsp-preview.component';
import { RSPComponent } from '../../pages/flux/rsp/rsp.component';
import { HotlineDashboardComponent } from '../../pages/hotline/hotline-dashboard/hotline-dashboard.component';
import { HotlineEditComponent } from '../../pages/hotline/hotline-edit/hotline-edit.component';
import { HotlineParametersComponent } from '../../pages/hotline/hotline-parameters/hotline-parameters.component';
import { HotlinePreviewComponent } from '../../pages/hotline/hotline-subcomposant/hotline-preview.component';
import { HotlineComponent } from '../../pages/hotline/hotline.component';
import { InterventionComponent } from '../../pages/hotline/intervention/intervention.component';
import { NouveautesComponent } from '../../pages/nouveautes/nouveautes.component';
import { FicheOrganismeComponent } from '../../pages/organismes/organismes/fiche-organisme/fiche-organisme.component';
import { NewOrganismeComponent } from '../../pages/organismes/organismes/new-organisme/new-organisme.component';
import { OrganismePreviewComponent } from '../../pages/organismes/organismes/organismes-subcomponents/organisme-preview.component';
import { OrganismesComponent } from '../../pages/organismes/organismes/organismes.component';
import { RegroupementsComponent } from '../../pages/organismes/regroupements/regroupements.component';
import { FicheTypeZeroRattacherComponent } from '../../pages/organismes/Type0/fiche-type0/fiche-type0-rattacher.component';
import { FicheTypeZeroComponent } from '../../pages/organismes/Type0/fiche-type0/fiche-type0.component';
import { TypeZeroComponent } from '../../pages/organismes/Type0/type0.component';
import { FichePsComponent } from '../../pages/ps/fiche-ps/fiche-ps.component';
import { PsDelegueExistantComponent } from '../../pages/ps/fiche-ps/ps-attache/ps-delegue-existant/ps-delegue-existant.component';
import { PsDeleguePreviewComponent } from '../../pages/ps/fiche-ps/ps-attache/ps-delegue-preview.components';
import { PsDelegueComponent } from '../../pages/ps/fiche-ps/ps-attache/ps-delegue.component';
import { PsEditeurExistantComponent } from '../../pages/ps/fiche-ps/ps-editeur/ps-editeur-existant/ps-editeur-existant.component';
import { PsEditeurNouveauComponent } from '../../pages/ps/fiche-ps/ps-editeur/ps-editeur-nouveau/ps-editeur-nouveau.component';
import { PsEditeurPreviewComponent } from '../../pages/ps/fiche-ps/ps-editeur/ps-editeur-preview.components';
import { PsEditeurComponent } from '../../pages/ps/fiche-ps/ps-editeur/ps-editeur.component';
import { PsEtablissementExistantComponent } from '../../pages/ps/fiche-ps/ps-etablissement/ps-etablissement-existant/ps-etablissement-existant.component';
import { PsEtablissementNouveauComponent } from '../../pages/ps/fiche-ps/ps-etablissement/ps-etablissement-nouveau/ps-etablissement-nouveau.component';
import { PsEtablissementPreviewComponent } from '../../pages/ps/fiche-ps/ps-etablissement/ps-etablissement-preview.components';
import { PsEtablissementComponent } from '../../pages/ps/fiche-ps/ps-etablissement/ps-etablissement.component';
import { PsLogicielExistantComponent } from '../../pages/ps/fiche-ps/ps-logiciel/ps-logiciel-existant/ps-logiciel-existant.component';
import { PsLogicielNouveauComponent } from '../../pages/ps/fiche-ps/ps-logiciel/ps-logiciel-nouveau/ps-logiciel-nouveau.component';
import { PsLogicielPreviewComponent } from '../../pages/ps/fiche-ps/ps-logiciel/ps-logiciel-preview.components';
import { PsLogicielComponent } from '../../pages/ps/fiche-ps/ps-logiciel/ps-logiciel.component';
import { PsSiteEmissionModificationComponent } from '../../pages/ps/fiche-ps/ps-site-emission/ps-site-emission-modification/ps-site-emission-modification.component';
import { PsSiteEmissionNouveauComponent } from '../../pages/ps/fiche-ps/ps-site-emission/ps-site-emission-nouveau/ps-site-emission-nouveau.component';
import { PsSiteEmissionComponent } from '../../pages/ps/fiche-ps/ps-site-emission/ps-site-emission.component';
import { PsSiteReroutageComponent } from '../../pages/ps/fiche-ps/ps-site-emission/ps-site-reroutage/ps-site-reroutage.component';
import { PsUserEditComponent } from '../../pages/ps/fiche-ps/ps-user/ps-edit-user/ps-user-edit.component';
import { PsUserExistantComponent } from '../../pages/ps/fiche-ps/ps-user/ps-user-existant/ps-user-existant.component';
import { PsUserNouveauComponent } from '../../pages/ps/fiche-ps/ps-user/ps-user-nouveau/ps-user-nouveau.component';
import { PsUserPreviewComponent } from '../../pages/ps/fiche-ps/ps-user/ps-user-preview.components';
import { PsUserComponent } from '../../pages/ps/fiche-ps/ps-user/ps-user.component';
import { PsPreviewComponent } from '../../pages/ps/ps-subcomponents/ps-preview.components';
import { PSComponent } from '../../pages/ps/ps.component';
import { RappAutoCountComponent } from '../../pages/rapprochement/rapp-auto-count.component';
import { RappAutoExternalPreviewComponent } from '../../pages/rapprochement/rapp-auto-external-preview.component';
import { RappAutoPreviewcomponent } from '../../pages/rapprochement/rapp-auto-preview.component';
import { RappAutoTotalMontantRspComponent } from '../../pages/rapprochement/rapp-auto-total-montant-rsp';
import { RappAutoComponent } from '../../pages/rapprochement/rapp-auto/rapp-auto.component';
import { RappBankAccountComponent } from '../../pages/rapprochement/rapp-bank-account/rapp-bank-account.component';
import { RappDashboardComponent } from '../../pages/rapprochement/rapp-dashboard/rapp-dashboard.component';
import { RappErrorMessageComponent } from '../../pages/rapprochement/rapp-error-message/rapp-error-message.component';
import { RappManuelComponent } from '../../pages/rapprochement/rapp-manuel/rapp-manuel.component';
import { RappValideComponent } from '../../pages/rapprochement/rapp-valide/rapp-valide.component';
import { ConditionsUtilisationComponent } from '../../pages/reglementaire/conditions-utilisation/conditions-utilisation.component';
//Reglementaire
import { MentionsLegalesComponent } from '../../pages/reglementaire/mentions-legales/mentions-legales.component';
import { ConventionelPreviewComponent } from '../../pages/sel/organization-state/conventionel-preview.component';
import { FicheOrgStateComponent } from '../../pages/sel/organization-state/fiche-org-state/fiche-org-state.component';
import { OrgStatePreviewComponent } from '../../pages/sel/organization-state/org-state-preview.component';
import { OrganizationStateComponent } from '../../pages/sel/organization-state/organization-state.component';
import { serviceDirectoryComponent } from '../../pages/sel/service-directory/service-directory.component';
import { TracabilityDetailsModalComponent } from '../../pages/sel/tracability/fiche-tracability/tracability-details-modal.component';
import { TracabilityPreviewComponent } from '../../pages/sel/tracability/tracability-preview.component';
import { TracabilityComponent } from '../../pages/sel/tracability/tracability.component';
import { SuiviRappComponent } from '../../pages/suivi-rapp/suivi-rapp.component';
import { UnknownPageComponent } from '../../pages/unknown-page/unknown-page.component';
import { UpdatePasswordComponent } from '../../pages/update-password/update-password.component';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { FicheUtilisateurOCTComponent } from '../../pages/users/fiche-utilisateurOCT/fiche-userOCT.component';
import { UserPreviewComponent } from '../../pages/users/user-preview.component';
import { UsersComponent } from '../../pages/users/users.component';
import { DateSelecteurPeriodeComponent } from './../../components/date-selecteur-periode/date-selecteur-periode.component';
import { VirementEtudierModalComponent } from './../../pages/suivi-rapp/virement-annomalie-modal/virement-etudier-modal.component';
// Components
import { AdminLayoutRoutes } from './admin-layout.routing';
import { DictRuleRappComponent } from '../../pages/dict-rule-rapp/dict-rule-rapp.component';
import { LogsRapprochementComponent } from '../../pages/logs-rapprochement/logs-rapprochement.component';
import { EditRuleColumnComponent } from '../../pages/dict-rule-rapp/edit-rule-column/edit-rule-column.component';
import { DeleteRuleColumnComponent } from '../../pages/dict-rule-rapp/delete-rule-column/delete-rule-column.component';
import {DateTimePickerComponent} from '../../components/date-time-picker/date-time-picker.component';
import {ColumnCommentTooltipComponent} from "../../components/column-tooltip/column-comment-tooltip-component";
import {SuiviRappProcReplayComponent} from "../../pages/suivi-rapp/suivi-rapp-procedureReplay";
import { OwlDateTimeModule, OwlNativeDateTimeModule,OwlDateTimeIntl,OWL_DATE_TIME_FORMATS} from 'ng-pick-datetime';
import { Injectable } from '@angular/core';
import {RejetComponent} from "../../pages/flux/rsp/rejets-rsp/rejet.component";
import { ReportingComponent } from '../../pages/reporting/reporting.component';
import {MouvementsFinanciersComponent} from "../../pages/flux/mouvements-financiers/mouvements.financiers.component";
import {MvFinancierFilterSvc} from "../../pages/flux/mouvements-financiers/MvFinancierFilterSvc";
import {ColumnOrganismTooltipComponent} from "../../components/column-tooltip/column-organism-tooltip-component";
import {NumberSeparatorPipe} from "../../core/pipes/number-separator.pipe";
import { MigrationOctComponent } from '../../pages/migration-oct/migration-oct.component';
import { AddMigrationOctComponent } from '../../pages/migration-oct/add-migration-oct/add-migration-oct.component';
import { MigrationOctPreviewComponent } from '../../pages/migration-oct/migration-oct-preview.component';
import { LogsMigrationOctComponent } from '../../pages/migration-oct/logs-migration-oct/logs-migration-oct.component';
import { StartMigrationOctComponent } from '../../pages/migration-oct/start-migration-oct/start-migration-oct.component';
import { DetailsMigrationOctComponent } from '../../pages/migration-oct/details-migration-oct/details-migration-oct.component';
import { DetailEmailCellRendererComponent } from '../../pages/migration-oct/details-migration-oct/detail-email-cell-enderer.component';
import { AgRspGridComponent } from '../../pages/rapprochement/ag-demo/ag-RspGrid.component';
import { AgHeaderCheckboxComponent } from '../../components/header-checkbox/header-checkbox-ag.component';
import { DateAgoPipe } from '../../shared/utils/date-pipe.service';
import { CardEbicsAbonnementComponent } from '../../pages/abonnement-ebics/card-ebics-abonnement/card-ebics-abonnement.component';
import { DeleteMigrationOctComponent } from '../../pages/migration-oct/delete-migration-oct/delete-migration-oct.component';
import {AlertComponent} from "../../pages/alert/alert.component";
import {AbonnementEbicsComponent} from "../../pages/abonnement-ebics/abonnement-ebics.component";
import {
    EditAbonnementEbicsComponent
} from "../../pages/abonnement-ebics/edit-abonnement-ebics/edit-abonnement-ebics.component";
import { AbonnementPreviewComponent } from '../../pages/abonnement-ebics/abonnement-preview-component';
import { AddAccountEbicsComponent } from '../../pages/abonnement-ebics/add-account-ebics/add-account-ebics.component';
import { DeleteAccountEbicsModalComponent } from '../../pages/abonnement-ebics/delete-account-ebics-modal/delete-account-ebics-modal.component';
import { SuiviRappMissingTransactionsComponent } from '../../pages/suivi-rapp/missing-transactions/suivi-rapp-missing-transactions';
import { IbanCleanDirective } from '../../directives/iban-clean.directive';
import { DetailsCompteAssociesComponent } from '../../pages/abonnement-ebics/details-comptes-associes/details-comptes-associes.component';
import {SuiviEbicsComponents} from "../../pages/suivi-ebics/suivi-ebics.components";
import {SuiviEbicsPreviewComponent} from "../../pages/suivi-ebics/suivi-ebics-preview.component";
import {PaginationComponentRapp} from "../../components/grid-pagination-Rapp/pagination.component.rapp";
import {AgGridModule} from "ag-grid-angular";
import { EditPsEtablissementComponent } from '../../pages/ps/fiche-ps/ps-etablissement/edit-ps-etablissement/edit-ps-etablissement.component';
import { ColumnMouvementComponent } from '../../components/column-tooltip/column-mf-tooltip-component';
import { RegleParsingXmlComponent } from '../../pages/abonnement-ebics/regle-parsing-xml/regle-parsing-xml.component';
import { AddRegleParsingXmlComponent } from '../../pages/abonnement-ebics/regle-parsing-xml/add-regle-parsing-xml/add-regle-parsing-xml.component';
import { ReglesParsingPreviewComponent } from '../../pages/abonnement-ebics/regle-parsing-xml/regles-parsing-preview-component';
import {AdminContentManagement} from "../../pages/ contenus-plateforme/admin-content-management";
import { AddRuleColumnComponent } from '../../pages/dict-rule-rapp/add-rule-column/add-rule-column.component';
import { SuiviRappIndexComponent } from '../../pages/suivi-rapp/suivi-rapp-index.component';
import { RappDashboardOrganismComponent } from '../../pages/suivi-rapp/rapp-dashboard-organism/rapp-dashboard-organism.component';
import { RappReconciliationRatecomponent } from '../../pages/suivi-rapp/rapp-reconciliation-rate.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RappDashboardPsComponent } from '../../pages/suivi-rapp/rapp-dashboard-ps/rapp-dashboard-ps.component';
import { LinkCellRendererComponent } from '../../pages/flux/arl/arl-link-renderer';
import {RefreshPreviewComponent} from "../../pages/abonnement-ebics/refresh-preview-component";
import { MatPaginatorModule } from '@angular/material/paginator';

registerLocaleData(localeFr);
export const MY_NATIVE_FORMATS = {
	fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second :'numeric'},
};
@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
	/** A label for the cancel button */
	cancelBtnLabel= 'Annuler';
};

@NgModule({
    imports: [
        //NgChartsModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        ProgressbarModule.forRoot(),

        ShowdownModule,
        CommonModule,
        RouterModule.forChild(AdminLayoutRoutes),
        TypeaheadModule.forRoot(),
        FormsModule,
        HttpClientModule,
        NgbModule,
        DynamicGridModule,
        AgGridModule,
        ReactiveFormsModule,
        MatTableModule,
        MatButtonModule,
        NgbNavModule,
        MatIconModule,
        MatMenuModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        BsDatepickerModule,
        NgSelectModule,
        DirectivesModule,
        ComponentsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        AgGridModule,
        MatPaginatorModule
    ] ,
    declarations: [
        PsPreviewComponent,
        LotPreviewComponent,
        RspPreviewComponent,
        UserPreviewComponent,
        FsePreviewComponent,
        ArlLotPreviewComponent,
        RappDashboardComponent,
        RappAutoComponent,
        RappValideComponent,
        RappManuelComponent,
        AgRspGridComponent,
        RappBankAccountComponent,
        CardEbicsAbonnementComponent,
        FseLotPreviewComponent,
        RetourPreviewComponent,
        EditeurPreviewComponent,
        OrganismePreviewComponent,
        AbonnementPreviewComponent,
        SuiviEbicsPreviewComponent,
        RefreshPreviewComponent,
        HotlinePreviewComponent,
        FicheEditeurRattacherComponent,
        FicheTypeZeroRattacherComponent,
        DashboardComponent,
        DashboardAdminComponent,
        UserProfileComponent,
        FicheUtilisateurOCTComponent,
        PSComponent,
        FichePsComponent,
        PsUserComponent,
        LotsComponent,
        FicheLotComponent,
        RSPComponent,
        RejetComponent,
        MouvementsFinanciersComponent,
		MigrationOctComponent,
        AbonnementEbicsComponent,
        EditAbonnementEbicsComponent,
		AddMigrationOctComponent,
        DeleteMigrationOctComponent,
		MigrationOctPreviewComponent,
		LogsMigrationOctComponent,
		StartMigrationOctComponent,
		DetailsMigrationOctComponent,
        DetailsCompteAssociesComponent,
		DetailEmailCellRendererComponent,
        FicheRSPComponent,
        FSEComponent,
        FicheFSEComponent,
        ARLComponent,
        RetoursEditablesComponent,
        LoiComponent,
        CheckLoiComponent,
        ComptabiliteComponent,
        OrganismesComponent,
        FicheOrganismeComponent,
        NewOrganismeComponent,
        RegroupementsComponent,
        TypeZeroComponent,
        FicheTypeZeroComponent,
        HotlineComponent,
        HotlineParametersComponent,
        HotlineEditComponent,
        InterventionComponent,
        HelpComponent,
        OpenHelpComponent,
        EditorsComponent,
        FicheEditeurComponent,
        UsersComponent,
        AjoutEditeurComponent,
        PaginationComponent,
        PaginationComponentRapp,
        AccordionHeaderButtonsComponent,
        AccordionHeaderButtonsComponent,
        AccordionGroupComponent,
        PluralizeComponent,
        FilterLabelComponent,
        PaginationSizeComponent,
        DateFromToFilterComponent,
        DateFilterpersonalizeComponent,
        ColumnSelectorComponent,
        HotlineDashboardComponent,
        UpdatePasswordComponent,
        ContactSupportComponent,
        HelpComponent,
        OpenHelpComponent,
        NouveautesComponent,
        SuiviRappComponent,
        SuiviRappIndexComponent,
        RappDashboardOrganismComponent,
        RappDashboardPsComponent,
        RappReconciliationRatecomponent,
        DateSelecteurPeriodeComponent,
        FilterChoiceExactePlageComponent,
        FilterMultipleComponent,
        DictRuleRappComponent,
        LogsRapprochementComponent,
        EditRuleColumnComponent,
        AddRuleColumnComponent,
        DeleteRuleColumnComponent,
        AddAccountEbicsComponent,
        SuiviEbicsComponents,
        AdminContentManagement,
        // Modals
        FseDetailsModalComponent,
        RspDetailsModalComponent,
        ArlDetailsModalComponent,
        RetourDetailsModalComponent,
        ConfirmModalComponent,
        DashboardHelpvideoModalComponent,
        VirementEtudierModalComponent,
        DateTimePickerComponent,
        AlertComponent,
        // Pipes
        YesNoPipe,
        PsPipe,
        CurrencyPipe,
        CustomDatePipe,
        CustomCurrencyPipe,
        FormatThousandsPipe,
        PhoneNumberFormatterPipe,
        OrganismeTypePipe,
        PadStartPipe,
        NumberSeparatorPipe,
        DateAgoPipe,
        IconRendererComponent,
        PsUserComponent,
        PsUserEditComponent,
        EditPsEtablissementComponent,
        PsEtablissementExistantComponent,
        PsEtablissementNouveauComponent,
        PsEtablissementComponent,
        PsEtablissementPreviewComponent,
        PsUserExistantComponent,
        PsUserNouveauComponent,
        PsUserPreviewComponent,
        PsEditeurComponent,
        PsEditeurExistantComponent,
        PsEditeurNouveauComponent,
        PsEditeurPreviewComponent,
        PsLogicielComponent,
        PsLogicielExistantComponent,
        PsLogicielNouveauComponent,
        PsLogicielPreviewComponent,
        PsSiteEmissionNouveauComponent,
        PsDelegueComponent,
        PsDelegueExistantComponent,
        PsDeleguePreviewComponent,
        PsSiteEmissionComponent,
        PsSiteReroutageComponent,
        PsSiteEmissionModificationComponent,
        RappErrorMessageComponent,
        RappAutoPreviewcomponent,
        SuiviRappProcReplayComponent,
        SuiviRappMissingTransactionsComponent,
        RappAutoCountComponent,
        RappAutoTotalMontantRspComponent,
        OrganizationStateComponent,
        serviceDirectoryComponent,
        OrgStatePreviewComponent,
        FicheOrgStateComponent,
        ConventionelPreviewComponent,
        TracabilityComponent,
        TracabilityPreviewComponent,
        RappAutoExternalPreviewComponent,
        TracabilityDetailsModalComponent,
        MentionsLegalesComponent,
        ConditionsUtilisationComponent,
        UnknownPageComponent,
        ColumnTooltipComponent,
        ColumnCommentTooltipComponent,
        ColumnOrganismTooltipComponent,
        ColumnMouvementComponent,
        RappDetailVrtCellRendererComponent,
        AgHeaderCheckboxComponent,
        ReportingComponent,
        DeleteAccountEbicsModalComponent,
        IbanCleanDirective,
        RegleParsingXmlComponent,
        AddRegleParsingXmlComponent,
        ReglesParsingPreviewComponent,
        LinkCellRendererComponent
    ],
    exports: [TooltipModule, NumberSeparatorPipe],
    providers: [BsModalRef, { provide: LOCALE_ID, useValue: 'fr-FR' }, LotsFilterSvc, FseFilterSvc, ArlsFilterSvc, MvFinancierFilterSvc, ContextHelpSvc, FormatThousandsPipe, PhoneNumberFormatterPipe, OrganismeTypePipe, CustomDatePipe,
        { provide: OWL_DATE_TIME_FORMATS, useClass: DefaultIntl, useValue: MY_NATIVE_FORMATS }, { provide: OwlDateTimeIntl, useClass: DefaultIntl }]
})
export class AdminLayoutModule {}
