import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { ColDef, GridOptions, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community';
import { AuthService } from '../../../core/services/auth.service';
import { ReglesParsingFilterRaw } from '../../../data/filters/ReglesParsingFilterRaw';
import { FilterItem } from '../../../data/filters/filter-item';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { ReglesParsingService } from '../../ps/service/regles-parsing.service';
import { isNullOrUndefined } from 'util';
import { findIndex, isEmpty, uniqBy } from 'lodash';
import { StorageService } from '../../../shared/storage-service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddRegleParsingXmlComponent } from './add-regle-parsing-xml/add-regle-parsing-xml.component';
import { ReglesParsingPreviewComponent } from './regles-parsing-preview-component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-regle-parsing-xml',
  templateUrl: './regle-parsing-xml.component.html',
  styleUrls: ['./regle-parsing-xml.component.scss']
})
export class RegleParsingXmlComponent implements OnInit {
  gridHelper: AgGridHelper;
  gridDataRaw: GridDataRaw;
  filters: ReglesParsingFilterRaw;
  filterList: FilterItem[];
  rowCount: number;
  showFilter = false;
  initHidden = true;
  showDeleteRegleModal: Boolean = false;
	regleToDelete: number;
  @Input() showFilterAbonnement: boolean;
  @Input() initHiddenAbonnement: boolean;
  @Output() toggleFilterDisplayEvent = new EventEmitter();

  constructor(
    private authSvc: AuthService,
    private reglesParsingService: ReglesParsingService,
		private storageService: StorageService,
    private modalService: BsModalService,
    private toastr: ToastrService,


  ) {
    const sessionPageSize = this.storageService.get('reglesParsingPaginationPageSize', true);
    this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
		this.gridHelper = new AgGridHelper('reglesParsing', this.gridDataRaw.size);
  }

  ngOnInit() {
    // Init filters
    const parsedFilter = JSON.parse(sessionStorage.getItem('reglesParsingFilter'));
    // Get filter stored in session if exist
    if (!isEmpty(parsedFilter)) {
      this.filters = Object.assign({}, parsedFilter);
      if (isNullOrUndefined(this.filters.statement) || isEmpty(this.filters.statement)) {
        this.filters.statement = parsedFilter.statement;
      }
    } else {
      this.filterList = [];
      this.filters = new ReglesParsingFilterRaw();

    }
    // Init ag-grid
    this.gridHelper.gridOptions = <GridOptions>(<unknown>{
      columnDefs: this.getColumnDefs(),
      defaultColDef: {
        resizable: true,
        suppressMenu: true
      },
      domLayout: 'autoHeight',
      rowHeight: 50,
      headerHeight: 50,
      rowModelType: 'serverSide',
      suppressServerSideInfiniteScroll: false,
      pagination: true,
      cacheBlockSize: 10,
      maxBlocksInCache: 0,
      infiniteInitialRowCount: 1,
      paginationPageSize: this.gridDataRaw.size,
      paginateChildRows: true,
      suppressScrollOnNewData: true,
      suppressPaginationPanel: true,
      suppressContextMenu: true,
      localeText: this.gridHelper.getLocaleText(),
      enableCellTextSelection: true,
      ensureDomOrder: true,
      //onRowClicked: (params) => this.onRowClicked(params),
      onGridReady: (params) => this.onGridReady(params),
      onGridSizeChanged: (params) => this.onGridSizeChanged(params),
      onSortChanged: (params) => this.onSortChanged(params),
      onColumnMoved: (params) => this.onColumnMoved(params)
    });

  }
  ngAfterViewInit() {
    this.reglesParsingService.modalClosed$.subscribe(() => {
      this.launchSearch();
    });
  }
  isAuthorized() {
    return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let width = event.target.innerWidth;
    this.setColumnOrder(width);
  }

  private getColumnDefs(): ColDef[] {
    return [{
      headerName: 'Statement',
      field: 'statement',
      minWidth: 300,
      lockVisible: true,
      cellStyle: this.gridHelper.centerContent(),
      sortable: true,
      hide: !this.isAuthorized()
    },
    {
      headerName: 'Iban',
      field: 'iban',
      minWidth: 100,
      maxWidth: 100,
      lockVisible: true,
      cellStyle: this.gridHelper.centerContent(),
      sortable: true
    },
    {
      headerName: 'Ntry',
      field: 'ntry',
      minWidth: 150,
      lockVisible: true,
      cellStyle: this.gridHelper.centerContent(),
      sortable: true,
      hide: !this.isAuthorized()
    },
    {
      headerName: 'Date Import',
      field: 'dateImport',
      minWidth: 100,
      lockVisible: true,
      cellStyle: this.gridHelper.centerContent(),
      sortable: true,
      hide: !this.isAuthorized()
    },
    {
      headerName: 'Label',
      field: 'label',
      minWidth: 100,
      lockVisible: true,
      cellStyle: this.gridHelper.centerContent(),
      sortable: true,
      hide: !this.isAuthorized()
    },
    {
      headerName: 'Transaction ID',
      field: 'transactionId',
      minWidth: 150,
      lockVisible: true,
      cellStyle: this.gridHelper.centerContent(),
      sortable: true,
      hide: !this.isAuthorized()
    },
    {
      headerName: 'Emetteur',
      field: 'emetteur',
      minWidth: 100,
      lockVisible: true,
      cellStyle: this.gridHelper.centerContent(),
      sortable: true,
      hide: !this.isAuthorized()
    },
    {
      headerName: 'Motif',
      field: 'motif',
      minWidth: 100,
      lockVisible: true,
      cellStyle: this.gridHelper.centerContent(),
      sortable: true,
      hide: !this.isAuthorized()
    },
    {
      headerName: 'Date Comptable',
      field: 'dateComptable',
      minWidth: 100,
      lockVisible: true,
      cellStyle: this.gridHelper.centerContent(),
      sortable: true,
      hide: !this.isAuthorized()
    },
    {
      headerName: 'Montant',
      field: 'montant',
      minWidth: 100,
      lockVisible: true,
      cellStyle: this.gridHelper.centerContent(),
      sortable: true,
      hide: !this.isAuthorized()
    },
    {
      headerName: 'Détail',
      field: 'detail',
      minWidth: 100,
      lockVisible: true,
      cellStyle: this.gridHelper.centerContent(),
      sortable: true,
      hide: !this.isAuthorized()
    },
    {
      headerName: 'Abonnements',
      field: 'bankName',
      minWidth: 100,
      lockVisible: true,
      cellRenderer: ReglesParsingPreviewComponent,
      cellRendererParams: {
        iconClass: '',
        action: 'showNumber'
      },
      sortable: false,
      hide: !this.isAuthorized()
    },
    {
			headerName: '',
			field: 'remove',
			minWidth: 70,
			maxWidth: 70,
			cellRenderer: ReglesParsingPreviewComponent,
			cellRendererParams: {
				iconClass: 'fa fa-trash',
				action: 'delete'
			},
			lockVisible: true,
			cellStyle: this.gridHelper.centerContent(),
			onCellClicked: (params) => this.showDeleteModal(params)
		},
    {
			headerName: '',
			field: 'edit',
			minWidth: 70,
			maxWidth: 70,
			cellStyle: this.gridHelper.centerContent(),
			cellRenderer: ReglesParsingPreviewComponent,
			cellRendererParams: {
				iconClass: 'fa fa-edit',
				action: 'edit'
			}
		}
    ];
  }
  showDeleteModal(params: any) {
    this.showDeleteRegleModal = true;
		this.regleToDelete = params.data.id;

	}
  cancelDelete() {
		this.showDeleteRegleModal = false;
	}
  deleteRegle() {
		
				this.reglesParsingService.deleteRegle(this.regleToDelete).subscribe((data) => {
					this.toastr.success('La règle a été supprimé avec succès.');
					this.showDeleteRegleModal = false;
					this.launchSearch();
				});
      }
  initFilter() {
    this.resetFilter();
    this.updateFilterList(this.filters);
  }
  resetFilter() {
    this.reglesParsingService.reset();
  }

  onGridSizeChanged(params) {
    this.gridHelper.gridApi.sizeColumnsToFit();
  }
  onColumnMoved(params: any): void {
    this.gridHelper.saveGridColumnState();
  }
  onSortChanged(params: any): void {
    this.gridHelper.saveGridSort();
  }
  onGridReady(params) {
    this.initFilter();
    var width = window.innerWidth;
    this.gridHelper.gridApi = params.api;
    this.gridHelper.gridColumnApi = params.columnApi;
    this.restoreGrid();
    this.setColumnOrder(width);
    this.launchSearch();
  }

  launchSearch() {
    const ctx = this;
    this.filters.statement = this.filters.statement ? this.filters.statement.trim() : null;
    this.filters.iban = this.filters.iban ? this.filters.iban.trim() : null;
    this.filters.ntry = this.filters.ntry ? this.filters.ntry.trim() : null;
    this.filters.dateImport = this.filters.dateImport ? this.filters.dateImport.trim() : null;
    this.filters.label = this.filters.label ? this.filters.label.trim() : null;
    this.filters.transactionId = this.filters.transactionId ? this.filters.transactionId.trim() : null;
    this.filters.emetteur = this.filters.emetteur ? this.filters.emetteur.trim() : null;
    this.filters.motif = this.filters.motif ? this.filters.motif.trim() : null;
    this.filters.dateComptable = this.filters.dateComptable ? this.filters.dateComptable.trim() : null;
    this.filters.montant = this.filters.montant ? this.filters.montant.trim() : null;
    this.filters.detail = this.filters.detail ? this.filters.detail.trim() : null;
    let formattedFilters = JSON.parse(JSON.stringify(this.filters));

    this.updateFilterList(this.filters);


    const dataSource: IServerSideDatasource = {
      getRows: function (paramsRows: IServerSideGetRowsParams) {
        const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
        ctx.reglesParsingService
          .getAllReglesParsing({
            page: ctx.gridHelper.gridApi.paginationGetCurrentPage(),
            size: ctx.gridHelper.gridApi.paginationGetPageSize(),
            sorts
          },
            formattedFilters
          )
          .subscribe(
            (data) => {
              ctx.rowCount = data.totalElements;
              ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
              paramsRows.success({
                "rowData": data.content,
                "rowCount": ctx.rowCount
              });
              const pageN = Number.parseInt(sessionStorage.getItem('reglesParsingCurrentPage'));
              if (
                !isNullOrUndefined(pageN) &&
                pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
                pageN > 0
              ) {
                ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
              }
              sessionStorage['reglesParsingCurrentPage'] = null;
            },
            () => paramsRows.fail()
          );

      }
    };
    this.gridHelper.gridApi.setServerSideDatasource(dataSource);
    sessionStorage['reglesParsingFilter'] = JSON.stringify(this.filters);
  }
  resetAllFilter(): void {
		this.filters = new ReglesParsingFilterRaw();
		this.launchSearch();
		this.reglesParsingService.reset();
	}
  onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.launchSearch();
		this.gridHelper.saveGridStatePrefix();
	}
  ngOnDestroy(): void {
    sessionStorage['reglesParsingFilter'] = JSON.stringify(this.filters);
  }
  resetGridState() {
    this.gridHelper.resetGridState();
    this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
  }
  updateFilterList(filters) {
    const ctx = this;
    ctx.filterList = [];
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== null && filters[key] !== '') {
        ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
      }
    });
    // Remove duplicated ids
    ctx.filterList = uniqBy(ctx.filterList, 'id');
    // filter null
    ctx.filterList = ctx.filterList.filter((item) => {
      return item.id != null;
    });
  }
  translateFilterLabelValue(key, value) {
    let translatedKey = key;
    let formattedValue = value;
    let formattedKey = key;
    switch (key) {
      case 'statement':
        translatedKey = 'Statement';
        break;
      case 'iban':
        translatedKey = "IBAN";
        break;
      case 'ntry':
        translatedKey = 'Ntry';
        break;
      case 'dateImport':
        translatedKey = "Date Import";
        break;
      case 'label':
        translatedKey = 'Label';
        break;
      case 'transactionId':
        translatedKey = 'Transaction ID';
        break;
      case 'emetteur':
        translatedKey = "Emetteur";
        break;
      case 'motif':
        translatedKey = 'Motif';
        break;
      case 'dateComptable':
        translatedKey = "Date Comptable";
        break;
      case 'montant':
        translatedKey = 'Montant';
        break;
      case 'detail':
        translatedKey = 'Détail';
        break;
      default:
        break;
    }
    return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
  }
  restoreGrid() {
    this.gridHelper.restoreGridStatePrefix();
    this.gridDataRaw.size = this.gridHelper.paginationPageSize;
  }
  goToAjouterRegle(){
    let initialState = {
      isEditing:false,
    };
    this.modalService.show(AddRegleParsingXmlComponent, {
      initialState,
      class: 'modal-xl'
    });
  }
  setColumnOrder(width: any) {
    const columnDefs = this.getColumnDefs();
    if (width <= 1600) {
      const columnApi = this.gridHelper.gridColumnApi;
      const removeIndex = columnDefs.findIndex(column => column.field === 'remove');
      const editIndex = columnDefs.findIndex(column => column.field === 'edit');
      if (removeIndex !== -1 || editIndex !== -1) {
        columnApi.moveColumn('edit', 0);
        columnApi.moveColumn('remove', 1);
      }
    } else {
      this.gridHelper.gridApi.sizeColumnsToFit();
      this.gridHelper.gridApi.setColumnDefs(columnDefs);
    }
  }
  removeFilter(id) {
    const ctx = this;
    delete ctx.filters[id];
    let currentFilterList = ctx.filterList;
    const index = findIndex(currentFilterList, (filt) => filt.id === id);
    currentFilterList.splice(index, 1);
    ctx.filterList = currentFilterList;
    ctx.launchSearch();


  }
  onKeyDown($event) {
    this.launchSearch();
    sessionStorage['reglesParsingFilter'] = JSON.stringify(this.filters);

  }
  toggleFilterDisplay() {
    this.toggleFilterDisplayEvent.emit();
  }
  refreshGrid() {
    this.gridHelper.gridApi.sizeColumnsToFit();
    this.gridHelper.gridApi.refreshCells({ force: true });
  }
}
