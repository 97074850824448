import { BehaviorSubject, Subscription } from 'rxjs';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../core/services/auth.service';
import { OctService } from '../../core/services/oct.service';
import { OctDataRaw } from '../../data/octDataRaw';
import { User } from '../../data/userDataRaw';
import { LogoutModalComponent } from '../../pages/logout/logout-modal.component';
import { UsersService } from '../../pages/users/users.service';
import { PsService } from '../../pages/ps/ps.service';
import { ROUTES } from '../../core/constants';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash-es';
import { StorageService } from '../../shared/storage-service';
import * as jwt_decode from 'jwt-decode';
import { HeaderDropdownComponent } from "./header-dropdown.component";
import { TypeConnexionRapprochement } from '../../data/TypeConnexionRapprochement';
import { HotlineFilterRaw } from "../../data/filters/hotlineFilterRaw";
import { PsDelegueService } from '../../pages/ps/service/ps-delegue.service';
import { PsDelegueDataRaw } from '../../data/PsDelegueDataRaw';
import { isEmpty } from 'lodash-es';


@Component({
    selector: 'careweb-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit, OnDestroy {
    octList: OctDataRaw[];
    octCodsList: String[] = [];
    octCodsListSubject: BehaviorSubject<String> = new BehaviorSubject<String>(null);
    public isCollapsed = true;
    public menuItems: any[];
    octDataRaw: OctDataRaw;
    currentUser: User;
    @Input() octSelected: OctDataRaw;
    showHotlineMenu = false;
    showRappBancMenu = false;
    numeroPs: string = null;
    idPs: String = null;
    subscription: Subscription;
    searchPsInput = false;
    @ViewChild(HeaderDropdownComponent) headerDropdownComponent!: HeaderDropdownComponent;
    rightClicked: boolean = true;
    rapproRoutes = ['/rapp-dashboard', '/rapp-auto', '/rapp-manuel', '/rapp-valide', '/rapp-bank-account'];
    typeConnexionBancaire: TypeConnexionRapprochement;
    hotlineFilters = new HotlineFilterRaw();
    selectedSupervised: any = -1;
    supervisedPsInSelect: any = null;
    isSupervisor: boolean = false;
    rappVisible: boolean = false;
    public supervisedPsList: PsDelegueDataRaw[];
    public supervisedPsListwithRappActiveVisible: PsDelegueDataRaw[];
    selectedPs:any;
    showDetailModal: Boolean = false;
    isPsExistOnOct: Boolean = true;
    showModalRappInactive: boolean = false;
    psWithRappActiveAndVisible: String[] = [];
    lastPsConsulted: String[] = [];
    private subscrpt: Subscription = new Subscription();
    rappConditionLoading: boolean = true;
    urlRapp;
    constructor(
        private router: Router,
        private authSvc: AuthService,
        public octSvc: OctService,
        public userSvc: UsersService,
        private modalService: BsModalService,
        private psSvc: PsService,
        private storageService: StorageService,
        private psDelegueSVC: PsDelegueService
    ) {
        this.octDataRaw = new OctDataRaw();
    }

    ngOnInit() {
        this.currentUser = this.authSvc.currentUserValue;
        const decodedToken = jwt_decode(localStorage.getItem('careweb_token'));
        if (this.currentUser) {
            this.userSvc.getUserById(this.currentUser.idUser).subscribe((res) => {
                this.currentUser.username = res.username;
                if (decodedToken.userType == 2 && !isNullOrUndefined(this.currentUser.idPs)) {
                    if (
                        !isNullOrUndefined(res.etablissementUser) &&
                        !isNullOrUndefined(res.etablissementUser.raisonSociale)
                    ) {
                        this.currentUser.etablissementRaisonSocial = res.etablissementUser.raisonSociale.toString()
                    } else {
                        // Si c'est null on récupére a partir du PS
                        this.psSvc.getPsById(this.currentUser.idPs).then((data) => {
                            if (data.raisonSocialeEtablissement) {
                                this.currentUser.etablissementRaisonSocial = data.raisonSocialeEtablissement.toString();
                            } else {
                                this.currentUser.etablissementRaisonSocial = data.numeroAdeli.toString();
                            }
                        });
                    }
                } else if (decodedToken.userType === 1) {
                    this.currentUser.etablissementRaisonSocial = 'Admin';
                } else if (decodedToken.userType === 3) {
                    this.currentUser.etablissementRaisonSocial = 'Hotline';
                } else if (decodedToken.userType === 4) {
                    this.currentUser.etablissementRaisonSocial = 'Admin SEL';
                }
            });

        }

        this.menuItems = ROUTES.filter((menuItem) => menuItem);
        this.router.events.subscribe((event) => {
            this.isCollapsed = true;
        });

        if (this.currentUser.idTypeUser == 3 || this.currentUser.idTypeUser == 1) {
            this.getHotLineSession(this.router.url);
            this.getSessionValue();
        }

        if (this.currentUser.idTypeUser == 2) {
            this.getRappBancSession();
            this.getPsOctsCode(this.currentUser.idPs);
        }

        this.octSelected = JSON.parse(localStorage.getItem('octCurrent'));
        if (this.octSelected) {
            this.octSvc.setOctValue(this.octSelected);
        }

        if (this.isAuthorized() || this.isAdminSel()) {
            this.getOctListByUser();
        }

        this.subscription = this.psSvc.eventPsSelected.subscribe((data) => {
            let parsedFilter =sessionStorage.getItem('isPsExistOnOct');
            if(parsedFilter == 'false' ){
                this.numeroPs=null;
                this.storageService.remove('numeroPs', false);
                this.storageService.remove('idPs', false);
                sessionStorage.removeItem('isPsExistOnOct')
            }else{
                this.numeroPs = data;
            }
            this.idPs = localStorage.getItem('idPs');
        });
        this.initPsData();
        this.subscrpt = this.psSvc.getLastPsConsulted().subscribe(ps => {
            this.lastPsConsulted = ps.length !== 0 ? ps : JSON.parse(localStorage.getItem("lastPsConsulted"));
        })
    }

    getOctListByUser() {
        this.userSvc.getListOctByIdUser(this.currentUser.idUser).subscribe((data) => {
            if (data) {
                this.userSvc.setCurrentlistOctByUser(data);
                this.octList = this.userSvc.getCurrentlistOctByUser;
                if (isNullOrUndefined(this.octSelected)) {
                    this.octSelected = this.octList.find((oct) => oct.code === 'QTP') || this.octList[0];
                }
                this.setSelectedOct();
            }
        });
    }

    getPsOctsCode(idPs: number) {
        this.psSvc.getOctsByIdPs(idPs).subscribe(data => {
            if (data) {
                data.forEach((codeOct) => {
                    this.octCodsListSubject.next(codeOct);
                    this.octCodsListSubject.subscribe(octCod => {
                        this.octCodsList.push(octCod);
                        if (this.octCodsList.length == data.length) {
                            localStorage.setItem('psOctsCods', JSON.stringify(this.octCodsList));
                        }
                    })
                });
            }
        });
    }

    onOctChange(idOct, nom, code) {
        this.octSelected = new OctDataRaw().withIdOct(idOct).withNom(nom).withCode(code);
        const localStorageOctCurrented = JSON.parse(localStorage.getItem('octCurrent'));
        //var psOctsCods = JSON.parse(localStorage.getItem('psOctsCods'));
        const currentPs = JSON.parse(localStorage.getItem('current_ps'));
        var psOctsCods: any[] = [];


        if (localStorageOctCurrented && currentPs) {
            if (localStorageOctCurrented.idOct !== idOct) {
                this.setSelectedOct();
                currentPs.octPsDtos.map(e => psOctsCods.push(e.idOct));
                this.resetSelectedPs(code, psOctsCods, idOct);
            }
        } else {
            this.setSelectedOct();
        }

        if (this.currentUser.idTypeUser === 3 || this.currentUser.idTypeUser === 1) {
            this.getSessionValue();
        }

        window.location.reload();
    }

    resetSelectedPs(codeOct, psOctsCods: String[], idOct) {
        let parsedFilter = JSON.parse(sessionStorage.getItem('psFilter'));
        if (psOctsCods != null && !psOctsCods.includes(idOct)) {
            this.storageService.remove('numeroPs', false);
            this.storageService.remove('idPs', false);
            this.storageService.remove('psOctsCods', false);
            localStorage.removeItem('current_ps');
            localStorage.removeItem('careweb_ebics_user');
            this.psSvc.eventPsSelected.emit(this.storageService.get('numeroPs', false));
            if (this.router.url.includes('ps-details') || this.rapproRoutes.includes(this.router.url)) {
                parsedFilter.numeroAdeli='';
                sessionStorage['psFilter'] = JSON.stringify(parsedFilter);
                this.isPsExistOnOct = false;
                sessionStorage.setItem('isPsExistOnOct', false.toString());
                this.router.navigate(['/ps']).then(() => {
                    window.location.reload();
                });
            } else if (!this.router.url.includes('ps-details') && !psOctsCods.includes(idOct)) {
                window.location.reload();
            }
        } else {
            window.location.reload();
        }
    }

    setSelectedOct() {
        this.octSvc.setOctValue(this.octSelected);
        localStorage['octCurrent'] = JSON.stringify(this.octSelected);
    }

    logout() {
        const initialState = {};
        this.modalService.show(LogoutModalComponent, { initialState, class: 'modal-md' });
    }

    isAuthorized() {
        return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
    }

    isAdminAuthorized() {
        return this.authSvc.userAuthorized('ADMIN');
    }

    isAdminSel() {
        return this.authSvc.userAuthorized('ADMIN_SEL');
    }

    hasSelAccess() {
        return this.isAdminSel() || this.isAdminAuthorized();
    }

    redirectToDashboard($event) {
        if (
            $event.target.className === 'baseline' &&
            !(this.router.url.includes('hotline') || this.router.url.includes('intervention'))
        ) {
            this.router.navigate(['/ps']);
        }
    }

    hasOctAccess(element?: string) {
        let selectedOctCode: string;
        if (!isNullOrUndefined(this.octSvc.getCurrentOctValue)) {
            selectedOctCode = this.octSvc.getCurrentOctValue.code;
        }
        // get current url and verify If OCT has access to the current page if not redirect to dashboard
        if (!isNullOrUndefined(element) && element === 'editeur') {
            const allowedEditOct: any[string] = ['QTP', 'CDS', 'SAN'];
            return allowedEditOct.indexOf(selectedOctCode) !== -1;
        } else {
            let isAllowedOct;
            const allowedOct: any[string] = ['QTP', 'ALL', 'CDS', 'SAN'];

            if (this.currentUser.idTypeUser == 2) {

                isAllowedOct = _.intersection(allowedOct, this.octCodsList).length > 0;

            } else {
                isAllowedOct = allowedOct.indexOf(selectedOctCode) != -1;

            }

            return isAllowedOct;
        }
    }


    public openTab(url: string) {
        const win = window.open(url, '_blank');
        win.focus();
    }

    getHotLineSession(path: any) {
        if (path.includes('hotline')) {
            this.hotlineFilters.numeroPs = localStorage.getItem('numeroPs');
            if (!isNullOrUndefined(this.hotlineFilters.numeroPs)) {
                const hotlineFilters = JSON.parse(sessionStorage.getItem('hotlineFilters') || '{}');
                hotlineFilters.numeroPs = this.hotlineFilters.numeroPs;
                sessionStorage.setItem('hotlineFilters', JSON.stringify(hotlineFilters))
            }
        }
        this.showHotlineMenu = path.includes('hotline') || path.includes('intervention');
    }

    getRappBancSession() {

        const currentPsStorage = localStorage.getItem('current_ps');
        if (currentPsStorage) {
            const currentPs = JSON.parse(currentPsStorage);
            this.showRappBancMenu = currentPs != null && ((currentPs.rappComptePs != null && currentPs.rappComptePs.actif && currentPs.typeRappro == 'OXLIN'))
        }
        else {
            this.showRappBancMenu = false;
        }
    }

    getSessionValue() {
         this.numeroPs = localStorage.getItem('numeroPs') || null;
         this.idPs = localStorage.getItem('idPs') || null;
    }
    
    removeSessionValue(): Boolean {
        this.psSvc.removeLocalStorageOfNumeroPS();
        localStorage.removeItem('numeroPs');
        localStorage.removeItem('idPs');
        localStorage.removeItem('current_ps');
        localStorage.removeItem('careweb_ebics_user');
        this.getSessionValue();
        return true;
    }

    showPsDetail() {
        this.idPs = localStorage.getItem('idPs');
        this.router.navigate(['/ps-details', this.idPs]);
    }

    desactivatePsFilter() {
        this.removeSessionValue();

        if (this.rapproRoutes.includes(this.router.url)) {
            this.router.navigate(['/dashboard']);
            return;
        }

        if (this.router.url.includes('ps-details')) {
            this.router.navigate(['/ps']);
        } else {
            window.location.reload();
        }
    }

    refresh(path: String) {
        this.router.navigate([path]);
    }

    trackByMethod(index: number, el: any): number {
        return el.id;
    }

    isEbicsActif(ebicsPs) {
        return ebicsPs.filter((e) => e.actif)?.length > 0;
    }
    isPsSelected() {
        let currentPsStorage = localStorage.getItem('current_ps');
        if (currentPsStorage) {
            const currentPs = JSON.parse(currentPsStorage);
            return currentPs && ((currentPs.typeRappro == 'OXLIN' && currentPs.rappComptePs && currentPs.rappComptePs.actif && !currentPs.hideRappro) || (currentPs.typeRappro == 'EBICS' && currentPs.psEbics && this.isEbicsActif(currentPs.psEbics) && !currentPs.hideRappro)) && (this.currentUser.idTypeUser == 3 || this.currentUser.idTypeUser == 1)
        }
        return false;
    }
    isRappVisible() {
        let currentPsStorage = localStorage.getItem('current_ps');
        if (currentPsStorage) {
            const currentPs = JSON.parse(currentPsStorage);
            return currentPs.rappVisible;
        }
        return false;
    }
    hasRapproVisible() {
        let supervisedPs = localStorage.getItem('supervised_ps');
        if (supervisedPs) {
            const currentPs = JSON.parse(supervisedPs);
            return currentPs && ((currentPs.typeRappro == 'OXLIN' && currentPs.rappComptePs && currentPs.rappComptePs.actif && !currentPs.hideRappro) || (currentPs.typeRappro == 'EBICS' && currentPs.psEbics && this.isEbicsActif(currentPs.psEbics) && !currentPs.hideRappro)) && (this.currentUser.idTypeUser == 3 || this.currentUser.idTypeUser == 1)
        }
        return false;
    }


    isEbicsUser() {
        // if (localStorage.getItem('careweb_ebics_user')) {
        //     var psEbics = JSON.parse(localStorage.getItem('careweb_ebics_user'));
        //     return psEbics && psEbics?.actif;
        // }
        // return false;
        const supervisedPsLocalStorage = localStorage.getItem('supervised_ps');
        if (!isEmpty(supervisedPsLocalStorage) && supervisedPsLocalStorage != '-1') {
            const supervisedTypeRapp = localStorage.getItem('supervised_type_rapp');
            if (!isEmpty(supervisedTypeRapp)){
                return supervisedTypeRapp==='1';
            }
            else{
                return false;
            }
            

        }
        else{
            let currentPsStorage = localStorage.getItem('current_ps');
            let careweb_user = localStorage.getItem('careweb_user');
            if (currentPsStorage) {
                const currentPs = JSON.parse(currentPsStorage);
                return currentPs && ((currentPs.typeRappro == 'EBICS' && currentPs.psEbics && this.isEbicsActif(currentPs.psEbics) && this.checkConditionEbics(currentPs)))
            }
            else if (careweb_user) {
                const currentUser = JSON.parse(careweb_user);
                return currentUser && currentUser.typeRappro == 'ebics';
            }
            return false;
        }
        
    }
    isSupervisorOxlinUser(){
        const supervisedPsLocalStorage = localStorage.getItem('supervised_ps');
        if (!isEmpty(supervisedPsLocalStorage) && supervisedPsLocalStorage != '-1') {
            const supervisedTypeRapp = localStorage.getItem('supervised_type_rapp');
            if (!isEmpty(supervisedTypeRapp)){
                return supervisedTypeRapp==='0';
            }
            else{
                return false;
            }
            

        }
    }
    isSupervisonAndPsNotSelected(){
        const supervisedPsLocalStorage = localStorage.getItem('supervised_ps');
        if (!isEmpty(supervisedPsLocalStorage) && supervisedPsLocalStorage === '-1') {
            return true;
        }
    }
    checkConditionEbics(currentPs) {
        if (currentPs.hideRappro && (this.currentUser.idTypeUser == 3 || this.currentUser.idTypeUser == 1)) {
            return false;
        }
        return true;
    }

    isOxlinUser() {
        let currentPsStorage = localStorage.getItem('current_ps');
        if (currentPsStorage) {
            const currentPs = JSON.parse(currentPsStorage);
            return currentPs && (currentPs.typeRappro == 'OXLIN' && currentPs.rappComptePs && currentPs.rappComptePs.actif) && (this.currentUser.idTypeUser == 3 || this.currentUser.idTypeUser == 1)
        }
        return false;
    }

    showSearchPsInput(e) {
        e.stopPropagation()
        this.searchPsInput = true
    }

    stopPropagation(e) {
        e.stopPropagation()
    }

    onKeyDown(e) {
        if (e.target.value) {
            this.psSvc.getPsByNumAdeli({ numAdeli: e.target.value }).subscribe(
                ps => {
                    if(this.checkPsHaveOct(ps[0].octPsDtos)){
                        localStorage.setItem("numeroPs", ps[0]?.numeroAdeli)
                        localStorage.setItem("idPs", ps[0]?.idPs)
                        localStorage.setItem('current_ps', JSON.stringify(ps[0]));
                        this.psSvc.eventPsSelected.emit(ps[0]?.numeroAdeli);
                        //this.router.navigate(['/ps-details', ps[0].idPs]);
                        this.psSvc.eventPsNotHaveOCT.emit(ps[0]?.numeroAdeli);
                        this.getListPsConsulted(ps[0]?.numeroAdeli);

                        if(this.router.url.includes('/ps-details')){
                            this.router.navigate(['/ps-details', ps[0].idPs], { replaceUrl: true }).then(() => {
                                window.location.reload();
                            });
                        }else {
                            window.location.reload();
                        }
                    }
                    else{
                        this.storageService.remove('numeroPs', false);
                        this.storageService.remove('idPs', false);
                        localStorage.removeItem('current_ps');
                        localStorage.removeItem('careweb_ebics_user');
                        this.psSvc.eventPsNotHaveOCT.emit(ps[0]?.numeroAdeli);
                        localStorage.setItem('shouldRefresh',true.toString());
                        localStorage.setItem('numeroAdeliHotline',ps[0]?.numeroAdeli.toString());
                        if(!this.router.url.includes('/hotline')){
                            this.router.navigate(['/ps']);
                        }else {
                            localStorage.setItem("numeroPs", ps[0]?.numeroAdeli)
                            localStorage.setItem("idPs", ps[0]?.idPs)
                            window.location.reload();
                        }
                    }
                    
                }
            )
        }
        else {
            this.searchPsInput = false
        }
    }
    getListPsConsulted(ps) {
        this.psSvc.loadLastPss();
        this.psSvc.addPs(ps)
        this.psSvc.saveLastPss();
    }
    checkPsHaveOct(octs: any[]): boolean {
        var currentOct = JSON.parse(localStorage.getItem('octCurrent'));
        return octs.map(e => e.idOct).includes(currentOct.idOct);
    }
    // goToFichePs(){
    //     const currentPs = JSON.parse(localStorage.getItem('current_ps'));
    //     this.router.navigate(['/ps-details', ps[0].idPs]);
    // }
    goToAddIntervention() {
        const host: string = location.origin;
        const url: string = host + '/careweb-client' + String(this.router.createUrlTree(['add_intervention']));
        window.open(url, '_blank');
    }
    onRightClick(): void {
        this.rightClicked = false;
    }
    onBlurDetect(): void {
        this.rightClicked = true;
        if (this.headerDropdownComponent.isOpen) {
            this.headerDropdownComponent.onClick();
        }
    }
    private initPsData() {
        // if connected user is PS
        if (!isNullOrUndefined(this.currentUser) && this.currentUser.idTypeUser == 2) {
            const idPs = this.currentUser.idPs;
            // Verify if connected user is a supervisor ps
            this.psDelegueSVC.getPsDelegueByIdPs(idPs).subscribe((data: Array<PsDelegueDataRaw>) => {
                if (data.length > 0) {
                    this.isSupervisor = true;
                    this.initListDelegues(data);

                }
                else{
                    this.rappConditionLoading=false;
                }
            });

        }
    }
    private initListDelegues(psDelegues: PsDelegueDataRaw[]): void {
        const psSuperviseur = new PsDelegueDataRaw();
        // Ajouter Ps de l'utilisateur
        psSuperviseur.idPsDelegue = psDelegues[0].idPsAssocie;
        psSuperviseur.numeroPsDelegue = psDelegues[0].numeroPsAssocie;
        psSuperviseur.nomPsDelegue = psDelegues[0].nomPsAssocie;

        this.supervisedPsList = psDelegues;
        this.supervisedPsList = [...psDelegues, psSuperviseur];

        const supervisedPsLocalStorage = localStorage.getItem('supervised_ps');
        if (!isEmpty(supervisedPsLocalStorage) && supervisedPsLocalStorage != '-1') {
            this.selectedSupervised = supervisedPsLocalStorage?.split(';')[0];
        }
        else if (isEmpty(supervisedPsLocalStorage) || supervisedPsLocalStorage) {
            localStorage.setItem('supervised_ps', '-1');
            this.selectedSupervised = -1;
        }
        this.psSvc.getPsDelegueWithRappVisible(this.currentUser.idPs,Number(this.currentUser.idUser)).subscribe((data) => {
            this.psWithRappActiveAndVisible = data.map(p=>p[0]);
            this.supervisedPsListwithRappActiveVisible = this.supervisedPsList.filter(ps => this.psWithRappActiveAndVisible.includes(ps.numeroPsDelegue));
            const supervisedPsLocalStorage = localStorage.getItem('supervised_ps');
            if (!isEmpty(supervisedPsLocalStorage) && supervisedPsLocalStorage != '-1') {
                const currentPs = JSON.parse(localStorage.getItem('current_ps'));
                this.psWithRappActiveAndVisible = !this.currentUser.userPreferences.rapproActif ? this.psWithRappActiveAndVisible.filter(p => p !== currentPs.numeroAdeli) : this.psWithRappActiveAndVisible;
                this.rappVisible = this.psWithRappActiveAndVisible.includes(supervisedPsLocalStorage.split(';')[0]);
                localStorage.setItem('supervised_rapp_visible', this.rappVisible.toString());
                let filteredData = data.filter(p => p[0] === supervisedPsLocalStorage.split(';')[0]);
                if(!isEmpty(filteredData)){
                    localStorage.setItem('supervised_type_rapp', filteredData[0][1]);
                }

            }
            this.rappConditionLoading=false;
            // // Vérifier si la route actuelle est différente de /dashboard
            // const currentUrl = this.router.url;
            // if (currentUrl !== '/dashboard') {
            //     // Forcer la navigation pour réévaluer le guard
            //     this.router.navigate([currentUrl], { skipLocationChange: true }).then(() => {
            //         this.router.navigate([currentUrl]);
            //     });
            // }
            


        });
    }

    activateAllPs() {
        this.selectedSupervised = -1;
        localStorage.setItem('supervised_ps', this.selectedSupervised);
        this.psSvc.eventPsSupervisedSelected.emit(-1);
        window.location.reload();

    }
    activatePsSupervised(supervisedPs: any, fromModal?) {
        this.rappConditionLoading=true;
        this.selectedSupervised = supervisedPs.numeroPsDelegue;
        localStorage.setItem('supervised_ps', this.selectedSupervised + ';' + supervisedPs.idPsDelegue);
        this.psSvc.getPsDelegueWithRappVisible(this.currentUser.idPs,Number(this.currentUser.idUser)).subscribe((data) => {
            const currentPs = JSON.parse(localStorage.getItem('current_ps'));

            this.psWithRappActiveAndVisible = data.map(p=>p[0]);
            this.supervisedPsListwithRappActiveVisible = this.supervisedPsList.filter(ps => this.psWithRappActiveAndVisible.includes(ps.numeroPsDelegue));
            const supervisedPsLocalStorage = localStorage.getItem('supervised_ps');
            if (!_.isEmpty(supervisedPsLocalStorage) && supervisedPsLocalStorage != '-1') {
                this.psWithRappActiveAndVisible = !this.currentUser.userPreferences.rapproActif ? this.psWithRappActiveAndVisible.filter(p => p !== currentPs.numeroAdeli) : this.psWithRappActiveAndVisible;
                this.rappVisible = this.psWithRappActiveAndVisible.includes(supervisedPsLocalStorage.split(';')[0]);
                localStorage.setItem('supervised_rapp_visible', this.rappVisible.toString());
                let filteredData = data.filter(p => p[0] === supervisedPsLocalStorage.split(';')[0]);
                if(!isEmpty(filteredData)){
                    localStorage.setItem('supervised_type_rapp', filteredData[0][1]);
                }
                
            }
            if (fromModal) {
                this.initPsData();
                this.showDetailModal = false;
                this.router.navigate(['/'+this.urlRapp]);
            }else {
                window.location.reload();
            }

            // // Vérifier si la route actuelle est différente de /dashboard
            // const currentUrl = this.router.url;
            // if (currentUrl !== '/dashboard') {
            //     // Forcer la navigation pour réévaluer le guard
            //     this.router.navigate([currentUrl], { skipLocationChange: true }).then(() => {
            //         this.router.navigate([currentUrl]);
            //     });
            // }
            
        });
        this.psSvc.eventPsSupervisedSelected.emit(supervisedPs.numeroPsDelegue);

    }
    cancel() {
        this.showDetailModal = false;
    }
    cancelModalRappInactive() {
        this.showModalRappInactive = false;
    }
    onMenuRappClick(event) {
        // Apply numero Ps on the filter if exist
        this.urlRapp = event.target.name;
        const supervisedPsLocalStorage = localStorage.getItem('supervised_ps');
        if (!isEmpty(supervisedPsLocalStorage) && supervisedPsLocalStorage == '-1') {
            event.stopPropagation();
            this.psSvc.getPsDelegueWithRappVisible(this.currentUser.idPs,Number(this.currentUser.idUser)).subscribe((data) => {
                const currentPs = JSON.parse(localStorage.getItem('current_ps'));

                this.psWithRappActiveAndVisible = data.map(p=>p[0]);
                this.psWithRappActiveAndVisible = !this.currentUser.userPreferences.rapproActif ? this.psWithRappActiveAndVisible.filter(p => p !== currentPs.numeroAdeli) : this.psWithRappActiveAndVisible;

                this.supervisedPsListwithRappActiveVisible = this.supervisedPsList.filter(ps => this.psWithRappActiveAndVisible.includes(ps.numeroPsDelegue));
                this.showDetailModal = true;
                if(this.psWithRappActiveAndVisible.includes(currentPs.numeroAdeli)){
                    this.selectedPs=this.supervisedPsList.find(ps=>ps.idPsDelegue===this.currentUser.idPs);
                }
                this.supervisedPsInSelect=this.selectedPs;
            });
        } else {
            this.router.navigate(['/'+this.urlRapp]);
        }
    }
    selectPsSupervised(numeroPs: any) {
        let ps = this.supervisedPsList.find(ps => ps.numeroPsDelegue === numeroPs);
        if (ps) {
            this.activatePsSupervised(ps);
        }
    }
    onChangePsSupervised(event: any) {
        this.supervisedPsInSelect = event;

    }
    validerActivatePs() {
        this.activatePsSupervised(this.supervisedPsInSelect, true);
    }
    getSupervisedPsList(): string {
        return this.supervisedPsList.map(p => p.numeroPsDelegue).join(", ");
    }
    ngOnDestroy() {
        this.subscrpt.unsubscribe();
    }
}
