<div class="modal-header pb-1">
    <h3 class="modal-title">Détail des rapprochements pour l'org. : {{organism}}</h3>
    <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
        (click)="closeModal()"><span aria-hidden="true">×</span></button>
</div>

<div class="modal-body pb-1">
    <div class="row grid-top-btn">
        <div class="col-md-4 filter">

        </div>
        <div class="col-md-8 conf-col">
            <div class="careweb-toolbar">
                <careweb-pagination-size id="dv_organismes_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                    [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
                </careweb-pagination-size>
                <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                    name="spn_reset_grid" (click)="resetGridState()">
                    <i class="fa fa-refresh"></i>
                </span>
            </div>
        </div>
    </div>

    <ag-grid-angular style="width: 100%;" class="ag-theme-balham ag-theme-rapp-manual"
        [gridOptions]="gridHelper.gridOptions">
    </ag-grid-angular>
    <careweb-pagination-rapp (pageChange)="pageChange($event)" [totalCount]="rowCount"
        [currentPage]="currentPage"></careweb-pagination-rapp>
</div>

<div class="modal-footer">

</div>