<div class="modal modal-show fade show" id="modalPslogiciel" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header pb-1">
          <h3 class="modal-title">{{title}}</h3>
          <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
            (click)="closeModal()"><span aria-hidden="true">×</span></button>
        </div>



        <div class="modal-body pb-0 mt-4" *ngIf="!isDeleteModale">
          <div class="alert alert-info" *ngIf="dictRuleCpam && !dictRuleCpam.enabled">
            <p>Nous vous proposons ces suggestions de mots clés CPAM <b>"{{dictRuleCpam?.motCleLabelVrt}}"</b> et
              <b>"{{dictRuleCpam?.motCleLabelV1}}"</b> sur la base d'une analyse portant sur les informations suivantes
              :
            </p>
            <ul>
              <li>
                Nombre d'organismes : <b>{{dictRuleCpam?.occurrenceOrg}}</b>
              <li>
                Nombre de rapprochements : <b>{{dictRuleCpam?.occurrenceVrt}}</b></li>
            </ul>
          </div>

          <div class="alert alert-danger alert-dismissible fade show" id="oldRuleKeyLabel" role="alert"
            *ngIf="oldRuleKeyLabel !== ''">
            Le "mot clé de virement" <strong>{{oldRuleKeyLabel}}</strong> est déjà ajouté au dictionnaire.
            <button type="button" id="KeyLabel" class="close" data-dismiss="alert" aria-label="Close"
              (click)="resetOldRuleKeyLabel()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="alert alert-danger alert-dismissible fade show" id="oldRuleKeyLabelVirement1" role="alert"
            *ngIf="oldRuleKeyLabelVirement1 !== '' ">
            Le "mot clé du Libellé de VRT 1 des RSP" <strong>{{oldRuleKeyLabelVirement1}}</strong> est déjà ajouté au
            dictionnaire.
            <button type="button" id="KeyLabelVirement1" class="close" data-dismiss="alert" aria-label="Close"
              (click)="resetOldRuleKeyLabelVirement()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row mb-2">
            <form [formGroup]="dictRuleCpamForm" class="col-12">
              <div class="row">
                <div class="col-4 col-form-label">Mot clé virement <span style="color:red">*</span> : </div>
                <div class="col-8 form-group">
                  <input type="text" class="form-control" formControlName="motCleLabelVrt" required
                    [ngClass]="{ 'is-invalid': isDictRuleFormSubmitted && dictRuleFormFields.motCleLabelVrt.errors }" />
                  <div *ngIf="isDictRuleFormSubmitted && dictRuleFormFields.motCleLabelVrt.errors"
                    class="invalid-feedback">
                    <div>
                      Ce champ est obligatoire
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4 col-form-label"> Mot clé du "Libellé de VRT 1" des RSP <span
                    style="color:red">*</span> : </div>
                <div class="col-8 form-group">
                  <input type="text" class="form-control" formControlName="motCleLabelV1" required
                    [ngClass]="{ 'is-invalid': isDictRuleFormSubmitted && dictRuleFormFields.motCleLabelV1.errors}" />
                  <div *ngIf="isDictRuleFormSubmitted && dictRuleFormFields.motCleLabelV1.errors"
                    class="invalid-feedback">
                    <div>
                      Ce champ est obligatoire
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="modal-body pb-0" *ngIf="isDeleteModale">
          <div class="form-block">
            <fieldset class="container-fluid">
              <div class="row">
                <div class="col-sm-12 col-form-label">Etes-vous sûr de vouloir supprimer la règle ?</div>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" (click)="closeModal()" class="btn btn-default">Annuler</button>
          <button type="button" (click)="onSubmit()" class="btn btn-primary"
            *ngIf="!isDeleteModale">Enregistrer</button>
          <button type="button" (click)="onSubmit()" class="btn btn-primary" *ngIf="isDeleteModale">Supprimer</button>
        </div>

      </div>
    </div>
  </div>

</div>