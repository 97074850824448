<div class="x-large-careweb-container">
    <section class="form-content">
        <!-- Titre H2 -->
        <div class="row form-title">
            <h2 class="col-md-6">Ajouter un nouvel Organisme</h2>
            <div class="col-md-6 text-title">
                <button class="btn btn-default btn-lg" type="button" (click)="goBack()">Retour</button>
            </div>
        </div>

        <div class="form-block">
            <!-- Titre -->
            <div class="row">
                <div class="col">
                    <h3 class="title-border">Détails</h3>
                </div>
            </div>
            <div [formGroup]="orgaismeForm" class="container-fluid">
                <div class="row">
                    <div class="col-sm-2 col-form-label">Sigle *</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="sigle" maxlength="13" [required]="true"
                            [ngClass]="{ 'is-invalid': btnAddClicked && formFields.sigle.errors }" />
                        <div *ngIf="btnAddClicked && formFields.sigle.errors" class="invalid-feedback">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-sm-2 col-form-label">Libellé *</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="libelle" maxlength="120"
                            [required]="true"
                            [ngClass]="{ 'is-invalid': btnAddClicked && formFields.libelle.errors }" />
                        <div *ngIf="btnAddClicked && formFields.libelle.errors" class="invalid-feedback">
                            Ce champ est obligatoire
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Actif</div>
                    <div class="col-sm-4 form-group">
                        <ng-select name="actif" id="actifSelector" formControlName="actif" appendTo="body"
                            [clearable]="false">
                            <ng-option value="true">Oui</ng-option>
                            <ng-option value="false">Non</ng-option>
                        </ng-select>
                    </div>

                    <div class="col-sm-2 col-form-label">Retour regroupement *</div>
                    <div class="col-sm-4 form-group">
                        <ng-select name="retourRegroupement" id="retourRegroupementSelector" [clearable]="false"
                            formControlName="retourRegroupement" appendTo="body" [required]="true"
                            style="width:100%; height:calc(1.8em + .75rem + 2px)!important;"
                            [ngClass]="{ 'is-invalid': btnAddClicked && formFields.retourRegroupement.errors?.required }">
                            <ng-option value="true">Oui</ng-option>
                            <ng-option value="false">Non</ng-option>
                        </ng-select>
                        <div *ngIf="btnAddClicked && formFields.retourRegroupement.errors" class="invalid-feedback">
                            <div *ngIf="formFields.retourRegroupement.errors.required">Ce champ est obligatoire</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Adresse 1</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="adresse1" maxlength="100" />
                    </div>
                    <div class="col-sm-2 col-form-label">Adresse 2</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="adresse2" maxlength="100" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Code Postal</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="codePostal"
                            onKeyPress="if(this.value.length==5) return false;"
                            oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                            onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,''); if(this.value.length==5) return false;"
                            pattern="[0-9]*" min="0" />
                    </div>
                    <div class="col-sm-2 col-form-label">Ville</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="ville" maxlength="50" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Téléphone</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="telephone"
                            onKeyPress="if(this.value.length==10) return false;"
                            oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                            onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,''); if(this.value.length==10) return false;"
                            pattern="[0-9]*" min="0" />
                    </div>
                    <div class="col-sm-2 col-form-label">Fax</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="fax"
                            onKeyPress="if(this.value.length==10) return false;"
                            oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                            onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,''); if(this.value.length==10) return false;"
                            pattern="[0-9]*" min="0" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Commentaire</div>
                    <div class="col-sm-10 form-group">
                        <textarea class="form-control" formControlName="commentaires" rows="2" maxlength="200">
                        </textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Régime</div>
                    <div class="col-sm-4 form-group">
                        <ng-select name="typeOrganisme" id="typeOrganismeSelector" formControlName="typeOrganisme"
                            appendTo="body" [clearable]="false" (change)="changeTypeOrganisme()">
                            <ng-option [value]="'RO'">AMO</ng-option>
                            <ng-option [value]="'RC'">AMC</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-sm-2 col-form-label">Code officiel</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" formControlName="codeOfficiel"
                            onKeyPress="if(this.value.length==10) return false;"
                            oninput="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,'');"
                            onpaste="this.value=this.value.replace(/[^\d]/,'').replace(/\s/g,'').replace( /\./gi,''); if(this.value.length==10) return false;"
                            pattern="[0-9]*" min="0" />
                    </div>
                </div>
                <!-- Si Type organisme RO -->
                <div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Tous centres</div>
                        <div class="col-sm-4 form-group">
                            <ng-select [disabled]="formFields.typeOrganisme.value=='RC'" name="tousCentres"
                                [clearable]="false" id="tousCentresSelector" formControlName="tousCentres"
                                appendTo="body">
                                <ng-option value="true">Oui</ng-option>
                                <ng-option value="false">Non</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-sm-2 col-form-label">Code G.R. *</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" [disabled]="formFields.typeOrganisme.value=='RC'" class="form-control"
                                formControlName="codeGr" onKeyPress="if(this.value.length==2) return false;"
                                onpaste="if(this.value.length==2) return false;"
                                oninput="this.value=this.value.replace(/[^\d]/,'');" pattern="[0-9]*"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'');"
                                onkeydown="this.value=this.value.replace(/[^\d]/,'');" [required]="true"
                                [ngClass]="{ 'is-invalid': btnAddClicked && formFields.codeGr.errors?.required }" />
                            <div *ngIf="btnAddClicked && formFields.codeGr.errors" class="invalid-feedback">
                                <div *ngIf="formFields.codeGr.errors.required">Ce champ est obligatoire</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Caisse C.A.S. *</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" [disabled]="formFields.typeOrganisme.value=='RC'" class="form-control"
                                formControlName="codeCaisseCas" onKeyPress="if(this.value.length==3) return false;"
                                onpaste="if(this.value.length==3) return false;"
                                oninput="this.value=this.value.replace(/[^\d]/,'');" pattern="[0-9]*"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'');"
                                onkeydown="this.value=this.value.replace(/[^\d]/,'');" [required]="true"
                                [ngClass]="{ 'is-invalid': btnAddClicked && formFields.codeCaisseCas.errors?.required }" />
                            <div *ngIf="btnAddClicked && formFields.codeCaisseCas.errors" class="invalid-feedback">
                                <div *ngIf="formFields.codeCaisseCas.errors.required">Ce champ est obligatoire</div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-form-label">Centre C.A.S. * </div>
                        <div class="col-sm-4 form-group">
                            <input type="text" [disabled]="formFields.typeOrganisme.value=='RC'" class="form-control"
                                formControlName="codeCentreCas"
                                onKeyPress="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==4) return false;"
                                onpaste="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==4) return false;"
                                oninput="this.value=this.value.replace(/[^\d]/,'');" pattern="[0-9]*"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'');"
                                onkeydown="this.value=this.value.replace(/[^\d]/,'');" [required]="true"
                                [ngClass]="{ 'is-invalid': btnAddClicked && formFields.codeCentreCas.errors?.required }" />
                            <div *ngIf="btnAddClicked && formFields.codeCentreCas.errors" class="invalid-feedback">
                                <div *ngIf="formFields.codeCentreCas.errors.required">Ce champ est obligatoire</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Code Organisme Type 1 *</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" [disabled]="formFields.typeOrganisme.value=='RC'" class="form-control"
                                formControlName="codeOrgType1"
                                onKeyPress="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==3) return false;"
                                onpaste="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==3) return false;"
                                oninput="this.value=this.value.replace(/[^\d]/,'');" pattern="[0-9]*"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'');"
                                onkeydown="this.value=this.value.replace(/[^\d]/,'');" [required]="true"
                                [ngClass]="{ 'is-invalid': btnAddClicked && formFields.codeOrgType1.errors?.required }" />
                            <div *ngIf="btnAddClicked && formFields.codeOrgType1.errors" class="invalid-feedback">
                                <div *ngIf="formFields.codeOrgType1.errors.required">Ce champ est obligatoire</div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-form-label">Centre Info. du Type 0 *</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" [disabled]="formFields.typeOrganisme.value=='RC'" class="form-control"
                                formControlName="codeCentreInfoType0"
                                onKeyPress="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==3) return false;"
                                onpaste="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==3) return false;"
                                oninput="this.value=this.value.replace(/[^\d]/,'');" pattern="[0-9]*"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'');"
                                onkeydown="this.value=this.value.replace(/[^\d]/,'');" [required]="true"
                                [ngClass]="{ 'is-invalid': btnAddClicked && formFields.codeCentreInfoType0.errors?.required }" />
                            <div *ngIf="btnAddClicked && formFields.codeCentreInfoType0.errors"
                                class="invalid-feedback">
                                <div *ngIf="formFields.codeCentreInfoType0.errors.required">Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Compression</div>
                        <div class="col-sm-4 form-group">
                            <ng-select [disabled]="formFields.typeOrganisme.value=='RC'" name="envoisCompresses"
                                [clearable]="false" id="envoisCompressesSelector" formControlName="envoisCompresses"
                                appendTo="body">
                                <ng-option value="true">Oui</ng-option>
                                <ng-option value="false">Non</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Code organisme type 1 générique *</div>
                        <div class="col-sm-4 form-group">
                            <ng-select [disabled]="formFields.typeOrganisme.value=='RC'" name="codeOrgType1Gen"
                                [clearable]="false" id="codeOrgType1GenSelector" formControlName="codeOrgType1Gen"
                                appendTo="body" [required]="true" 
                                style="width:100%; height:calc(1.8em + .75rem + 2px)!important;"
                                [ngClass]="{ 'is-invalid': btnAddClicked && formFields.codeOrgType1Gen.errors?.required }">
                                <ng-option value="true">Oui</ng-option>
                                <ng-option value="false">Non</ng-option>
                            </ng-select>
                            <div *ngIf="btnAddClicked && formFields.codeOrgType1Gen.errors" class="invalid-feedback">
                                <div *ngIf="formFields.codeOrgType1Gen.errors.required">Ce champ est obligatoire</div>
                            </div>
                        </div>

                        <div class="col-sm-2 col-form-label pr-0">Code centre info type 0 générique *</div>
                        <div class="col-sm-4 form-group">
                            <ng-select [disabled]="formFields.typeOrganisme.value=='RC'" name="codeCentreInfoType0Gen"
                                [clearable]="false" id="codeCentreInfoType0GenSelector"
                                formControlName="codeCentreInfoType0Gen" appendTo="body" [required]="true"
                                style="width:100%; height:calc(1.8em + .75rem + 2px)!important;"
                                [ngClass]="{ 'is-invalid': btnAddClicked && formFields.codeCentreInfoType0Gen.errors?.required }">
                                <ng-option value="true">Oui</ng-option>
                                <ng-option value="false">Non</ng-option>
                            </ng-select>
                            <div *ngIf="btnAddClicked && formFields.codeCentreInfoType0Gen.errors"
                                class="invalid-feedback">
                                <div *ngIf="formFields.codeCentreInfoType0Gen.errors.required">Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End RO -->
                <form #retourForm="ngForm" appCompareDate>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Début</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="dateMin" id="dateMin" #dateMin="ngModel"
                                bsDatepicker size="25" placement="bottom" [(ngModel)]="startDate" [maxDate]="endDate"
                                placeholder="Choisir une date"
                                value="{{ startDate | date:'dd/MM/yyyy' }}"
                                [bsConfig]="{ containerClass: 'theme-kiss'  }" autocomplete="off"
                                [disabled]="formFields.typeOrganisme.value=='RO'" />
                            <careweb-error-messages [field]="dateMin" [touched]="true">
                            </careweb-error-messages>
                        </div>

                        <div class="col-sm-2 col-form-label pr-0">Fin</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="dateMax" id="dateMax" #dateMax="ngModel"
                                bsDatepicker size="25" placement="bottom" [bsConfig]="{ containerClass: 'theme-kiss'  }"
                                placeholder="Choisir une date" [(ngModel)]="endDate" [minDate]="startDate"
                                autocomplete="off" value="{{ endDate | date:'dd/MM/yyyy' }}" [maxDate]="null"
                                [disabled]="formFields.typeOrganisme.value=='RO'" />
                            <careweb-error-messages [field]="dateMax" [touched]="true">
                            </careweb-error-messages>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-sm-2 col-form-label">AMC Seul *</div>
                    <div class="col-sm-4 form-group">
                        <ng-select [disabled]="formFields.typeOrganisme.value=='RO'" name="rcSeul" id="rcSeul"
                            [clearable]="false" formControlName="rcSeul" appendTo="body" [required]="true"
                            style="width:100%; height:calc(1.8em + .75rem + 2px)!important;" [clearable]="false"
                            [ngClass]="{ 'is-invalid': btnAddClicked && formFields.rcSeul.errors?.required }">
                            <ng-option [value]="true">Oui</ng-option>
                            <ng-option [value]="false">Non</ng-option>
                        </ng-select>
                        <div *ngIf="btnAddClicked && formFields.rcSeul.errors" class="invalid-feedback">
                            <div *ngIf="formFields.rcSeul.errors.required">Ce champ est obligatoire</div>
                        </div>
                    </div>
                    <div class="col-sm-2 col-form-label">Aide perenne *</div>
                    <div class="col-sm-4 form-group">
                        <ng-select [disabled]="formFields.typeOrganisme.value=='RO'" name="aidePerenne" id="aidePerenne"
                            [clearable]="false" formControlName="aidePerenne" appendTo="body" [required]="true"
                            style="width:100%; height:calc(1.8em + .75rem + 2px)!important;" [clearable]="false"
                            [ngClass]="{ 'is-invalid': btnAddClicked && formFields.aidePerenne.errors?.required }">
                            <ng-option [value]="true">Oui</ng-option>
                            <ng-option [value]="false">Non</ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-bar control mb-3">
            <button type="button" class="btn btn-default me-3" (click)="cancel()">
                Annuler
            </button>
            <!-- Bouton -->
            <button type="button" class="btn btn-primary" (click)="save()">
                Enregistrer
            </button>
        </div>
    </section>
</div>
