import { Component, OnInit, ViewChild } from '@angular/core';
import { OrganismesService } from '../organismes.service';
import { AmoDataRaw } from '../../../../data/amoDataRaw';
import { AmcDataRaw } from '../../../../data/amcDataRaw';
import { Router } from '@angular/router';
import { OrganismeDataRaw } from '../../../../data/organismeDataRaw';
import { isNullOrUndefined } from 'util';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../core/services/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, NgForm } from '@angular/forms';
import { InputValidators } from 'src/main/webapp/src/app/components/validators/InputValidators';
import { formatDate } from '@angular/common';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';

@Component({
	selector: 'careweb-new-organisme',
	templateUrl: './new-organisme.component.html',
	styleUrls: [ './new-organisme.component.scss' ]
})
export class NewOrganismeComponent implements OnInit {
	organisme: OrganismeDataRaw;
	amo: AmoDataRaw;
	amc: AmcDataRaw;
	btnAddClicked = false;
	orgaismeForm: UntypedFormGroup;
	startDate: any;
	endDate: any;
	@ViewChild('retourForm')
	form: NgForm;

	constructor(
		private organismesSvc: OrganismesService,
		private router: Router,
		private toastr: ToastrService,
		private authSvc: AuthService,
		private fb: UntypedFormBuilder,
		private inputValidators: InputValidators
	) {
		// Define local date fr
		defineLocale('fr', frLocale);
	}

	ngOnInit(): void {
		if (!this.isAuthorized()) {
			this.router.navigate([ '/organismes' ]);
		}

		this.organisme = new OrganismeDataRaw();
		this.amo = new AmoDataRaw();
		this.amc = new AmcDataRaw();
		this.initForm();
	}

	initForm() {
		this.orgaismeForm = this.fb.group({
			// organisme.libelleCourt
			sigle: new UntypedFormControl('', [ Validators.required, this.inputValidators.inputWithSpaces ]),
			// organisme.libelleLong
			libelle: new UntypedFormControl('', [ Validators.required, this.inputValidators.inputWithSpaces ]),
			actif: new UntypedFormControl(''),
			retourRegroupement: new UntypedFormControl('', [ Validators.required ]),
			adresse1: new UntypedFormControl(''),
			adresse2: new UntypedFormControl(''),
			codePostal: new UntypedFormControl(''),
			ville: new UntypedFormControl(''),
			telephone: new UntypedFormControl(''),
			fax: new UntypedFormControl(''),
			commentaires: new UntypedFormControl(''),
			// Régime RO ou RC
			typeOrganisme: new UntypedFormControl(''),
			codeOfficiel: new UntypedFormControl(''),
			// Si Type organisme RO
			tousCentres: new UntypedFormControl(''),
			codeGr: new UntypedFormControl(''),
			codeCaisseCas: new UntypedFormControl(''),
			codeCentreCas: new UntypedFormControl(''),
			codeOrgType1: new UntypedFormControl(''),
			codeCentreInfoType0: new UntypedFormControl(''),
			envoisCompresses: new UntypedFormControl(''),
			codeOrgType1Gen: new UntypedFormControl(''),
			codeCentreInfoType0Gen: new UntypedFormControl(''),
			//si type est RC
			rcSeul: new UntypedFormControl(''),
			aidePerenne: new UntypedFormControl('')
		});
		this.orgaismeForm.controls['typeOrganisme'].setValue('RO');
		this.orgaismeForm.controls['actif'].setValue('false');
		this.orgaismeForm.controls['tousCentres'].setValue('false');
		this.orgaismeForm.controls['rcSeul'].disable();
		this.orgaismeForm.controls['aidePerenne'].disable();
		this.orgaismeForm.controls['codeOfficiel'].disable();
	}

	cancel() {
		this.organisme = new OrganismeDataRaw();
		this.amo = new AmoDataRaw();
		this.amc = new AmcDataRaw();
		this.orgaismeForm.reset();
		this.btnAddClicked = false;
	}

	save() {
		this.btnAddClicked = true;
		this.fromFormToObject();
		if (this.orgaismeForm.valid) {
			if (this.organisme.typeOrganisme === 'RO') {
				this.organismesSvc
					.saveAmo(this.amo)
					.then((res: OrganismeDataRaw) => {
						if (!isNullOrUndefined(res)) {
							this.toastr.success('Le nouvel organisme a été ajouté avec succès');
							this.router.navigate([ '/organismes' ]);
						}
					})
					.catch((err) => {});
			} else {
				this.organismesSvc
					.saveAmc(this.amc)
					.then((res: OrganismeDataRaw) => {
						if (!isNullOrUndefined(res)) {
							this.toastr.success('Le nouvel organisme a été ajouté avec succès');
							this.router.navigate([ '/organismes' ]);
						}
					})
					.catch((err) => {});
			}
		}
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	fromFormToObject() {
		this.organisme.libelleCourt = this.orgaismeForm.controls['sigle'].value;
		this.organisme.libelleLong = this.orgaismeForm.controls['libelle'].value;
		this.organisme.actif = this.orgaismeForm.controls['actif'].value;
		this.organisme.retourRegroupement = this.orgaismeForm.controls['retourRegroupement'].value;
		this.organisme.adresse1 = this.orgaismeForm.controls['adresse1'].value;
		this.organisme.adresse2 = this.orgaismeForm.controls['adresse2'].value;
		this.organisme.codePostal = this.orgaismeForm.controls['codePostal'].value;
		this.organisme.ville = this.orgaismeForm.controls['ville'].value;
		this.organisme.telephone = this.orgaismeForm.controls['telephone'].value;
		this.organisme.fax = this.orgaismeForm.controls['fax'].value;
		this.organisme.commentaires = this.orgaismeForm.controls['commentaires'].value;
		this.organisme.typeOrganisme = this.orgaismeForm.controls['typeOrganisme'].value;

		if (this.organisme.typeOrganisme === 'RO') {
			// if Amo
			this.amo.actif = this.organisme.actif;
			this.amo.libelleCourt = this.organisme.libelleCourt ? this.organisme.libelleCourt.trim() : '';
			this.amo.libelleLong = this.organisme.libelleLong ? this.organisme.libelleLong.trim() : '';
			this.amo.adresse1 = this.organisme.adresse1 ? this.organisme.adresse1.trim() : '';
			this.amo.adresse2 = this.organisme.adresse2 ? this.organisme.adresse2.trim() : '';
			this.amo.codePostal = this.organisme.codePostal;
			this.amo.ville = this.organisme.ville ? this.organisme.ville.trim() : '';
			this.amo.commentaires = this.organisme.commentaires ? this.organisme.commentaires.trim() : '';
			this.amo.telephone = this.organisme.telephone;
			this.amo.fax = this.organisme.fax;
			this.amo.retourRegroupement = this.organisme.retourRegroupement;
			this.amo.rcSeul = false;
			this.amo.tousCentres = this.orgaismeForm.controls['tousCentres'].value;
			!isNullOrUndefined(this.orgaismeForm.controls['codeGr'].value) && this.formFields.codeGr.value !== ''
				? (this.amo.codeGr = this.orgaismeForm.controls['codeGr'].value)
				: this.orgaismeForm.controls['codeGr'].setErrors({ required: true });
			!isNullOrUndefined(this.orgaismeForm.controls['codeCaisseCas'].value) &&
			this.formFields.codeCaisseCas.value !== ''
				? (this.amo.codeCaisseCas = this.orgaismeForm.controls['codeCaisseCas'].value)
				: this.orgaismeForm.controls['codeCaisseCas'].setErrors({ required: true });
			!isNullOrUndefined(this.orgaismeForm.controls['codeCentreCas'].value) &&
			this.formFields.codeCentreCas.value !== ''
				? (this.amo.codeCentreCas = this.orgaismeForm.controls['codeCentreCas'].value)
				: this.orgaismeForm.controls['codeCentreCas'].setErrors({ required: true });
			!isNullOrUndefined(this.orgaismeForm.controls['codeOrgType1'].value) &&
			this.formFields.codeOrgType1.value !== ''
				? (this.amo.codeOrgType1 = this.orgaismeForm.controls['codeOrgType1'].value)
				: this.orgaismeForm.controls['codeOrgType1'].setErrors({ required: true });
			!isNullOrUndefined(this.orgaismeForm.controls['codeCentreInfoType0'].value) &&
			this.formFields.codeCentreInfoType0.value !== ''
				? (this.amo.codeCentreInfoType0 = this.orgaismeForm.controls['codeCentreInfoType0'].value)
				: this.orgaismeForm.controls['codeCentreInfoType0'].setErrors({ required: true });
			!isNullOrUndefined(this.orgaismeForm.controls['codeOrgType1Gen'].value) &&
			this.formFields.codeOrgType1Gen.value !== ''
				? (this.amo.codeOrgType1Gen = this.orgaismeForm.controls['codeOrgType1Gen'].value)
				: this.orgaismeForm.controls['codeOrgType1Gen'].setErrors({ required: true });
			!isNullOrUndefined(this.orgaismeForm.controls['codeCentreInfoType0Gen'].value) &&
			this.formFields.codeCentreInfoType0Gen.value !== ''
				? (this.amo.codeCentreInfoType0Gen = this.orgaismeForm.controls['codeCentreInfoType0Gen'].value)
				: this.orgaismeForm.controls['codeCentreInfoType0Gen'].setErrors({ required: true });
			this.amo.envoisCompresses =
				!isNullOrUndefined(this.orgaismeForm.controls['envoisCompresses'].value) &&
				this.orgaismeForm.controls['envoisCompresses'].value.trim() !== ''
					? this.orgaismeForm.controls['envoisCompresses'].value
					: false;
			this.amo.chiffrementAccepte = false;
		} else if (this.organisme.typeOrganisme === 'RC') {
			this.orgaismeForm.controls['codeGr'].setValidators(null);
			this.orgaismeForm.controls['codeCaisseCas'].setValidators(null);
			this.orgaismeForm.controls['codeCentreCas'].setValidators(null);
			this.orgaismeForm.controls['codeOrgType1'].setValidators(null);
			this.orgaismeForm.controls['codeCentreInfoType0'].setValidators(null);
			this.orgaismeForm.controls['codeOrgType1Gen'].setValidators(null);
			this.orgaismeForm.controls['codeCentreInfoType0Gen'].setValidators(null);

			// if AMC
			this.amc.actif = this.organisme.actif;
			this.amc.libelleCourt = this.organisme.libelleCourt ? this.organisme.libelleCourt.trim() : '';
			this.amc.libelleLong = this.organisme.libelleLong ? this.organisme.libelleLong.trim() : '';
			this.amc.adresse1 = this.organisme.adresse1 ? this.organisme.adresse1.trim() : '';
			this.amc.adresse2 = this.organisme.adresse2 ? this.organisme.adresse2.trim() : '';
			this.amc.codePostal = this.organisme.codePostal;
			this.amc.ville = this.organisme.ville ? this.organisme.ville.trim() : '';
			this.amc.commentaires = this.organisme.commentaires ? this.organisme.commentaires.trim() : '';
			this.amc.telephone = this.organisme.telephone;
			this.amc.fax = this.organisme.fax;
			this.amc.retourRegroupement = this.organisme.retourRegroupement;
			this.amc.babusiaux = false;
			this.amc.envoisCompresses = false;
			this.amc.changeCodeNSV = false;
			this.amc.chiffrementAccepte = false;
			this.amc.aidePerenne = false;
			this.amc.codeOfficiel = this.orgaismeForm.controls['codeOfficiel'].value;

			this.amc.dateDebutValidite = this.startDate ? formatDate(this.startDate, 'dd/MM/yyyy', 'fr-FR') : null;
			this.amc.dateFinValidite = this.endDate ? formatDate(this.endDate, 'dd/MM/yyyy', 'fr-FR') : null;
			!isNullOrUndefined(this.formFields.rcSeul.value) && this.formFields.rcSeul.value !== ''
				? (this.amc.rcSeul = this.formFields.rcSeul.value)
				: this.orgaismeForm.controls['rcSeul'].setErrors({ required: true });
				!isNullOrUndefined(this.formFields.aidePerenne.value) && this.formFields.aidePerenne.value !== ''
				? (this.amc.aidePerenne = this.formFields.aidePerenne.value)
				: this.orgaismeForm.controls['aidePerenne'].setErrors({ required: true });
		}
	}

	get formFields() {
		return this.orgaismeForm.controls;
	}

	changeTypeOrganisme() {
		if (this.formFields.typeOrganisme.value === 'RC') {
			this.orgaismeForm.controls['tousCentres'].disable();
			this.orgaismeForm.controls['codeGr'].disable();
			this.orgaismeForm.controls['codeCaisseCas'].disable();
			this.orgaismeForm.controls['codeCentreCas'].disable();
			this.orgaismeForm.controls['codeOrgType1'].disable();
			this.orgaismeForm.controls['codeCentreInfoType0'].disable();
			this.orgaismeForm.controls['codeOrgType1Gen'].disable();
			this.orgaismeForm.controls['envoisCompresses'].disable();
			this.orgaismeForm.controls['codeCentreInfoType0Gen'].disable();
			this.orgaismeForm.controls['rcSeul'].enable();
			this.orgaismeForm.controls['aidePerenne'].enable();
			this.orgaismeForm.controls['aidePerenne'].setValue(false);
			this.orgaismeForm.controls['codeOfficiel'].enable();
		} else if (this.formFields.typeOrganisme.value === 'RO') {
			this.orgaismeForm.controls['tousCentres'].enable();
			this.orgaismeForm.controls['codeGr'].enable();
			this.orgaismeForm.controls['codeCaisseCas'].enable();
			this.orgaismeForm.controls['codeCentreCas'].enable();
			this.orgaismeForm.controls['codeOrgType1'].enable();
			this.orgaismeForm.controls['codeCentreInfoType0'].enable();
			this.orgaismeForm.controls['codeOrgType1Gen'].enable();
			this.orgaismeForm.controls['envoisCompresses'].enable();
			this.orgaismeForm.controls['codeCentreInfoType0Gen'].enable();
			this.orgaismeForm.controls['rcSeul'].disable();
			this.orgaismeForm.controls['aidePerenne'].disable();
			this.orgaismeForm.controls['codeOfficiel'].disable();
			this.orgaismeForm.controls['codeOfficiel'].setValue('');
		}
	}

	goBack() {
		this.router.navigate(['/organismes']);
	}
}
